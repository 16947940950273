import { Key } from "react";
import { GroupCard } from "./groupCard";
// import { GroupsType } from "../types";
import { Waveform } from "@uiball/loaders";

export const GroupList = ({ groupListData, isLoading, fetchData }: any) => {
  // console.log("groupListData", groupListData);

  return (
    <div>
      <div>
        {isLoading ? (
          <div className="flex justify-center items-center p-16">
            <Waveform size={100} speed={2} color="black" />
          </div>
        ) : (
          <>
            {groupListData?.length > 0 ? (
              <div className="flex flex-wrap justify-end pt-2">
                {groupListData?.map(
                  (
                    { id, name, fee, date_start, date_end, teacherId }: any,
                    index: Key | null | undefined
                  ) => (
                    <div
                      key={index}
                      className="w-full sm:w-1/2 md:w-1/3 lg:w-2/6 p-2">
                      <GroupCard
                        id={id}
                        title={name}
                        fetchData={fetchData}
                        price={fee}
                        date_start={date_start}
                        date_end={date_end}
                        teacherId={teacherId}
                      />
                    </div>
                  )
                )}

                {/* <div className="w-full sm:w-1/2 md:w-1/3 lg:w-2/6 p-2">
            <SubjectCard />
          </div> */}
              </div>
            ) : (
              <div className="flex justify-center p-8 text-2xl">
                <div>لايوجد اي كروبات</div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
