import { Box, Button, IconButton, Modal, TextField } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
import { GetsingleSubjectData } from "../api/getSingleSubject";
import { z } from "zod";
import { EditSubjectReq } from "../api/editSubject";

import { notification } from "antd";
import type { NotificationPlacement } from "antd/es/notification/interface";
import { SingleSubjectCard } from "../types";
const styleClasses =
  "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white shadow-lg p-4 md:w-6/12 sm:w-11/12 xsm:w-11/12";
const inputSchema = z.object({
  subject_name: z.string(),
});

const Context = React.createContext({ name: "Default" });

export const EditSingleSubject = ({ id, fetchSubjectData }: any) => {
  const [subjectData, setSubjectData] = useState<SingleSubjectCard>();

  const [open, setOpen] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm();
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement: NotificationPlacement) => {
    api.success({
      message: `تم تعديل المادة بنجاح`,
      // description: <Context.Consumer>{({ name }) => ``}</Context.Consumer>,
      placement,
    });
  };
  const fetchSubjectDataReq = async () => {
    try {
      const response = await GetsingleSubjectData(id);
      // console.log(response);

      setSubjectData(response);
    } catch (error) {
      console.error("Error fetching class data:", error);
    }
  };
  useEffect(() => {
    fetchSubjectDataReq();
  }, []);
  const handleClick = () => {
    setOpen(true);
    setValue("subject_name", subjectData?.subject_name); // Reset input value when modal is opened
    clearErrors();
    // fetchEditData();
  };
  const handleClose = () => {
    setOpen(false);
  };
  const onSubmit = async (data: any) => {
    try {
      // Validate the submitted data using the schema
      const validatedData = inputSchema.parse(data);

      // You can use the validated data here
      // console.log(validatedData);
      // console.log(subjectId);

      const response = await EditSubjectReq(id, validatedData);

      if (response.status === 200) {
        // console.log(response.status);
        openNotification("topLeft");
      }
      // console.log(response.status);
      // Close the modal

      handleClose();
      fetchSubjectDataReq();
      fetchSubjectData();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  // console.log(subjectData);
  const contextValue = useMemo(() => ({ name: "Ant Design" }), []);

  return (
    <>
      <Context.Provider value={contextValue}>
        {contextHolder}
        <div className="flex font-mainfont py-6">
          <Button
            onClick={() => handleClick()}
            type="submit"
            variant="contained"
            color="primary">
            <div className="font-mainfont">تعديل</div>
          </Button>
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box className={styleClasses}>
            <div className="flex justify-between items-center font-mainfont pb-2">
              <div>
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={open ? "long-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </div>
              <div className="font-mainfont">تعديل المادة</div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="py-2 flex items-center justify-between">
                <div className="w-9/12 xsm:w-8/12">
                  <TextField
                    fullWidth
                    id="subject_name"
                    placeholder="ادخل اسم المادة"
                    {...register("subject_name", { required: true })}
                    error={!!errors.subject_name}
                    helperText={
                      errors.subject_name ? (
                        <div className="text-right">
                          الرجاء ادخال اسم المادة
                        </div>
                      ) : (
                        ""
                      )
                    }
                    InputLabelProps={{
                      style: {
                        textAlign: "right", // Align the label to the right
                      },
                    }}
                    inputProps={{
                      style: {
                        textAlign: "right", // Align the input text to the right
                        direction: "rtl", // Set text direction for the input
                      },
                    }}
                    style={{
                      direction: "rtl", // Set text direction for the entire TextField
                      textAlign: "right", // Align the entire TextField to the right
                    }}
                  />
                </div>
                <div className="font-mainfont">: اسم المادة</div>
              </div>
              {/* <div className="py-2 flex items-center justify-between">
              <div className="w-9/12 xsm:w-8/12">
                <TextField
                  fullWidth
                  id="subject_price"
                  placeholder="ادخل سعر المادة"
                  {...register("subject_price", { required: true })}
                  error={!!errors.subject_price}
                  helperText={
                    errors.subject_price ? (
                      <div className="text-right">الرجاء ادخال سعر المادة</div>
                    ) : (
                      ""
                    )
                  }
                  InputLabelProps={{
                    style: {
                      textAlign: "right", // Align the label to the right
                    },
                  }}
                  inputProps={{
                    style: {
                      textAlign: "right", // Align the input text to the right
                      direction: "rtl", // Set text direction for the input
                    },
                  }}
                  style={{
                    direction: "rtl", // Set text direction for the entire TextField
                    textAlign: "right", // Align the entire TextField to the right
                  }}
                />
              </div>
              <div className="font-mainfont">: سعر المادة</div>
            </div> */}
              <div className="flex font-mainfont py-4">
                <Button type="submit" variant="contained" color="primary">
                  <div className="font-mainfont">اضافة</div>
                </Button>
              </div>
            </form>
          </Box>
        </Modal>{" "}
      </Context.Provider>
    </>
  );
};
