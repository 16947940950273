import { useForm } from "react-hook-form";
import { z } from "zod";
import { Box, Button, IconButton, Modal, TextField } from "@mui/material";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { CreateStudentReq } from "../api/createStudent";
import { CreateStudentType } from "../types";
import { useAuth } from "../../../lib/auth";

const inputSchema = z.object({
  student_name: z.string(),
  phone: z.string().regex(/^[0-9]*$/, "Invalid number"),
  address: z.string(),
  parent_phone: z.string().regex(/^[0-9]*$/, "Invalid number"),
});

const styleClasses =
  "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white shadow-lg p-4 md:w-6/12 sm:w-11/12 xsm:w-11/12";

export const CreateStudent = ({ fetchData }: any) => {
  const [open, setOpen] = useState(false);
  const { user } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm();

  const handleOpen = () => {
    setOpen(true);
    setValue("student_name", "");
    setValue("phone", "");
    setValue("address", "");
    setValue("parent_phone", "");
    clearErrors();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async (data: any) => {
    try {
      const validatedData = inputSchema.parse(data);
      const transformedData: CreateStudentType = {
        student_name: String(validatedData.student_name), // Convert to string
        phone: String(validatedData.phone), // Convert to string
        parent_phone: String(validatedData.parent_phone), // Convert to string
        address: String(validatedData.address), // Convert to string
        schoolID: user?.schoolId, // Leave as is (assuming schoolID is already a number)
      };
      // {
      //   "student_name": "string",
      //   "phone": "stringstri",
      //   "parent_phone": "stringstri",
      //   "address": "string",
      //   "schoolID": 2
      // }
      const response = await CreateStudentReq(transformedData);

      if (response.status == 201) {
        // Handle a successful response here, e.g., show a success message
        // console.log("Student created successfully");
        handleClose();
        fetchData();
      } else {
        // Handle an error response here, e.g., show an error message
        // console.error("Error creating student:", response.error);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div className="flex justify-between items-center">
      <div>
        <Button onClick={handleOpen} variant="contained" size="large">
          <div className="font-mainfont">انشاء طالب جديد</div>
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box className={styleClasses}>
            <div className="flex justify-between items-center font-mainfont pb-2">
              <div>
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={open ? "long-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </div>
              <div className="font-mainfont">انشاء طالب جديد</div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="py-2 flex items-center justify-between">
                <div className="w-9/12 xsm:w-8/12">
                  <TextField
                    fullWidth
                    id="student_name"
                    placeholder="ادخل اسم الطالب"
                    {...register("student_name", { required: true })}
                    error={!!errors.student_name}
                    // helperText={
                    //   errors.student_name ? (
                    //     <div className="text-right">
                    //       الرجاء ادخال اسم الطالب
                    //     </div>
                    //   ) : (
                    //     ""
                    //   )
                    // }
                    InputLabelProps={{
                      style: {
                        textAlign: "right", // Align the label to the right
                      },
                    }}
                    inputProps={{
                      style: {
                        textAlign: "right", // Align the input text to the right
                        direction: "rtl", // Set text direction for the input
                      },
                    }}
                    style={{
                      direction: "rtl", // Set text direction for the entire TextField
                      textAlign: "right", // Align the entire TextField to the right
                    }}
                  />
                  {errors.student_name && (
                    <div className="text-right text-xs text-red-600 pt-1">
                      الرجاء ادخال اسم الطالب
                    </div>
                  )}
                </div>
                <div className="font-mainfont">: اسم الطالب</div>
              </div>
              <div className="py-2 flex items-center justify-between">
                <div className="w-9/12 xsm:w-8/12">
                  <TextField
                    fullWidth
                    id="phone"
                    placeholder="ادخل رقم الهاتف"
                    {...register("phone", {
                      required: true,
                      pattern: /^07\d*$/, // Use pattern to enforce numeric input
                    })}
                    error={!!errors.phone}
                    InputLabelProps={{
                      style: {
                        textAlign: "right", // Align the label to the right
                      },
                    }}
                    inputProps={{
                      style: {
                        textAlign: "right", // Align the input text to the right
                        direction: "rtl", // Set text direction for the input
                      },
                    }}
                    style={{
                      direction: "rtl", // Set text direction for the entire TextField
                      textAlign: "right", // Align the entire TextField to the right
                    }}
                  />{" "}
                  {errors.phone && (
                    <div className="text-right text-xs text-red-600 pt-1">
                      {errors.phone.type === "pattern"
                        ? "رقم الهاتف يجب ان يبدا ب 07"
                        : "الرجاء ادخال رقم الهاتف"}
                    </div>
                  )}
                </div>
                <div className="font-mainfont">: رقم الهاتف</div>
              </div>
              <div className="py-2 flex items-center justify-between">
                <div className="w-9/12 xsm:w-8/12">
                  <TextField
                    fullWidth
                    id="address"
                    placeholder="ادخل عنوان السكن"
                    {...register("address", { required: true })}
                    error={!!errors.address}
                    // helperText={
                    //   errors.address ? (
                    //     <div className="text-right">
                    //       الرجاء ادخال عنوان السكن
                    //     </div>
                    //   ) : (
                    //     ""
                    //   )
                    // }
                    InputLabelProps={{
                      style: {
                        textAlign: "right", // Align the label to the right
                      },
                    }}
                    inputProps={{
                      style: {
                        textAlign: "right", // Align the input text to the right
                        direction: "rtl", // Set text direction for the input
                      },
                    }}
                    style={{
                      direction: "rtl", // Set text direction for the entire TextField
                      textAlign: "right", // Align the entire TextField to the right
                    }}
                  />{" "}
                  {errors.address && (
                    <div className="text-right text-xs text-red-600 pt-1">
                      الرجاء ادخال عنوان السكن
                    </div>
                  )}
                </div>
                <div className="font-mainfont">: عنوان السكن</div>
              </div>
              <div className="py-2 flex items-center justify-between">
                <div className="w-9/12 xsm:w-8/12">
                  <TextField
                    fullWidth
                    id="parent_phone"
                    placeholder="ادخل رقم هاتف الوالدين"
                    {...register("parent_phone", {
                      required: true,
                      pattern: /^07\d*$/, // Use pattern to enforce numeric input
                    })}
                    error={!!errors.parent_phone}
                    // helperText={
                    //   errors.parent_phone ? (
                    //     <div className="text-right">
                    //       {errors.parent_phone.type === "pattern"
                    //         ? "الرجاء ادخال رقم هاتف صحيح"
                    //         : "الرجاء ادخال رقم الهاتف"}
                    //     </div>
                    //   ) : (
                    //     ""
                    //   )
                    // }
                    InputLabelProps={{
                      style: {
                        textAlign: "right", // Align the label to the right
                      },
                    }}
                    inputProps={{
                      style: {
                        textAlign: "right", // Align the input text to the right
                        direction: "rtl", // Set text direction for the input
                      },
                    }}
                    style={{
                      direction: "rtl", // Set text direction for the entire TextField
                      textAlign: "right", // Align the entire TextField to the right
                    }}
                  />
                  {errors.parent_phone && (
                    <div className="text-right text-xs text-red-600 pt-1">
                      {errors.parent_phone.type === "pattern"
                        ? "رقم الهاتف يجب ان يبدا ب 07"
                        : "الرجاء ادخال رقم الهاتف"}
                    </div>
                  )}
                </div>
                <div className="font-mainfont">: رقم هاتف الوالدين</div>
              </div>
              <div className="flex font-mainfont py-4">
                <Button type="submit" variant="contained" color="primary">
                  <div className="font-mainfont">اضافة</div>
                </Button>
              </div>
            </form>
          </Box>
        </Modal>
      </div>
    </div>
  );
};
