import { axios } from "../../../lib/axios";

export const ClassArchiveDelete = async (id: any, enteredClassId: any) => {
  const requestBody = {
    entity: "stage",
    id: enteredClassId,
  };
  const response = await axios.delete(`/delete-archived/${id}`, {
    data: requestBody,
  });
  return response;
};
// done types
