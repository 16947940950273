import { Breadcrumbs, Button, Link } from "@mui/material";
import { useEffect, useState } from "react";
import { GetSingleAdmin } from "../api/getSingleAdmin";
import { useParams } from "react-router-dom";
import { decryptionFun } from "../../../lib/secretUrl";
import { User } from "../types";
import { EditAdmin } from "./editAdmin";
import { Waveform } from "@uiball/loaders";

export const AdminProfile = () => {
  const { encryptedAdminId } = useParams();
  const [id, setId] = useState<number | null>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [singleAdminData, setSingleAdminData] = useState<User | undefined>();
  const secretKey = process.env.REACT_APP_ADMIN_ENCRYPTION_KEY as string;

  const fetchData = async () => {
    if (encryptedAdminId) {
      const adminId = decryptionFun(encryptedAdminId, secretKey);
      setId(adminId);
      setIsLoading(false);
      try {
        const response = await GetSingleAdmin(adminId);
        // if (response != undefined) {
        // }
        setSingleAdminData(response);
        // console.log(response);
      } catch (error) {
        console.log(error);
      }
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <div>
        <div className="pl-5 flex justify-between">
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/admins">
              <div className="font-mainfont text-md">الادمنية</div>
            </Link>
            <div className="font-mainfont text-md">
              {singleAdminData?.fullName}
            </div>
          </Breadcrumbs>
        </div>
        <div className="flex flex-col px-5">
          <div className="flex justify-between items-center">
            {/* <div className="flex font-mainfont py-6">
              <Button type="submit" variant="contained" color="primary">
                <div className="font-mainfont">تعديل</div>
              </Button>
            </div> */}
            {isLoading ? (
              <div className="flex justify-center items-center p-16">
                <Waveform size={40} speed={2} color="black" />
              </div>
            ) : (
              <>
                {" "}
                <EditAdmin id={id} fetchData={fetchData} />
                <div className="font-mainfont text-3xl">
                  {singleAdminData?.fullName}
                </div>
              </>
            )}
          </div>
          <div className="border-b-2 border-mainLine"></div>
        </div>
      </div>
      <div>
        {/* {isLoading ? (
          <div className="flex justify-center items-center p-16">
            <Waveform size={40} speed={2} color="black" />
          </div>
        ) : ( */}
        <div className="flex flex-wrap justify-end p-3">
          <div className="font-mainfont text-l p-3">
            {singleAdminData?.userName} : اسم المستخدم
          </div>
          {/* <MetaDataCard index={1} title="رقم الهاتف" data={"077012345678"} />
          <MetaDataCard index={2} title="عنوان السكن" data="iraq" />
          <MetaDataCard index={3} title="المبلغ الكلي المستحق" data={1234} />
          <MetaDataCard index={4} title="المبلغ الكلي المدفوع" data={1234} />
          <MetaDataCard index={5} title="المبلغ الكلي المتبقي" data={1234} /> */}
          {/* {singleTeacherMetaData.map((card, index) => (
              
            ))} */}
        </div>
        {/* )} */}
      </div>
    </div>
  );
};

// done types
