import { axios } from "../../../lib/axios";
import { SingleSubjectCard } from "../types";

export const GetsingleSubjectData = async (id: number | null) => {
  // const response = await axios.get(`/subjects/${id}`);
  // console.log("response", response.data);
  const response = await axios.get(`/subjects/${id}?relations[]=course`);
  // console.log("response2", response2);

  return response.data;
};

// done types
