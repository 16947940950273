import { axios } from "../../../lib/axios";
import { ClassContainer } from "../types";

export const GetClassesReq = async (): Promise<ClassContainer> => {
  // console.log("hi");

  const response = await axios.get("/stages");
  // console.log("response.data", response);

  return response.data;
};
// done types
