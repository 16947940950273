import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Breadcrumbs, Link } from "@mui/material";
import { MetaDataCard } from "../../../components/elements/metaDataCard";
import { decryptionFun } from "../../../lib/secretUrl";
import { GetSingleStudentData } from "../api/getSingleStudentData";
import { EditStudent } from "./editStudent";
import { Waveform } from "@uiball/loaders";
import { DeleteStudent } from "./deleteStudent";
import { ROLES, useAuthorization } from "../../../lib/authorization";
export const StudentProfile = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [studentProfileData, setStudentProfileData] = useState<any>();
  const { encryptedStudentId } = useParams();
  const secretStudenttKey = process.env
    .REACT_APP_STUDENT_ENCRYPTION_KEY as string;
  // setUserProfileData(response);
  // console.log(id);
  const { checkAccess } = useAuthorization();
  const [canAccess, setCanAccess] = useState(false);
  const [studentCourseList, setStudentCourseList] = useState<any>([]);
  const fetchSingleStudentMetaData = async () => {
    if (encryptedStudentId) {
      const id = decryptionFun(encryptedStudentId, secretStudenttKey);
      try {
        const response = await GetSingleStudentData(id);
        if (response) {
          setStudentProfileData(response.response);
          setStudentCourseList(response.secountResponse);
          setIsLoading(false);
        }
        setCanAccess(
          checkAccess({
            allowedRoles: [ROLES.SUPERADMIN],
          })
        );
      } catch (error) {
        console.error("Error fetching teacher data:", error);
      }
    }
  };
  useEffect(() => {
    fetchSingleStudentMetaData();
  }, []);
  // console.log("studentProfileData", studentCourseList);

  return (
    <div>
      <div>
        {isLoading ? (
          <div className="flex justify-center items-center p-16">
            <Waveform size={100} speed={2} color="black" />
          </div>
        ) : (
          <>
            <div className="pl-5 flex justify-between">
              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/students">
                  <div className="font-mainfont text-md">الطلاب</div>
                </Link>
                <div className="font-mainfont text-md">
                  {/* {singleTeacherMetaData?.teacher_name} */}
                  {studentProfileData?.studentName}
                </div>
              </Breadcrumbs>
            </div>
            <div className="flex flex-col px-5">
              <div className="flex justify-between items-center">
                <div className="flex justify-start gap-8">
                  {canAccess ? (
                    studentCourseList?.length === 0 ? (
                      <DeleteStudent
                        StudentProfileData={studentProfileData}
                        fetchSingleStudentMetaData={fetchSingleStudentMetaData}
                      />
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  <EditStudent
                    StudentProfileData={studentProfileData}
                    fetchSingleStudentMetaData={fetchSingleStudentMetaData}
                  />
                </div>
                <div className="font-mainfont text-3xl">
                  {studentProfileData?.studentName}
                </div>
              </div>
              <div className="border-b-2 border-mainLine"></div>
            </div>
            <div>
              <div className="flex flex-wrap justify-end p-3">
                <MetaDataCard
                  index={1}
                  title="رقم الهاتف"
                  data={studentProfileData?.studentPhone}
                />
                <MetaDataCard
                  index={2}
                  title="عنوان السكن"
                  data={studentProfileData?.studentAddress}
                />{" "}
                <MetaDataCard
                  index={3}
                  title="رقم هاتف الاهل"
                  data={studentProfileData?.studentParentPhone}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
