import { SingleClass } from "../components/SingleClass";
import { SubjectList } from "../../subject/components/SubjectList";
import { useEffect } from "react";

export const SingleClassWrapper = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    // fetchData();
  }, []);

  // console.log(isLoading);

  return (
    <div className="pt-5">
      <SingleClass />
      <div className="pt-3 p-5">
        <SubjectList />
      </div>
    </div>
  );
};
