import { axios } from "../../../lib/axios";
import { Student } from "../types";

export const GetSingleStudentCourses = async (id: number | null) => {
  const response = await axios.get(`/students/courses`);
  // console.log(response);

  const singleStudent = response.data.find((single: any) => single.id === id);

  return singleStudent || null; // Return null if no match is found
};
