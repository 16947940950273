import { useEffect, useState } from "react";
import {
  Authorization,
  ROLES,
  useAuthorization,
} from "../../../lib/authorization";
import { AdminList } from "../components/adminList";
import { AdminTable } from "../components/adminTable";
import { CreateAdmin } from "../components/createAdmin";
import { User } from "../types";
import { GetAdmin } from "../api/getAdmins";

export const AdminWrapper = () => {
  const { checkAccess } = useAuthorization();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [adminData, setAdminData] = useState<User[]>([]);
  const fetchClassData = async () => {
    try {
      const response = await GetAdmin();
      // console.log(response);
      setAdminData(response);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching class data:", error);
    }
  };

  useEffect(() => {
    fetchClassData();
  }, []);

  useEffect(() => {
    // Scroll to the top of the page when the component is mounted
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div>
      {checkAccess({ allowedRoles: [ROLES.ADMIN, ROLES.SUPERADMIN] }) && (
        <>
          <div className="p-10">
            <CreateAdmin fetchClassData={fetchClassData} />
            <AdminList
              isLoading={isLoading}
              adminData={adminData}
              fetchClassData={fetchClassData}
            />
            {/* <AdminTable /> */}
          </div>
        </>
      )}
    </div>
  );
};
