import { useEffect, useState } from "react";
import { StudentGroupCard } from "./studentGroupCard";
import { useParams } from "react-router-dom";
import { decryptionFun } from "../../../lib/secretUrl";
import { Waveform } from "@uiball/loaders";
import { GetSingleStudentCourses } from "../api/getStudentCourses";

export const StudentProfileCourseList = () => {
  const [studentCardData, setStudentCardData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);

  const { encryptedStudentId } = useParams();
  const secretStudentKey = process.env
    .REACT_APP_STUDENT_ENCRYPTION_KEY as string;

  const fetchSingleStudentData = async () => {
    if (encryptedStudentId) {
      const id = decryptionFun(encryptedStudentId, secretStudentKey);
      const response = await GetSingleStudentCourses(id);
      // console.log(response.student_group);
      // Use map to accumulate non-null course values
      const nonNullCourses = response.student_group
        .filter((result: any) => result.course !== null)
        .map((result: any) => result.course);

      setStudentCardData(nonNullCourses);
      setIsLoading(false);
      // setStudentCardData(response.student_group); // Provide an initial empty array as a fallback
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchSingleStudentData();
  }, []);
  // console.log("studentCardDatastudentCardData", studentCardData);
  return (
    <div>
      {isLoading ? (
        <div className="flex justify-center items-center p-16">
          <Waveform size={100} speed={2} color="black" />
        </div>
      ) : (
        <div>
          {studentCardData && studentCardData?.length > 0 ? (
            <div className="flex flex-wrap justify-end pt-2">
              {studentCardData?.map((studentGroup: any, index: any) => (
                <div key={index} className="w-full md:w-1/2 lg:w-1/3 p-2">
                  <StudentGroupCard studentGroup={studentGroup} />
                </div>
              ))}
            </div>
          ) : (
            <div className="flex justify-center items-center p-5">
              <div>الطالب الحالي لم يدخل اي كروب</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
