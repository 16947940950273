import { axios } from "../../../lib/axios";
import { SingleClassMetaDataType } from "../types";

export const GetsingleClassData = async (
  id: number | null
): Promise<SingleClassMetaDataType> => {
  const response = await axios.get(`/stages/${id}?relations[]=subjects`);
  // console.log("response", response.data);

  return response.data;
};
