import { axios } from "../../../lib/axios";
import { AdminDataProp, CreateAdminProp, User } from "../types";

// Define a type that matches the actual API response structure

// Update the function signature to reflect the API response type
export const CreateAdminReq = async (data: any) => {
  try {
    // Step 1: Get the role ID for "admin"
    const roleResponse = await axios.get("/roles");

    // Find the role object with name "admin"
    const adminRole = roleResponse.data.array.find(
      (role: any) => role.role === "admin"
    );

    if (!adminRole) {
      throw new Error("Role 'admin' not found.");
    }

    // Step 2: Create the admin user with the obtained role ID
    const adminUserData = {
      ...data,
      roleId: adminRole.id,
      schoolId: 2,
    };

    // Make a POST request to create the admin user
    const response = await axios.post(`/user`, adminUserData);

    return response.data;
  } catch (error) {
    console.error("Error creating admin:", error);
    throw error;
  }
};
