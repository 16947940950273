import React from "react";
import { useAuth } from "./auth";

export enum ROLES {
  SUPERADMIN = "superadmin",
  ADMIN = "admin",
  TEACHER = "teacher",
}

type RoleTypes = keyof typeof ROLES;
export const useAuthorization = () => {
  const { user } = useAuth();

  if (!user) {
    throw Error("User does not exist!");
  }

  const checkAccess = React.useCallback(
    ({
      allowedRoles,
    }: {
      allowedRoles: ("superadmin" | "admin" | "teacher")[];
    }) => {
      if (allowedRoles && allowedRoles.length > 0) {
        return allowedRoles.includes(
          user.role as "superadmin" | "admin" | "teacher"
        );
      }

      return true;
    },
    [user.role]
  );

  return { checkAccess, role: user.role };
};

type AuthorizationProps = {
  forbiddenFallback?: React.ReactNode;
  children: React.ReactNode;
} & (
  | {
      allowedRoles: ("superadmin" | "admin" | "teacher")[]; // Use lowercase strings here
      policyCheck?: never;
    }
  | {
      allowedRoles?: never;
      policyCheck: boolean;
    }
);

export const Authorization = ({
  policyCheck,
  allowedRoles,
  forbiddenFallback = null,
  children,
}: AuthorizationProps) => {
  const { checkAccess } = useAuthorization();

  let canAccess = false;

  if (allowedRoles) {
    canAccess = checkAccess({ allowedRoles });
  }

  if (typeof policyCheck !== "undefined") {
    canAccess = policyCheck;
  }

  return <>{canAccess ? children : forbiddenFallback}</>;
};
