import { TeacherTable } from "../components/teacherTable";
import { CreateTeacher } from "../components/createTeacher";
import { useEffect, useState } from "react";
import { GetTeacherDataReq } from "../api/getTeacherData";
import { Button, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { Waveform } from "@uiball/loaders";

export const TeacherWrapper = () => {
  const [originalTeacherData, setOriginalTeacherData] = useState([]);
  const [filteredTeacherData, setFilteredTeacherData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { register, handleSubmit } = useForm();
  const fetchData = async () => {
    try {
      // Fetch teacher data using an API call
      const response = await GetTeacherDataReq();
      // console.log("response", response);

      setOriginalTeacherData(response);
      setFilteredTeacherData(response);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching teacher data:", error);
    }
  };

  const onSubmit = async (data: any) => {
    const filter = data.search_teacher_name || ""; // Get the filter from the form

    if (filter === "") {
      // If the input is empty, reset to the original data
      setFilteredTeacherData(originalTeacherData);
    } else {
      // Filter the studentData based on the filter string
      const filteredData = originalTeacherData.filter((teacher: any) => {
        if (teacher) {
          for (const key in teacher) {
            if (
              typeof teacher[key] === "string" &&
              (teacher[key as keyof typeof teacher] as string).includes(filter)
            ) {
              return true;
            }
            // Check for 'fullName' in the 'user' object
            if (key === "user") {
              if (
                typeof teacher[key].fullName === "string" &&
                teacher[key].fullName.includes(filter)
              ) {
                return true;
              }
            }
          }
        }
        return false;
      });

      setFilteredTeacherData(filteredData); // Set filtered data
    }
  };
  useEffect(() => {
    fetchData();
    // Scroll to the top of the page when the component is mounted
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  // console.log(teacherData);

  return (
    <div className="p-10">
      {isLoading ? (
        <div className="flex justify-center items-center p-16">
          <Waveform size={100} speed={2} color="black" />
        </div>
      ) : (
        <>
          <div className="flex justify-between items-center mb-2">
            <div>
              <CreateTeacher
                teacherData={originalTeacherData}
                setTeacherData={setOriginalTeacherData}
                fetchData={fetchData}
              />
            </div>
            <div className="flex items-center">
              <div className="pr-2 md:block sm:hidden xsm:hidden">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Button type="submit" variant="contained" color="primary">
                    <div className="font-mainfont">بحث</div>
                  </Button>
                  <TextField
                    size="small"
                    id="search_teacher_name"
                    placeholder="البحث"
                    {...register("search_teacher_name")}
                    InputLabelProps={{
                      style: {
                        textAlign: "right",
                      },
                    }}
                    inputProps={{
                      style: {
                        textAlign: "right",
                        direction: "rtl",
                      },
                    }}
                    style={{
                      direction: "rtl",
                      textAlign: "right",
                    }}
                  />
                </form>
              </div>
              <div className="font-mainfont text-3xl">المدرسين</div>
            </div>
          </div>
          <div className="md:hidden sm:flex xsm:flex justify-end w-full">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="w-full flex justify-end">
              <Button type="submit" variant="contained" color="primary">
                <div className="font-mainfont">بحث</div>
              </Button>
              <TextField
                size="small"
                fullWidth
                id="search_teacher_name"
                placeholder="البحث"
                {...register("search_teacher_name")}
                InputLabelProps={{
                  style: {
                    textAlign: "right",
                  },
                }}
                inputProps={{
                  style: {
                    textAlign: "right",
                    direction: "rtl",
                  },
                }}
                style={{
                  direction: "rtl",
                  textAlign: "right",
                }}
              />
            </form>
          </div>
          <div className="border-b-2 border-mainLine pt-2"></div>
          {/* <StudentTable
            fetchData={fetchData}
            studentData={filteredStudentData}
          /> */}
          <TeacherTable teacherData={filteredTeacherData} />
        </>
      )}
    </div>
  );
};
