import { Box, Button, IconButton, Modal, TextField } from "@mui/material";
import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import { ROLES, useAuthorization } from "../../../lib/authorization";
import { z } from "zod";
import { useParams } from "react-router-dom";
import { decryptionFun } from "../../../lib/secretUrl";
import { CreateSujbectReq } from "../api/CreateSujbect";

import { notification } from "antd";
import type { NotificationPlacement } from "antd/es/notification/interface";

const styleClasses =
  "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white shadow-lg p-4 md:w-6/12 sm:w-11/12 xsm:w-11/12";
const Context = React.createContext({ name: "Default" });

export const inputSchema = z.object({
  subject_name: z.string(),
});

export const CreateSubject = ({ fetchData }: any) => {
  const { checkAccess } = useAuthorization();
  const [open, setOpen] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm();

  const { encryptedClassId } = useParams();
  const secretKey = process.env.REACT_APP_CLASS_ENCRYPTION_KEY as string;
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement: NotificationPlacement) => {
    api.success({
      message: `تم انشاء المادة بنجاح`,
      // description: <Context.Consumer>{({ name }) => ``}</Context.Consumer>,
      placement,
    });
  };
  // const fetchEditData = async () => {
  //   try {
  //     // const response = await axios.get(`class/${id}`);
  //     // setSingleClassEditData(response.data);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };
  const handleEditClick = () => {
    setOpen(true);
    setValue("subject_name", "");
    // setValue("subject_price", "");
    clearErrors();
    // fetchEditData();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async (data: any) => {
    try {
      // Validate the submitted data using the schema
      const validatedData = inputSchema.parse(data);

      if (encryptedClassId) {
        const id = decryptionFun(encryptedClassId, secretKey);

        if (id) {
          const subjectData = {
            stageId: id,
            subject_name: validatedData.subject_name,
          };

          // Create the subject
          const response = await CreateSujbectReq(subjectData);

          if (response.status == 201) {
            openNotification("topLeft");
          }
        }
      }

      // Close the modal
      handleClose();
      fetchData();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  const contextValue = useMemo(() => ({ name: "Ant Design" }), []);
  // console.log("classMetaDataStats", classMetaDataStats);

  return (
    <>
      <Context.Provider value={contextValue}>
        {contextHolder}
        {checkAccess({ allowedRoles: [ROLES.ADMIN, ROLES.SUPERADMIN] }) ? (
          <>
            <div className="flex justify-between items-center mb-2">
              <div>
                <Button
                  onClick={handleEditClick}
                  variant="contained"
                  size="large">
                  <div className="font-mainfont">انشاء مادة</div>
                </Button>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description">
                  <Box className={styleClasses}>
                    <div className="flex justify-between items-center font-mainfont pb-2">
                      <div>
                        <IconButton
                          aria-label="more"
                          id="long-button"
                          aria-controls={open ? "long-menu" : undefined}
                          aria-expanded={open ? "true" : undefined}
                          aria-haspopup="true"
                          onClick={handleClose}>
                          <CloseIcon />
                        </IconButton>
                      </div>
                      <div className="font-mainfont">انشاء مادة جديد</div>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="py-2 flex items-center justify-between">
                        <div className="w-9/12 xsm:w-8/12">
                          <TextField
                            fullWidth
                            id="subject_name"
                            placeholder="ادخل اسم المادة"
                            {...register("subject_name", { required: true })}
                            error={!!errors.subject_name}
                            helperText={
                              errors.subject_name ? (
                                <div className="text-right">
                                  الرجاء ادخال اسم المادة
                                </div>
                              ) : (
                                ""
                              )
                            }
                            InputLabelProps={{
                              style: {
                                textAlign: "right", // Align the label to the right
                              },
                            }}
                            inputProps={{
                              style: {
                                textAlign: "right", // Align the input text to the right
                                direction: "rtl", // Set text direction for the input
                              },
                            }}
                            style={{
                              direction: "rtl", // Set text direction for the entire TextField
                              textAlign: "right", // Align the entire TextField to the right
                            }}
                          />
                        </div>
                        <div className="font-mainfont">: اسم المادة</div>
                      </div>
                      {/* <div className="py-2 flex items-center justify-between">
                      <div className="w-9/12 xsm:w-8/12">
                        <TextField
                          fullWidth
                          id="subject_price"
                          placeholder="ادخل سعر المادة"
                          {...register("subject_price", { required: true })}
                          error={!!errors.subject_price}
                          helperText={
                            errors.subject_price ? (
                              <div className="text-right">
                                الرجاء ادخال سعر المادة
                              </div>
                            ) : (
                              ""
                            )
                          }
                          InputLabelProps={{
                            style: {
                              textAlign: "right", // Align the label to the right
                            },
                          }}
                          inputProps={{
                            style: {
                              textAlign: "right", // Align the input text to the right
                              direction: "rtl", // Set text direction for the input
                            },
                          }}
                          style={{
                            direction: "rtl", // Set text direction for the entire TextField
                            textAlign: "right", // Align the entire TextField to the right
                          }}
                        />
                      </div>
                      <div className="font-mainfont">: سعر المادة</div>
                    </div> */}
                      <div className="flex font-mainfont py-4">
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary">
                          <div className="font-mainfont">اضافة</div>
                        </Button>
                      </div>
                    </form>
                  </Box>
                </Modal>
              </div>
              <div>
                <div className="font-mainfont text-3xl">المواد</div>
              </div>
            </div>
          </>
        ) : (
          /* Content to render when the condition is not met (else block) */
          <div className="flex justify-end items-center mb-2">
            {/* You can add content specific to when the condition is not met here */}
            <div className="font-mainfont text-3xl">المواد</div>
          </div>
        )}
        <div className="border-b-2 border-mainLine pt-2"></div>
      </Context.Provider>{" "}
    </>
  );
};

// done types
