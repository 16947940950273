import { useEffect, useState } from "react";
import { ClassList } from "../components/ClassList";
import { CreateClass } from "../components/CreateClass";
import { SingleClass } from "../types";
import { GetClassesReq } from "../api/getClass";
import { ROLES, useAuthorization } from "../../../lib/authorization";

export const ClassWrapper = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [classData, setClassData] = useState<SingleClass[]>();
  const { checkAccess } = useAuthorization();

  const fetchClassData = async () => {
    // setIsLoading(true);
    setClassData([]);
    try {
      const response = await GetClassesReq();
      // console.log("response", response);
      if (response.array) {
        setClassData(response.array);
      } else {
        setClassData(response.array);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching class data:", error);
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    fetchClassData();
  }, []);

  return (
    <div>
      <div className="pt-3 p-5">
        {checkAccess({
          allowedRoles: [ROLES.ADMIN, ROLES.SUPERADMIN],
        }) ? (
          <CreateClass fetchClassData={fetchClassData} />
        ) : (
          ""
        )}
        <ClassList
          fetchClassData={fetchClassData}
          isLoading={isLoading}
          classData={classData}
        />
      </div>
    </div>
  );
};
