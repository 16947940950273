import { ResponseInterceptor } from "@/features/teachers/api/getTeacherData";
import { axios } from "../../../lib/axios";

const requestBody = {
  entity: "course",
};

export const undeleteGroupReq = async (id: any) => {
  const response = await axios.patch(`/undelete/${id}`, requestBody);
  return response.data;
};
// done types
