import { axios } from "../../../lib/axios";
import { createTeacherType } from "../types";

export const createTeacher = async (teacherData: createTeacherType) => {
  try {
    const formattedData = {
      teacherName: teacherData.teacher_name,
      phone: teacherData.phone_number,
      address: teacherData.Adress,
      schoolId: teacherData.schoolId,
      userName: teacherData.teacher_userName,
      password: teacherData.password,
      specialty: teacherData.specialty,
      coursesIds: [],
      teacherPaymentIds: [],
    };

    const response = await axios.post("/teacher", formattedData);

    return response;
  } catch (error) {
    return false;
  }
};
