import { Breadcrumbs } from "@mui/material";
import { Waveform } from "@uiball/loaders";
import { useEffect, useState } from "react";
import { GroupMetaData } from "./groupMetaData";
import { useNavigate, useParams } from "react-router-dom";
import { decryptionFun } from "../../../lib/secretUrl";
import { GetsingleSubjectData } from "../../subject/api/getSingleSubject";
import { GetSingleGroup } from "../api/getSingleGroup";
import { EditSingleGroup } from "./editSingleGroup";
import { ROLES, useAuthorization } from "../../../lib/authorization";
import { GetSingleClass } from "../api/getSingleStage";
import { DeleteSingleGroup } from "./deleteGroup";
export const SingleGroupData = ({ fethcTeacherData }: any) => {
  const { encryptedClassId, encryptedSubjectId, encryptedGrouptId } =
    useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [classData, setClassData] = useState<any>([]);
  const [subjectData, setSubjectData] = useState<any>([]);
  const [groupData, setGroupData] = useState<any>([]);
  const { checkAccess } = useAuthorization();
  const [canAccess, setCanAccess] = useState(false);
  const secretClassKey = process.env.REACT_APP_CLASS_ENCRYPTION_KEY as string;
  const secretSubjectKey = process.env
    .REACT_APP_SUBJECT_ENCRYPTION_KEY as string;
  const secreGrouptKey = process.env.REACT_APP_GROUP_ENCRYPTION_KEY as string;

  useEffect(() => {
    if (encryptedClassId && encryptedSubjectId && encryptedGrouptId) {
      const ClassId = decryptionFun(encryptedClassId, secretClassKey);
      const SubjectId = decryptionFun(encryptedSubjectId, secretSubjectKey);
      const GroupId = decryptionFun(encryptedGrouptId, secreGrouptKey);

      const fetchData = async () => {
        try {
          if (GroupId) {
            const groupResponse = await GetSingleGroup(GroupId);
            // console.log(groupResponse);

            setGroupData(groupResponse);
          }
          const classResponse = await GetSingleClass(ClassId);
          setClassData(classResponse);

          const subjectResponse = await GetsingleSubjectData(SubjectId);
          setSubjectData(subjectResponse);
          setCanAccess(
            checkAccess({
              allowedRoles: [ROLES.SUPERADMIN],
            })
          );
          setIsLoading(false); // Set isLoading to false after fetching data
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchData();
    }
  }, []);
  const navigate = useNavigate();

  // console.log("groupData", groupData);

  return (
    <div>
      {isLoading ? (
        <div className="flex justify-center items-center p-16">
          <Waveform size={40} speed={2} color="black" />
        </div>
      ) : (
        <>
          <div className="pl-5 flex justify-between">
            <Breadcrumbs aria-label="breadcrumb">
              <div
                className="font-mainfont sm:text-sm xsm:text-xs md:text-md hover:underline cursor-pointer"
                onClick={() => navigate(`/dashboard`)}>
                الصفوف
              </div>
              <div
                className="font-mainfont sm:text-sm xsm:text-xs md:text-md hover:underline cursor-pointer"
                onClick={() => navigate(`/dashboard/${encryptedClassId}`)}>
                {classData?.stage_name}
              </div>
              <div
                className="font-mainfont sm:text-sm xsm:text-xs md:text-md hover:underline cursor-pointer"
                onClick={() =>
                  navigate(
                    `/dashboard/${encryptedClassId}/${encryptedSubjectId}`
                  )
                }>
                {subjectData?.subject_name}
              </div>
              <div>
                <div className="font-mainfont sm:text-sm xsm:text-xs md:text-md">
                  {groupData?.name}
                </div>
              </div>
            </Breadcrumbs>
          </div>

          {checkAccess({
            allowedRoles: [ROLES.ADMIN, ROLES.SUPERADMIN],
          }) ? (
            <div className="flex flex-col px-5">
              <div className="flex justify-between items-center">
                <div className="flex justify-start gap-8">
                  {canAccess ? <DeleteSingleGroup /> : ""}
                  <EditSingleGroup
                    GroupData={groupData}
                    fethcTeacherData={fethcTeacherData}
                  />
                </div>
                <div className="font-mainfont text-3xl">{groupData?.name}</div>
              </div>
              <div className="border-b-2 border-mainLine"></div>
            </div>
          ) : (
            <div className="flex justify-end p-5 items-center">
              <div className="font-mainfont text-3xl">{groupData?.name}</div>
            </div>
          )}

          {checkAccess({
            allowedRoles: [ROLES.ADMIN, ROLES.SUPERADMIN],
          }) ? (
            <GroupMetaData />
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
};
