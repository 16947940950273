import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Box, Button, IconButton, Modal, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { z } from "zod";
import dayjs, { Dayjs } from "dayjs";
import { GetSingleGroup } from "../api/getSingleGroup";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useNavigate, useParams } from "react-router-dom";
import { decryptionFun } from "../../../lib/secretUrl";
import { EditGroupReq } from "../api/editGroup";
import { ROLES, useAuthorization } from "../../../lib/authorization";
import { DeleteGroup } from "../api/deleteGroup";

const styleClasses =
  "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white shadow-lg p-4 md:w-6/12 sm:w-11/12 xsm:w-11/12";
export const EditSingleGroup = ({ fethcTeacherData }: any) => {
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  // const [teacher, setTeacher] = useState("");
  // const [teacherList, setTeacherList] = useState<
  //   { id: number; teacher_name: string }[]
  // >([]);
  const navigate = useNavigate();

  const [isLoading, setIsLoaging] = useState(true);
  const { checkAccess } = useAuthorization();
  const [startDateValue, setStartDateValue] = useState<Dayjs | null>();
  const [endDateValue, setEndDateValue] = useState<Dayjs | null>();
  const [startDateError, setStartDateError] = useState<string | null>(null);
  const [endDateError, setEndDateError] = useState<string | null>(null);
  // const [currentTeacher, setCurrentTeacher] = useState<any>();
  // const [selectedTeacher, setSelectedTeacher] = useState();
  const secreGrouptKey = process.env.REACT_APP_GROUP_ENCRYPTION_KEY as string;
  const [GroupData, setGroupData] = useState<any>();
  // const [teacherLoading, setTeacherLoading] = useState(true);
  const { encryptedClassId, encryptedSubjectId, encryptedGrouptId } =
    useParams();
  const [canAccess, setCanAccess] = useState(false);
  // const [startDate, setStartDate] = useState<any>();
  // const [endDate, setEndDate] = useState<any>();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm();
  const inputEditSchema = z.object({
    name: z.string(),
    fee: z.string(),
    teacher_percentage: z.number(),
    teacherId: z.number(),
    // date_start: z.string(),
    // date_end: z.string(),
  });

  const fetchGroupData = async () => {
    if (encryptedGrouptId) {
      const id = decryptionFun(encryptedGrouptId, secreGrouptKey);
      // console.log("id", id);

      if (id) {
        const response = await GetSingleGroup(id);
        setGroupData(response);
        // console.log(response);

        // setStartDate(response.date_start);
        // setEndDate(response.date_end);
        setStartDateValue(dayjs(response.date_start));
        setEndDateValue(dayjs(response.date_end));
        setCanAccess(
          checkAccess({
            allowedRoles: [ROLES.SUPERADMIN],
          })
        );

        setIsLoaging(false);
        // const startDateFormat = dayjs(startDate);
        // if (response.teacherId) {
        //   const teacherResponse = await GetCurrentTeacher(response.teacherId);
        //   if (teacherResponse.user.fullName) {
        //     setCurrentTeacher({
        //       name: teacherResponse.user.fullName,
        //       id: teacherResponse.id,
        //     }); // Set the current teacher name here
        //   }
        //   setTeacherLoading(false);
        // }
        //   ("responseteacherId", response);
        //   ("response", teacherResponse);
      }
    }
  };

  // const fetchTeacherList = async () => {
  //   try {
  //     const response: any = await GetTeacherDataReq();
  //     //   ("response", response);

  //     const simplifiedTeacherList = response.map(
  //       (teacher: { id: any; user: { fullName: any } }) => ({
  //         id: teacher.id,
  //         teacher_name: teacher.user.fullName,
  //       })
  //     );

  //     // setTeacherList(simplifiedTeacherList);
  //   } catch (error) {}
  // };

  useEffect(() => {
    fetchGroupData();
    // fetchTeacherList();
  }, []);

  // console.log("GroupDataGroupData", GroupData);

  const handleEditClick = () => {
    // console.log("GroupData", GroupData);

    setEditOpen(true);
    setValue("groupName", GroupData.name);
    setValue("price", GroupData.fee);
    setStartDateValue(dayjs(GroupData.date_start));
    setEndDateValue(dayjs(GroupData.date_end));
    // setValue("teacher_percentage", GroupData.teacher_percentage);
    // setEndDate(GroupData.date_end);
    //   (currentTeacher);
    clearErrors();
  };

  const handleEditClose = () => {
    setEditOpen(false);
  };

  const handleDeleteClick = () => {
    setDeleteOpen(true);
    // setValue("teacher_percentage", GroupData.teacher_percentage);
    // setEndDate(GroupData.date_end);
    //   (currentTeacher);
    clearErrors();
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const onDeleteSubmit = async () => {
    // console.log(`/dashboard/${encryptedClassId}/${encryptedSubjectId}`);
    const response = await DeleteGroup(GroupData.id);
    navigate(`/dashboard/${encryptedClassId}/${encryptedSubjectId}`);
    handleDeleteClose();
  };

  const onEditSubmit = async (data: any) => {
    try {
      const parsedStartDate = dayjs(startDateValue);
      const parsedEndDate = dayjs(endDateValue);

      let startDateError = null;
      let endDateError = null;

      if (
        !parsedStartDate.isValid() ||
        parsedStartDate.isBefore("2000-01-01")
      ) {
        startDateError = "Please fill in the start date.";
      }

      if (!parsedEndDate.isValid() || parsedEndDate.isBefore("2000-01-01")) {
        endDateError = "Please fill in the end date.";
      }

      // Set the error messages
      setStartDateError(startDateError);
      setEndDateError(endDateError);

      // Check if either of the error messages is not null
      if (startDateError || endDateError) {
        return; // Prevent form submission if there are errors
      }

      const acceptedStartDate = parsedStartDate.format(
        "YYYY-MM-DDTHH:mm:ss.SSSZ"
      );
      const acceptedEndDate = parsedEndDate.format("YYYY-MM-DDTHH:mm:ss.SSSZ");

      const formData = {
        name: data.groupName,
        fee: data.price,
        // teacher_percentage: data.teacher_percentage,
        date_start: acceptedStartDate,
        date_end: acceptedEndDate,
        // teacherId: currentTeacher.id,
      };

      // Log the form data with teacher_name
      //   ("Submitted Data:", startDate);

      // Validate the submitted data using the schema (excluding teacher_name)
      // const validatedData = inputEditSchema.  ("id", formData);(formData);
      //   (currentTeacher);
      // console.log(formData);

      if (encryptedGrouptId) {
        const id = decryptionFun(encryptedGrouptId, secreGrouptKey);
        //   ("id", formData);
        //   ("id", id);

        if (id) {
          // console.log(formData);

          const reponse = await EditGroupReq(id, formData);
          //   ("reponse", reponse);
        }
      }
      // Close the modal
      handleEditClose();
      fethcTeacherData();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  // const handleTeacherChange = (event: SelectChangeEvent) => {
  //   setCurrentTeacher({
  //     id: event.target.value as string,
  //   });
  // };
  // console.log(GroupData);

  return (
    <>
      {isLoading ? (
        <div className="flex font-mainfont py-6">
          <Button variant="contained" color="primary">
            <div className="font-mainfont">تعديل</div>
          </Button>
        </div>
      ) : (
        <>
          <div className="flex font-mainfont py-6">
            <Button
              onClick={handleEditClick}
              variant="contained"
              color="primary">
              <div className="font-mainfont">تعديل</div>
            </Button>
          </div>

          <Modal
            open={deleteOpen}
            onClose={handleDeleteClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box className={styleClasses}>
              <div className="flex justify-between items-center font-mainfont pb-6">
                <div>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={deleteOpen ? "long-menu" : undefined}
                    aria-expanded={deleteOpen ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleDeleteClose}>
                    <CloseIcon />
                  </IconButton>
                </div>
                <div className="font-mainfont">حذف الكروب</div>
              </div>
              <div className="flex justify-end pb-6">
                <div>هل انت متاكد من الكروب</div>
              </div>

              <div className="flex font-mainfont pt-6">
                <Button
                  onClick={onDeleteSubmit}
                  variant="contained"
                  color="primary">
                  <div className="font-mainfont">نعم</div>
                </Button>
              </div>
            </Box>
          </Modal>

          <Modal
            open={editOpen}
            onClose={handleEditClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box className={styleClasses}>
              <div className="flex justify-between items-center font-mainfont pb-6">
                <div>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={editOpen ? "long-menu" : undefined}
                    aria-expanded={editOpen ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleEditClose}>
                    <CloseIcon />
                  </IconButton>
                </div>
                <div className="font-mainfont">تعديل</div>
              </div>
              <form onSubmit={handleSubmit(onEditSubmit)}>
                <div className="py-2 flex items-center justify-between">
                  <div className="w-9/12 xsm:w-8/12">
                    <TextField
                      fullWidth
                      id="groupName"
                      placeholder="ادخل اسم الكروب"
                      {...register("groupName", { required: true })}
                      error={!!errors.groupName}
                      InputLabelProps={{
                        style: {
                          textAlign: "right", // Align the label to the right
                        },
                      }}
                      inputProps={{
                        style: {
                          textAlign: "right", // Align the input text to the right
                          direction: "rtl", // Set text direction for the input
                        },
                      }}
                      style={{
                        direction: "rtl", // Set text direction for the entire TextField
                        textAlign: "right", // Align the entire TextField to the right
                      }}
                    />
                    {errors.groupName && (
                      <div className="text-right text-xs text-red-600 pt-1">
                        الرجاء ادخال اسم الكورس
                      </div>
                    )}
                  </div>
                  <div className="font-mainfont">: اسم الكروب</div>
                </div>

                <div className="py-2 flex items-center justify-between">
                  <div className="w-9/12 xsm:w-8/12">
                    <TextField
                      fullWidth
                      id="price"
                      defaultValue={GroupData.price}
                      placeholder="ادخل سعر الكورس"
                      {...register("price", { required: true })}
                      error={!!errors.price}
                      InputLabelProps={{
                        style: {
                          textAlign: "right", // Align the label to the right
                        },
                      }}
                      InputProps={
                        {
                          // inputComponent: NumericFormatCustom as any,
                        }
                      }
                      style={{
                        direction: "rtl", // Set text direction for the entire TextField
                        textAlign: "right", // Align the entire TextField to the right
                      }}
                    />{" "}
                    {errors.price && (
                      <div className="text-right text-xs text-red-600 pt-1">
                        الرجاء ادخال سعر الكورس
                      </div>
                    )}
                  </div>
                  <div className="font-mainfont">: سعر الكورس</div>
                </div>

                <div className="py-2 flex items-center justify-between">
                  <div className="w-9/12 xsm:w-8/12">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker", "DatePicker"]}>
                        <DatePicker
                          className="w-full"
                          value={startDateValue}
                          onChange={(newValue) => setStartDateValue(newValue)}
                          format="DD-MM-YYYY"
                          minDate={dayjs("2000-01-01")}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                    {startDateError && (
                      <div className="text-right text-xs text-red-600 pt-1">
                        الرجاء ادخال تاريخ البدء
                      </div>
                    )}
                    {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DateField
                          sx={{ width: "100%" }}
                          required
                          value={startDate}
                          // defaultValue={endDate}
                          onChange={handleStartDateChange}
                          format="DD-MM-YYYY"
                        />
                      </DemoContainer>
                    </LocalizationProvider> */}
                  </div>
                  <div className="font-mainfont">: تاريخ البدء</div>
                </div>
                <div className="py-2 flex items-center justify-between">
                  <div className="w-9/12 xsm:w-8/12">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker", "DatePicker"]}>
                        <DatePicker
                          className="w-full"
                          value={endDateValue}
                          onChange={(newValue) => setEndDateValue(newValue)}
                          format="DD-MM-YYYY"
                          minDate={dayjs("2000-01-01")}
                        />
                      </DemoContainer>
                    </LocalizationProvider>{" "}
                    {endDateError && (
                      <div className="text-right text-xs text-red-600 pt-1">
                        الرجاء ادخال تاريخ الانتهاء
                      </div>
                    )}
                    {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DateField
                          sx={{ width: "100%" }}
                          required
                          value={startDate}
                          // defaultValue={endDate}
                          onChange={handleStartDateChange}
                          format="DD-MM-YYYY"
                        />
                      </DemoContainer>
                    </LocalizationProvider> */}
                  </div>
                  <div className="font-mainfont">: تاريخ الانتهاء</div>
                </div>
                {/* <div className="py-2 flex items-center justify-between">
              <div className="w-9/12 xsm:w-8/12">
                <TextField
                  fullWidth
                  id="teacher_percentage"
                  placeholder="ادخل نسبة المدرس"
                  {...register("teacher_percentage", { required: true })}
                  error={!!errors.teacher_percentage}
                  helperText={
                    errors.teacher_percentage ? (
                      <div className="text-right">الرجاء ادخال نسبة المدرس</div>
                    ) : (
                      ""
                    )
                  }
                  InputLabelProps={{
                    style: {
                      textAlign: "right", // Align the label to the right
                    },
                  }}
                  inputProps={{
                    style: {
                      textAlign: "right", // Align the input text to the right
                      direction: "rtl", // Set text direction for the input
                    },
                  }}
                  style={{
                    direction: "rtl", // Set text direction for the entire TextField
                    textAlign: "right", // Align the entire TextField to the right
                  }}
                />
              </div>
              <div className="font-mainfont">: نسبة المدرس</div>
            </div> */}
                {/* <div className="py-2 flex items-center justify-between">
              <div className="w-9/12 xsm:w-8/12">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DateField
                      sx={{ width: "100%" }}
                      required
                      value={startDate}
                      // defaultValue={endDate}
                      onChange={handleStartDateChange}
                      format="DD-MM-YYYY"
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
              <div className="font-mainfont">: تاريخ البدء</div>
            </div>
            <div className="py-2 flex items-center justify-between">
              <div className="w-9/12 xsm:w-8/12">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DateField
                      sx={{ width: "100%" }}
                      required
                      value={endDate}
                      // defaultValue={"02-12-2020"}
                      onChange={handleEndDateChange}
                      format="DD-MM-YYYY"
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
              <div className="font-mainfont">: تاريخ الانتهاء</div>
            </div> */}

                <div className="flex font-mainfont pt-6">
                  <Button type="submit" variant="contained" color="primary">
                    <div className="font-mainfont">اضافة</div>
                  </Button>
                </div>
              </form>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
};
