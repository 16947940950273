import { Card } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { encryptionFun } from "../../../lib/secretUrl";
import { useEffect, useState } from "react";
import { Waveform } from "@uiball/loaders";
import Tooltip from "@mui/material/Tooltip";
import { GetsingleSubjectData } from "../../subject/api/getSingleSubject";
import { GetsingleClassDataReq } from "../../subject/api/getSingleClass";
import { useAuth } from "../../../lib/auth";
export const TeacherGroupCard = ({ teacherGroupData, fetchData }: any) => {
  const secretClassKey = process.env.REACT_APP_CLASS_ENCRYPTION_KEY as string;
  const secretSubjectKey = process.env
    .REACT_APP_SUBJECT_ENCRYPTION_KEY as string;
  const secreGrouptKey = process.env.REACT_APP_GROUP_ENCRYPTION_KEY as string;
  const [CourseId, setCourseId] = useState<any>();
  const [Subject, setSubject] = useState<any>();
  const [classInfo, setClass] = useState<any>();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useAuth();
  const handleStageNavigae = () => {
    console.log("classInfo", classInfo);

    const enctyptedStageId = encryptionFun(classInfo.id, secretClassKey);
    console.log(enctyptedStageId);

    navigate(`/dashboard/${enctyptedStageId}`);
  };

  const handleSubjectNavigae = () => {
    const enctyptedStageId = encryptionFun(classInfo.id, secretClassKey);

    const enctyptedSubjectId = encryptionFun(Subject.id, secretSubjectKey);
    navigate(`/dashboard/${enctyptedStageId}/${enctyptedSubjectId}`);
  };

  const handleGroupNavigae = () => {
    // console.log(classInfo, Subject, CourseId);
    const enctyptedStageId = encryptionFun(classInfo.id, secretClassKey);

    const enctyptedSubjectId = encryptionFun(Subject.id, secretSubjectKey);

    const enctyptedGrouptId = encryptionFun(CourseId, secreGrouptKey);

    navigate(
      `/dashboard/${enctyptedStageId}/${enctyptedSubjectId}/${enctyptedGrouptId}`
    );
  };
  const fetchSubject = async () => {
    // console.log("teacherGroupDatateacherGroupData", teacherGroupData);

    const response = await GetsingleSubjectData(teacherGroupData.subjectId);
    setSubject({ name: response.subject_name, id: response.id });
    // console.log("responseresponseresponseresponseresponse", response);

    const response2 = await GetsingleClassDataReq({
      id: response.stageId,
      user: user,
    });
    // console.log("response2response2", response2);
    setClass({ name: response2.stage_name, id: response2.id });
    setIsLoading(false);
  };
  useEffect(() => {
    setCourseId(teacherGroupData.id);
    fetchSubject();
  }, []);
  // console.log(teacherGroupData);

  return (
    <>
      {isLoading ? (
        <div className="flex justify-center items-center p-16">
          <Waveform size={100} speed={2} color="black" />
        </div>
      ) : (
        <Card key={secretClassKey} className="bg-white rounded-md h-full">
          <div className="flex flex-col justify-between h-full">
            <div>
              <div
                className="mb-1 text-end font-bold p-3 pr-6 text-xl cursor-pointer hover:underline"
                onClick={() => handleGroupNavigae()}>
                <Tooltip title="الانتقال الى هذا الكروب">
                  {teacherGroupData?.name}
                </Tooltip>
              </div>
              <div className="border-b-2 border-cardLine w-full"></div>
              <div className="flex flex-col items-end p-6">
                <div className="flex">
                  {teacherGroupData.date_end}
                  <div className="font-semibold pl-1"> : تاريخ الانتهاء</div>
                </div>
                <div className="flex">
                  {teacherGroupData.date_start}
                  <div className="font-semibold pl-1"> : تاريخ البدء</div>
                </div>
                <div className="flex">
                  {teacherGroupData.fee}
                  <div className="font-semibold pl-1"> : سعر الكورس</div>
                </div>

                <div className="flex">
                  <div
                    className="pr-4 cursor-pointer hover:underline"
                    onClick={() => handleStageNavigae()}>
                    <Tooltip title="الانتقال الى هذا الصف">
                      {classInfo?.name}
                    </Tooltip>
                  </div>
                  <div className="font-semibold pl-1"> : الصف</div>
                </div>
                <div className="flex">
                  <div
                    className="pr-4 cursor-pointer hover:underline"
                    onClick={() => handleSubjectNavigae()}>
                    <Tooltip title="الانتقال الى هذه المادة">
                      {Subject?.name}
                    </Tooltip>
                  </div>
                  <div className="font-semibold pl-1"> : المادة</div>
                </div>
              </div>
            </div>
            <div className="p-2\ flex flex-col flex-end justify-end"></div>
          </div>
        </Card>
      )}{" "}
    </>
  );
};
