import { axios } from "../../../lib/axios";

export const EditClassReq = async (id: any, stage_name: any) => {
  const dataReq = {
    stage_name,
  };
  // console.log("name.class_name", stage_name.stage_name);
  // console.log("dataReq", dataReq.stage_name);

  const response = await axios.patch(`/stages/${id}`, dataReq);
  // console.log(response);

  return response;
};

// done types
