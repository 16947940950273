import { useForm } from "react-hook-form";
import { z } from "zod";
import { Box, Button, IconButton, Modal, TextField } from "@mui/material";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { createTeacher } from "../api/createTeacher";
import { createTeacherType } from "../types";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useAuth } from "../../../lib/auth";

const styleClasses =
  "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white shadow-lg p-4 md:w-6/12 sm:w-11/12 xsm:w-11/12";

export const inputSchema = z.object({
  teacher_name: z.string(),
  phone_number: z.string(),
  teacher_adress: z.string(),
  teacher_userName: z.string(),
  teacher_password: z.string(),
  specialty: z.string(),
});

export const CreateTeacher = ({
  teacherData,
  setTeacherData,
  fetchData,
}: any) => {
  const [open, setOpen] = useState(false);
  const { user } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm();
  const [error, setError] = useState(false);
  const handleOpen = () => {
    setOpen(true);
    setValue("teacher_name", "");
    setValue("phone_number", "");
    setValue("teacher_adress", "");
    setValue("teacher_userName", "");
    setValue("teacher_password", "");
    setValue("specialty", "");
    clearErrors();
    setError(false);
  };

  const handleClose = () => {
    setOpen(false);
    setError(false);
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const onSubmit = async (data: any) => {
    try {
      const teacherData: createTeacherType = {
        teacher_name: data.teacher_name,
        phone_number: data.phone_number,
        Adress: data.teacher_adress,
        password: data.teacher_password,
        teacher_userName: data.teacher_userName,
        specialty: data.specialty,
        schoolId: user?.schoolId,
      };

      // Call the createTeacher API function with the validated data
      const response = await createTeacher(teacherData);
      if (!response) {
        setError(true);
      } else {
        setError(false);
        // console.log("Teacher created:", response);
        handleClose();
      }

      // }
      fetchData();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <>
      <div>
        <div className="flex justify-between items-center">
          <div>
            <Button onClick={handleOpen} variant="contained" size="large">
              <div className="font-mainfont">انشاء مدرس جديد</div>
            </Button>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description">
              <Box className={styleClasses}>
                <div className="flex justify-between items-center font-mainfont pb-2">
                  <div>
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-controls={open ? "long-menu" : undefined}
                      aria-expanded={open ? "true" : undefined}
                      aria-haspopup="true"
                      onClick={handleClose}>
                      <CloseIcon />
                    </IconButton>
                  </div>
                  <div className="font-mainfont">انشاء مدرس جديد</div>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  {error ? (
                    <div className="flex justify-center p-4 text-red-500">
                      <div>عذرا هنالك مدرس يمتلك نفس المعلومات</div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="py-2 flex items-center justify-between">
                    <div className="w-9/12 xsm:w-8/12">
                      <TextField
                        fullWidth
                        id="teacher_name"
                        placeholder="ادخل اسم المدرس"
                        {...register("teacher_name", { required: true })}
                        error={!!errors.teacher_name}
                        InputLabelProps={{
                          style: {
                            textAlign: "right", // Align the label to the right
                          },
                        }}
                        inputProps={{
                          style: {
                            textAlign: "right", // Align the input text to the right
                            direction: "rtl", // Set text direction for the input
                          },
                        }}
                        style={{
                          direction: "rtl", // Set text direction for the entire TextField
                          textAlign: "right", // Align the entire TextField to the right
                        }}
                      />{" "}
                      {errors.teacher_name && (
                        <div className="text-right text-xs text-red-600 pt-1">
                          الرجاء ادخال اسم المدرس
                        </div>
                      )}
                    </div>
                    <div className="font-mainfont">: اسم المدرس</div>
                  </div>
                  <div className="py-2 flex items-center justify-between">
                    <div className="w-9/12 xsm:w-8/12">
                      <TextField
                        fullWidth
                        id="phone_number"
                        placeholder="ادخل رقم الهاتف"
                        {...register("phone_number", {
                          required: true,
                          pattern: /^07\d*$/,
                        })}
                        error={!!errors.phone_number}
                        InputLabelProps={{
                          style: {
                            textAlign: "right", // Align the label to the right
                          },
                        }}
                        inputProps={{
                          style: {
                            textAlign: "right", // Align the input text to the right
                            direction: "rtl", // Set text direction for the input
                          },
                        }}
                        style={{
                          direction: "rtl", // Set text direction for the entire TextField
                          textAlign: "right", // Align the entire TextField to the right
                        }}
                      />{" "}
                      {errors.phone_number && (
                        <div className="text-right text-xs text-red-600 pt-1">
                          {errors.phone_number.type === "pattern"
                            ? "رقم الهاتف يجب ان يبدا ب 07"
                            : "الرجاء ادخال رقم الهاتف"}
                        </div>
                      )}
                    </div>
                    <div className="font-mainfont">: رقم الهاتف</div>
                  </div>
                  <div className="py-2 flex items-center justify-between">
                    <div className="w-9/12 xsm:w-8/12">
                      <TextField
                        fullWidth
                        id="teacher_adress" // Ensure this ID matches the field name
                        placeholder="ادخل عنوان السكن"
                        {...register("teacher_adress", { required: true })}
                        error={!!errors.teacher_adress}
                        InputLabelProps={{
                          style: {
                            textAlign: "right", // Align the label to the right
                          },
                        }}
                        inputProps={{
                          style: {
                            textAlign: "right", // Align the input text to the right
                            direction: "rtl", // Set text direction for the input
                          },
                        }}
                        style={{
                          direction: "rtl", // Set text direction for the entire TextField
                          textAlign: "right", // Align the entire TextField to the right
                        }}
                      />{" "}
                      {errors.teacher_adress && (
                        <div className="text-right text-xs text-red-600 pt-1">
                          الرجاء ادخال عنوان السكن
                        </div>
                      )}
                    </div>
                    <div className="font-mainfont">: عنوان السكن</div>
                  </div>
                  <div className="py-2 flex items-center justify-between">
                    <div className="w-9/12 xsm:w-8/12">
                      <TextField
                        fullWidth
                        id="specialty" // Ensure this ID matches the field name
                        placeholder="ادخل المادة"
                        {...register("specialty", { required: true })}
                        error={!!errors.specialty}
                        InputLabelProps={{
                          style: {
                            textAlign: "right", // Align the label to the right
                          },
                        }}
                        inputProps={{
                          style: {
                            textAlign: "right", // Align the input text to the right
                            direction: "rtl", // Set text direction for the input
                          },
                        }}
                        style={{
                          direction: "rtl", // Set text direction for the entire TextField
                          textAlign: "right", // Align the entire TextField to the right
                        }}
                      />{" "}
                      {errors.specialty && (
                        <div className="text-right text-xs text-red-600 pt-1">
                          الرجاء ادخال المادة التي يدرسها
                        </div>
                      )}
                    </div>
                    <div className="font-mainfont">: الاختصاص</div>
                  </div>
                  <div className="py-2 flex items-center justify-between">
                    <div className="w-9/12 xsm:w-8/12">
                      <TextField
                        fullWidth
                        id="teacher_userName"
                        placeholder="ادخل اسم المستخدم"
                        {...register("teacher_userName", { required: true })}
                        error={!!errors.teacher_userName}
                        InputLabelProps={{
                          style: {
                            textAlign: "right", // Align the label to the right
                          },
                        }}
                        inputProps={{
                          style: {
                            textAlign: "right", // Align the input text to the right
                            direction: "rtl", // Set text direction for the input
                          },
                        }}
                        style={{
                          direction: "rtl", // Set text direction for the entire TextField
                          textAlign: "right", // Align the entire TextField to the right
                        }}
                      />{" "}
                      {errors.teacher_userName && (
                        <div className="text-right text-xs text-red-600 pt-1">
                          الرجاء ادخال اسم المستخدم
                        </div>
                      )}
                    </div>
                    <div className="font-mainfont">: اسم المستخدم</div>
                  </div>
                  <div className="py-2 flex items-center justify-between">
                    <div className="w-9/12 xsm:w-8/12">
                      <OutlinedInput
                        fullWidth
                        placeholder="ادخل الرمز السري"
                        id="teacher_password"
                        type={showPassword ? "text" : "password"}
                        {...register("teacher_password", { required: true })}
                        error={!!errors.teacher_password}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              // onMouseDown={handleMouseDownPassword}
                              edge="end">
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        inputProps={{
                          style: {
                            textAlign: "right", // Align the input text to the right
                            direction: "rtl", // Set text direction for the input
                          },
                        }}
                        style={{
                          direction: "rtl", // Set text direction for the entire TextField
                          textAlign: "right", // Align the entire TextField to the right
                        }}
                      />
                      {errors.teacher_password && (
                        <div className="text-right text-xs text-red-600 pt-1">
                          الرجاء ادخال رمز سري
                        </div>
                      )}
                    </div>
                    <div className="font-mainfont">: الرمز السري</div>
                  </div>
                  <div className="flex font-mainfont py-4">
                    <Button type="submit" variant="contained" color="primary">
                      <div className="font-mainfont">اضافة</div>
                    </Button>
                  </div>
                </form>
              </Box>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
};
