import * as React from "react";
import { BrowserRouter as Router } from "react-router-dom";

// import { Button, Spinner } from "@/components/Elements";
// import { Notifications } from "@/components/Notifications/Notifications";
import { AuthProvider } from "../lib/auth";
// import { queryClient } from "@/lib/react-query";

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <AuthProvider>
      <Router>{children}</Router>
    </AuthProvider>
  );
};
