import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { decryptionFun } from "../../../lib/secretUrl";
import { SingleSubject } from "../components/singleSubject";
import { CreateGroup } from "../../groups/components/createGroup";
import { GroupList } from "../../groups/components/groupList";

import { GetSubject } from "../../groups/api/getSubject";
import { CreateGroupReq } from "../../groups/api/createGroup";
import { useAuth } from "../../../lib/auth";
export const SingleSubjectWrapper = () => {
  const { encryptedClassId, encryptedSubjectId } = useParams();
  const secretSubjectKey = process.env
    .REACT_APP_SUBJECT_ENCRYPTION_KEY as string;

  const secretClassKey = process.env.REACT_APP_CLASS_ENCRYPTION_KEY as string;
  const { user } = useAuth();
  const [groupListData, setGroupListData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const fetchData = async () => {
    setIsLoading(true);
    setGroupListData([]);
    // console.log(encryptedSubjectId);

    if (encryptedSubjectId && encryptedClassId) {
      // console.log(encryptedSubjectId, secretSubjectKey);

      const subjectId = decryptionFun(encryptedSubjectId, secretSubjectKey);
      // console.log(subjectId);
      const classId = decryptionFun(encryptedClassId, secretClassKey);
      const response = await GetSubject(classId, subjectId, user);
      // console.log("responseresponseresponse", response?.course);
      // if (response) {
      setGroupListData(response?.course);
      setIsLoading(false);
      // }
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    // Scroll to the top of the page when the component is mounted
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  // console.log(groupListData);
  const onCreateGroup = async ({
    data,
    startDate,
    endDate,
    teacher,
    handleClose,
  }: any) => {
    // console.log(data, startDate, endDate, teacher, handleClose);
    try {
      if (encryptedSubjectId) {
        const subjectId = decryptionFun(encryptedSubjectId, secretSubjectKey);
        const schoolId = 2;
        // console.log(endDate);

        // Ensure that startDate and endDate are valid Date objects

        // console.log(acceptedStartDate, acceptedEndDate);

        // console.log(acceptedStartDate, acceptedEndDate);

        const requestData = {
          name: data.groupName,
          teacherId: Number(teacher),
          subjectId: Number(subjectId),
          schoolId: Number(schoolId),
          studentsIds: [],
          teacher_percentage: Number(data.teacher_percentage),
          discount_percentage: 0,
          fee: Number(data.fee.replace(/,/g, "")),
          date_start: startDate,
          date_end: endDate,
        };
        // console.log(requestData);
        // console.log(requestData);

        const response = await CreateGroupReq(requestData);
        // console.log(response);

        // console.log("Submitted Data:", requestData);
        // Close the modal
        // console.log(" fetchData();");
        if (response) {
          handleClose();
          fetchData();
        }
      } else {
        console.error("Error: encryptedGrouptId is missing.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  // console.log("groupListData", groupListData);

  return (
    <>
      <div className="pt-5">
        <SingleSubject />
        <div className="pt-3 p-5">
          <CreateGroup onCreateGroup={onCreateGroup} />
          <GroupList
            groupListData={groupListData}
            isLoading={isLoading}
            fetchData={fetchData}
          />
        </div>
      </div>
    </>
  );
};
