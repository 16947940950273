import { axios } from "../../../lib/axios";

export const GroupArchiveDelete = async (id: any, enteredGroupId: any) => {
  const requestBody = {
    entity: "course",
    id: enteredGroupId,
  };
  const response = await axios.delete(`/delete-archived/${id}`, {
    data: requestBody,
  });
  return response;
};
// done types
