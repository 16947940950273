import { axios } from "../../../lib/axios";
// import { createTeacherType } from "../types";

export const EditSubjectReq = async (id: any, subjectData: any) => {
  try {
    // console.log(subjectData);
    // "stageId": 1

    const response = await axios.patch(`/subjects/${id}`, subjectData);
    // console.log(response);
    return response;
  } catch (error) {
    // Handle errors here
    throw error;
  }
};
