import React, { useState } from "react";
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import SchoolIcon from "@mui/icons-material/School";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import PersonIcon from "@mui/icons-material/Person";
import ArchiveIcon from "@mui/icons-material/Archive";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { Squash as Hamburger } from "hamburger-react";
import { ROLES, useAuthorization } from "../../lib/authorization";
import { useAuth } from "../../lib/auth";
import { encryptionFun } from "../../lib/secretUrl";

type NavBarItem = {
  name: string;
  to: string;
  icon: JSX.Element;
};

const NavBar: React.FC = () => {
  const { checkAccess } = useAuthorization();
  const location = useLocation();

  const navigation: NavBarItem[] = [
    checkAccess({ allowedRoles: [ROLES.SUPERADMIN] }) && {
      name: "الارشيف",
      to: "/archives",
      icon: <ArchiveIcon style={{ color: "white", fontSize: 24 }} />,
    },
    checkAccess({ allowedRoles: [ROLES.SUPERADMIN] }) && {
      name: "الادمنية",
      to: "/admins",
      icon: <AdminPanelSettingsIcon style={{ color: "white", fontSize: 24 }} />,
    },
    checkAccess({ allowedRoles: [ROLES.ADMIN, ROLES.SUPERADMIN] }) && {
      name: "الطلاب",
      to: "/students",
      icon: <SchoolIcon style={{ color: "white", fontSize: 24 }} />,
    },
    checkAccess({ allowedRoles: [ROLES.ADMIN, ROLES.SUPERADMIN] }) && {
      name: "المدرسين",
      to: "/teachers",
      icon: <CoPresentIcon style={{ color: "white", fontSize: 24 }} />,
    },
    {
      name: "الصفوف",
      to: "/dashboard",
      icon: <GridViewRoundedIcon style={{ color: "white", fontSize: 24 }} />,
    },
  ].filter(Boolean) as NavBarItem[];

  return (
    <>
      {navigation.map((item, index) => (
        <NavLink
          key={index}
          to={item.to}
          className={`m-1 px-4 py-2 rounded-md ${
            location.pathname.includes(item.to)
              ? "bg-white bg-opacity-20"
              : "hover:bg-white hover:bg-opacity-20"
          }`}>
          <span className="text-white pr-2">{item.name}</span>
          {item.icon}
        </NavLink>
      ))}
    </>
  );
};

interface MobileNavBarProps {
  setOpen: (isOpen: boolean) => void;
}

const MobileNavBar: React.FC<MobileNavBarProps> = ({ setOpen }) => {
  const { checkAccess } = useAuthorization();
  const location = useLocation();

  const navigation: NavBarItem[] = [
    {
      name: "الصفوف",
      to: "/dashboard",
      icon: <GridViewRoundedIcon style={{ color: "white", fontSize: 24 }} />,
    },
    checkAccess({ allowedRoles: [ROLES.ADMIN, ROLES.SUPERADMIN] }) && {
      name: "المدرسين",
      to: "/teachers",
      icon: <CoPresentIcon style={{ color: "white", fontSize: 24 }} />,
    },
    checkAccess({ allowedRoles: [ROLES.ADMIN, ROLES.SUPERADMIN] }) && {
      name: "الطلاب",
      to: "/students",
      icon: <SchoolIcon style={{ color: "white", fontSize: 24 }} />,
    },
    checkAccess({ allowedRoles: [ROLES.SUPERADMIN] }) && {
      name: "الادمنية",
      to: "/admins",
      icon: <AdminPanelSettingsIcon style={{ color: "white", fontSize: 24 }} />,
    },
    checkAccess({ allowedRoles: [ROLES.SUPERADMIN] }) && {
      name: "الارشيف",
      to: "/archives",
      icon: <ArchiveIcon style={{ color: "white", fontSize: 24 }} />,
    },
  ].filter(Boolean) as NavBarItem[];

  return (
    <>
      {navigation.map((item, index) => (
        <NavLink
          key={index}
          to={item.to}
          className={`m-1 px-4 py-2 w-full rounded-md ${
            location.pathname === item.to
              ? "bg-white bg-opacity-20"
              : "hover:bg-white hover:bg-opacity-20"
          }`}
          onClick={() => setOpen(false)}>
          <span className="text-white pr-2">{item.name}</span>
          {item.icon}
        </NavLink>
      ))}
    </>
  );
};

type MainLayoutProps = {
  children: React.ReactNode;
};

export const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const { user, logout } = useAuth();
  const navigate = useNavigate(); // Define useNavigate here
  const secretKey = process.env.REACT_APP_ME_ENCRYPTION_KEY as string;
  const [isOpen, setOpen] = useState<true | false>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleProfile = () => {
    handleClose();
    if (user?.id) {
      // console.log(user?.id);
      const id = encryptionFun(Number(user?.id), secretKey);
      navigate(`/me/${id}`);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    logout();
    setAnchorEl(null);
  };
  return (
    <div className="h-full bg-black">
      <div className="flex justify-between items-center space-x-4 p-3">
        <div
          className={`m-1 px-4 py-2 rounded-md ${
            anchorEl
              ? "bg-white bg-opacity-20"
              : "hover:bg-white hover:bg-opacity-20"
          }`}>
          <button onClick={handleClick} className="flex items-center">
            <PersonIcon style={{ color: "white", fontSize: 24 }} />
            <span className="text-white pl-2">me</span>
          </button>
          <Menu
            className="mt-2 pl-10"
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}>
            <MenuItem onClick={() => handleProfile()}>الملف الشخصي</MenuItem>
            <MenuItem onClick={handleLogout}>تسجيل الخروج</MenuItem>
          </Menu>
        </div>
        <div className="hidden md:flex">
          <NavBar />
        </div>
        <div className="flex md:hidden p-px">
          <Hamburger
            toggled={isOpen}
            toggle={setOpen}
            color="white"
            size={24} // Adjust the size of the icon as needed
          />
        </div>
      </div>
      {isOpen ? (
        <div
          className={`flex justify-around flex-col text-center px-4 md:hidden ${
            isOpen
              ? "opacity-100 transition-opacity delay-1000 duration-1000 ease-in-out"
              : "opacity-0 transition-opacity delay-1000 duration-1000 ease-in-out"
          }`}>
          <MobileNavBar setOpen={setOpen} />
        </div>
      ) : (
        ""
      )}
      <div
        className={`bg-bgcolor h-full min-h-screen rounded-tl-2xl rounded-tr-2xl ${
          isOpen
            ? "translate-y-4 transition-transform ease-in-out"
            : "translate-y-0 transition-transform ease-in-out"
        }`}>
        {children}
      </div>
    </div>
  );
};
