import { axios } from "../../../lib/axios";

export const deletedStudentReq = async () => {
  const response = await axios.get(
    `/students?isDeleted=true&relations[]=student_group`
  );
  // const response2 = await axios.get(`/students&relations[]=student-payment`);
  // const resultsArray = [];
  // console.log("response", response2);
  return response;
  // Define a function to fetch user data for a given teacher
  // const fetchUserData = async (teacher: any) => {
  //   const userResponse = await axios.get(`/user/${teacher.userId}`);
  //   const userData = userResponse.data;
  //   return {
  //     teacher,
  //     user: userData,
  //   };
  // };

  // // Use Promise.all to send parallel requests and collect results
  // const userDataPromises = response.data.array.map(fetchUserData);

  // try {
  //   const userDataArray = await Promise.all(userDataPromises);
  //   resultsArray.push(...userDataArray);
  // } catch (error) {
  //   console.error("Error fetching user data:", error);
  // }

  // // console.log(resultsArray);
  // return resultsArray;
};
