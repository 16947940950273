import { Waveform } from "@uiball/loaders";
import { Card } from "@mui/material";
import { EditSubject } from "./EditSubject";
import { useNavigate, useParams } from "react-router-dom";
import { encryptionFun } from "../../../lib/secretUrl";
import { ROLES, useAuthorization } from "../../../lib/authorization";
import { CreateSubject } from "./CreateSubject";
import { useEffect, useState } from "react";
import { decryptionFun } from "../../../lib/secretUrl";
import { GetsingleClassDataReq } from "../../subject/api/getSingleClass";
import { Subjects } from "../../singleClass/types";
import { useAuth } from "../../../lib/auth";

export const SubjectList = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [subjectListData, setSubjectListData] = useState<Subjects[]>([]);
  const { encryptedClassId } = useParams();
  const secretClassKey = process.env.REACT_APP_CLASS_ENCRYPTION_KEY as string;
  const secretKey = process.env.REACT_APP_SUBJECT_ENCRYPTION_KEY as string;
  const navigate = useNavigate();
  const { checkAccess } = useAuthorization();
  const { user } = useAuth();
  const fetchData = async () => {
    try {
      // setSubjectListData([]);
      setIsLoading(true);
      // console.log(encryptedClassId);

      // setSubjectListData([]);
      if (encryptedClassId) {
        const id = decryptionFun(encryptedClassId, secretClassKey);
        if (id) {
          try {
            // console.log("useruser", user);

            const response = await GetsingleClassDataReq({ id, user }); // Pass both id and isTeacher
            // console.log("response", response);

            const subject = response.subjects;
            // console.log(subject);

            setSubjectListData(subject);
            setIsLoading(false);
          } catch (error) {
            console.error("Error fetching class data:", error);
          }
        }
      }
      // console.log(response);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleClass = (id: number) => {
    const enctyptedSubjectId = encryptionFun(id, secretKey);
    navigate(`/dashboard/${encryptedClassId}/${enctyptedSubjectId}`);
  };
  useEffect(() => {
    fetchData();
  }, []);
  // console.log("subjectListData", subjectListData);

  return (
    <>
      <CreateSubject fetchData={fetchData} />
      <div>
        {isLoading ? (
          <div className="flex justify-center items-center p-16">
            <Waveform size={40} speed={2} color="black" />
          </div>
        ) : (
          <div>
            {subjectListData.length === 0 ? (
              <div className="flex justify-center p-5">
                <div>لا يوجد مواد</div>
              </div>
            ) : (
              <div className="flex flex-wrap justify-end pt-2">
                {subjectListData?.map((data, index: number) => (
                  <div
                    key={index}
                    className="w-full sm:w-1/2 md:w-1/3 lg:w-2/6 p-2 h-fit">
                    <Card className="bg-white rounded-md h-fit">
                      <div className="flex flex-col h-fit justify-between">
                        <div
                          className="cursor-pointer"
                          onClick={() => handleClass(data.id)}>
                          <div className="mb-1 text-end font-bold p-3 pr-6 text-xl">
                            {data.subject_name}
                          </div>
                        </div>
                        {checkAccess({
                          allowedRoles: [ROLES.ADMIN, ROLES.SUPERADMIN],
                        }) ? (
                          <div>
                            <div className="border-b-2 border-cardLine w-full p-3"></div>
                            <EditSubject id={data.id} fetchData={fetchData} />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </Card>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

// done types
