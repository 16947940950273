import { useEffect, useState } from "react";
import { StudentHeader } from "../components/studentHeader";
import { StudentTable } from "../components/studentTable";
import { Button, TextField } from "@mui/material";
import { GetStudentDataReq } from "../api/getStudentData";
import { CreateStudent } from "../components/createStudent";
import { useForm } from "react-hook-form";
import { Waveform } from "@uiball/loaders";
export const StudentWrapper = () => {
  const [originalStudentData, setOriginalStudentData] = useState([]); // Original data
  const [filteredStudentData, setFilteredStudentData] = useState([]); // Filtered data
  const [isLoading, setIsLoading] = useState(true);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const fetchData = async () => {
    try {
      // Fetch teacher data using an API call
      const response = await GetStudentDataReq();
      setOriginalStudentData(response); // Set original data
      setFilteredStudentData(response); // Initialize filtered data with the original data
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching teacher data:", error);
    }
  };

  const onSubmit = async (data: any) => {
    const filter = data.search_student_name || ""; // Get the filter from the form

    if (filter === "") {
      // If the input is empty, reset to the original data
      setFilteredStudentData(originalStudentData);
    } else {
      // Filter the studentData based on the filter string
      const filteredData = originalStudentData.filter((student) => {
        // Check if any string field contains the filter string
        for (const key in student) {
          if (
            typeof student[key] === "string" &&
            (student[key as keyof typeof student] as string).includes(filter)
          ) {
            return true;
          }
        }
        return false;
      });

      setFilteredStudentData(filteredData); // Set filtered data
    }
  };

  useEffect(() => {
    // Scroll to the top of the page when the component is mounted
    window.scrollTo({ top: 0, behavior: "smooth" });
    fetchData();
  }, []);

  return (
    <div className="p-10">
      {isLoading ? (
        <div className="flex justify-center items-center p-16">
          <Waveform size={100} speed={2} color="black" />
        </div>
      ) : (
        <>
          <div className="flex justify-between items-center mb-2">
            <div>
              <CreateStudent fetchData={fetchData} />
            </div>
            <div className="flex items-center">
              <div className="pr-2 md:block sm:hidden xsm:hidden">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Button type="submit" variant="contained" color="primary">
                    <div className="font-mainfont">بحث</div>
                  </Button>
                  <TextField
                    size="small"
                    id="search_student_name"
                    placeholder="البحث"
                    {...register("search_student_name")}
                    InputLabelProps={{
                      style: {
                        textAlign: "right",
                      },
                    }}
                    inputProps={{
                      style: {
                        textAlign: "right",
                        direction: "rtl",
                      },
                    }}
                    style={{
                      direction: "rtl",
                      textAlign: "right",
                    }}
                  />
                </form>
              </div>
              <div className="font-mainfont text-3xl">الطلاب</div>
            </div>
          </div>{" "}
          <div className="md:hidden sm:flex xsm:flex justify-end w-full">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="w-full flex justify-end">
              <Button type="submit" variant="contained" color="primary">
                <div className="font-mainfont">بحث</div>
              </Button>
              <TextField
                size="small"
                id="search_student_name"
                fullWidth
                placeholder="البحث"
                {...register("search_student_name")}
                InputLabelProps={{
                  style: {
                    textAlign: "right",
                  },
                }}
                inputProps={{
                  style: {
                    textAlign: "right",
                    direction: "rtl",
                  },
                }}
                style={{
                  direction: "rtl",
                  textAlign: "right",
                }}
              />
            </form>
          </div>
          <div className="border-b-2 border-mainLine pt-2"></div>
          <StudentTable
            fetchData={fetchData}
            studentData={filteredStudentData}
          />
        </>
      )}
    </div>
  );
};
