import { axios } from "../../../lib/axios";
// import { ClassTypes } from "../types";

export const EditGroupReq = async (id: any, data: any) => {
  // console.log(data);

  const response = await axios.patch(`/courses/${id}`, data); // Use stage_name key
  // console.log(response.data);

  return response.data;
};
