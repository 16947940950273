import { Breadcrumbs, Link } from "@mui/material";
import { useEffect, useState } from "react";
import { Waveform } from "@uiball/loaders";
import { useParams } from "react-router-dom";
import { GetSingleTeacherDataReq } from "../api/getSingleTeacherData";
import { decryptionFun } from "../../../lib/secretUrl";
import { MetaDataCard } from "../../../components/elements/metaDataCard";
import { EditTeacher } from "./editTeacher";
import { DeleteTeacher } from "./deleteTeacher";
// import { TeacherProfileMetaDataCard } from "./teacherProfileMetaDataCard";
import { ROLES, useAuthorization } from "../../../lib/authorization";
import ChangeTeacherPassword from "./changeTeacherPassword";
export const TeacherProfile = () => {
  const { encryptedTeacherId } = useParams();
  const secretKey = process.env.REACT_APP_TEACHER_ENCRYPTION_KEY as string;
  const [isLoading, setIsLoading] = useState(true);
  const [teacherProfileData, setTeacherProfileData] = useState<any>();
  const { checkAccess } = useAuthorization();
  const [canAccess, setCanAccess] = useState(false);
  const fetchSingleTeacherMetaData = async () => {
    if (encryptedTeacherId) {
      const id = decryptionFun(encryptedTeacherId, secretKey);
      try {
        const response = await GetSingleTeacherDataReq(id); // Pass the id as an object property

        if (response) {
          setTeacherProfileData(response);
        }
        setCanAccess(
          checkAccess({
            allowedRoles: [ROLES.SUPERADMIN],
          })
        );
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching teacher data:", error);
      }
    }
  };
  useEffect(() => {
    fetchSingleTeacherMetaData();
  }, []);
  // console.log("teacherProfileData", teacherProfileData.course);

  return (
    <div>
      <div>
        {isLoading ? (
          <div className="flex justify-center items-center p-16">
            <Waveform size={100} speed={2} color="black" />
          </div>
        ) : (
          <>
            <div className="pl-5 flex justify-between">
              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/teachers">
                  <div className="font-mainfont text-md">المدرسين</div>
                </Link>
                <div className="font-mainfont text-md">
                  {/* {singleTeacherMetaData?.teacher_name} */}
                  {teacherProfileData?.user.fullName}
                </div>
              </Breadcrumbs>
            </div>
            <div className="flex flex-col px-5">
              <div className="flex justify-between items-center">
                <div className="flex justify-start">
                  {canAccess ? (
                    teacherProfileData?.course.length === 0 ? (
                      <DeleteTeacher
                        TeacherProfileData={teacherProfileData}
                        fetchSingleTeacherMetaData={fetchSingleTeacherMetaData}
                      />
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}

                  <EditTeacher
                    TeacherProfileData={teacherProfileData}
                    fetchSingleTeacherMetaData={fetchSingleTeacherMetaData}
                  />

                  <ChangeTeacherPassword
                    TeacherProfileData={teacherProfileData}
                  />
                </div>
                <div className="font-mainfont text-3xl">
                  {teacherProfileData?.user.fullName}
                </div>
              </div>
              <div className="border-b-2 border-mainLine"></div>
            </div>
            {/* <EditTeacher teacherData={singleTeacherMetaData} /> */}
            <div className="flex flex-wrap justify-end p-3">
              <MetaDataCard
                index={1}
                title="رقم الهاتف"
                data={teacherProfileData?.phone}
              />{" "}
              <MetaDataCard
                index={4}
                title="الاختصاص"
                data={teacherProfileData?.specialty}
              />
              <MetaDataCard
                index={2}
                title="عنوان السكن"
                data={teacherProfileData?.address}
              />{" "}
              <MetaDataCard
                index={3}
                title="اسم المستخدم"
                data={teacherProfileData?.user.userName}
              />{" "}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
