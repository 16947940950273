import { ResponseInterceptor } from "@/features/teachers/api/getTeacherData";
import { axios } from "../../../lib/axios";

const requestBody = {
  entity: "subject",
};

export const undeleteSubjectReq = async (id: any) => {
  const response = await axios.patch(`/undelete/${id}`, requestBody);
  return response.data;
};
// done types
