import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Box, Button, IconButton, Modal, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { EditTeacherReq } from "../api/editTeacher";
import { notification } from "antd";
import type { NotificationPlacement } from "antd/es/notification/interface";

export const inputSchema = z.object({
  teacherName: z.string(),
  phone: z.string(),
  address: z.string(),
  userName: z.string(),
  specialty: z.string(),
  // password: z.string(),
});

const styleClasses =
  "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white shadow-lg p-4 md:w-6/12 sm:w-11/12 xsm:w-11/12";
const Context = React.createContext({ name: "Default" });

export const EditTeacher = ({
  TeacherProfileData,
  fetchSingleTeacherMetaData,
}: {
  TeacherProfileData: any;
  fetchSingleTeacherMetaData: any;
}) => {
  const [open, setOpen] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [error, setError] = useState(false);
  const openNotification = (placement: NotificationPlacement) => {
    api.success({
      message: `تم تعديل معلومات الاستاذ بنجاح`,
      // description: <Context.Consumer>{({ name }) => ``}</Context.Consumer>,
      placement,
    });
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm();

  const handleOpen = () => {
    setValue("teacherName", TeacherProfileData?.user.fullName);
    setValue("phone", TeacherProfileData?.phone);
    setValue("address", TeacherProfileData?.address);
    setValue("userName", TeacherProfileData?.user.userName);
    setValue("specialty", TeacherProfileData?.specialty);
    clearErrors();
    setError(false);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async (data: any) => {
    try {
      // console.log("TeacherProfileData", TeacherProfileData);

      const response = await EditTeacherReq({
        id: TeacherProfileData.id,
        data: data,
      });
      // console.log(data);
      if (response.status === 200) {
        openNotification("topLeft");
        fetchSingleTeacherMetaData();
        handleClose();
      }
    } catch (error) {
      // openErrorNotification("topLeft");
      setError(true);
    }
  };

  const contextValue = useMemo(() => ({ name: "Ant Design" }), []);
  // console.log(TeacherProfileData);

  return (
    <Context.Provider value={contextValue}>
      {contextHolder}
      <div className="flex font-mainfont py-6 px-4">
        <Button
          onClick={handleOpen}
          type="submit"
          variant="contained"
          color="primary">
          <div className="font-mainfont">تعديل</div>
        </Button>
      </div>
      <div className="z-0">
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box className={styleClasses}>
            <div className="flex justify-between items-center font-mainfont pb-2">
              <div>
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={open ? "long-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </div>
              <div className="font-mainfont">انشاء مدرس جديد</div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="py-2 flex items-center justify-between">
                <div className="w-9/12 xsm:w-8/12">
                  <TextField
                    fullWidth
                    id="teacherName"
                    placeholder="ادخل اسم المدرس"
                    {...register("teacherName", { required: true })}
                    error={!!errors.teacherName}
                    helperText={
                      errors.teacherName ? (
                        <div className="text-right">
                          الرجاء ادخال اسم المدرس
                        </div>
                      ) : (
                        ""
                      )
                    }
                    InputLabelProps={{
                      style: {
                        textAlign: "right", // Align the label to the right
                      },
                    }}
                    inputProps={{
                      style: {
                        textAlign: "right", // Align the input text to the right
                        direction: "rtl", // Set text direction for the input
                      },
                    }}
                    style={{
                      direction: "rtl", // Set text direction for the entire TextField
                      textAlign: "right", // Align the entire TextField to the right
                    }}
                  />
                </div>
                <div className="font-mainfont">: اسم المدرس</div>
              </div>
              <div className="py-2 flex items-center justify-between">
                <div className="w-9/12 xsm:w-8/12">
                  <TextField
                    fullWidth
                    id="phone"
                    placeholder="ادخل رقم الهاتف"
                    {...register("phone", {
                      required: true,
                    })}
                    error={!!errors.phone}
                    helperText={
                      errors.phone ? (
                        <div className="text-right">
                          الرجاء ادخال رقم الهاتف
                        </div>
                      ) : (
                        ""
                      )
                    }
                    InputLabelProps={{
                      style: {
                        textAlign: "right", // Align the label to the right
                      },
                    }}
                    inputProps={{
                      style: {
                        textAlign: "right", // Align the input text to the right
                        direction: "rtl", // Set text direction for the input
                      },
                    }}
                    style={{
                      direction: "rtl", // Set text direction for the entire TextField
                      textAlign: "right", // Align the entire TextField to the right
                    }}
                  />
                </div>
                <div className="font-mainfont">: رقم الهاتف</div>
              </div>
              <div className="py-2 flex items-center justify-between">
                <div className="w-9/12 xsm:w-8/12">
                  <TextField
                    fullWidth
                    id="address" // Ensure this ID matches the field name
                    placeholder="ادخل عنوان السكن"
                    {...register("address", { required: true })}
                    error={!!errors.address}
                    helperText={
                      errors.address ? (
                        <div className="text-right">
                          الرجاء ادخال عنوان السكن
                        </div>
                      ) : (
                        ""
                      )
                    }
                    InputLabelProps={{
                      style: {
                        textAlign: "right", // Align the label to the right
                      },
                    }}
                    inputProps={{
                      style: {
                        textAlign: "right", // Align the input text to the right
                        direction: "rtl", // Set text direction for the input
                      },
                    }}
                    style={{
                      direction: "rtl", // Set text direction for the entire TextField
                      textAlign: "right", // Align the entire TextField to the right
                    }}
                  />
                </div>
                <div className="font-mainfont">: عنوان السكن</div>
              </div>
              <div className="py-2 flex items-center justify-between">
                <div className="w-9/12 xsm:w-8/12">
                  <TextField
                    fullWidth
                    id="specialty" // Ensure this ID matches the field name
                    placeholder="ادخل المادة"
                    {...register("specialty", { required: true })}
                    error={!!errors.specialty}
                    InputLabelProps={{
                      style: {
                        textAlign: "right", // Align the label to the right
                      },
                    }}
                    inputProps={{
                      style: {
                        textAlign: "right", // Align the input text to the right
                        direction: "rtl", // Set text direction for the input
                      },
                    }}
                    style={{
                      direction: "rtl", // Set text direction for the entire TextField
                      textAlign: "right", // Align the entire TextField to the right
                    }}
                  />{" "}
                  {errors.specialty && (
                    <div className="text-right text-xs text-red-600 pt-1">
                      الرجاء ادخال المادة التي يدرسها
                    </div>
                  )}
                </div>
                <div className="font-mainfont">: الاختصاص</div>
              </div>
              <div className="py-2 flex items-center justify-between">
                <div className="w-9/12 xsm:w-8/12">
                  <TextField
                    fullWidth
                    id="userName"
                    placeholder="ادخل اسم المستخدم"
                    {...register("userName", { required: true })}
                    error={!!errors.userName}
                    helperText={
                      errors.userName ? (
                        <div className="text-right">
                          الرجاء ادخال اسم المستخدم
                        </div>
                      ) : (
                        ""
                      )
                    }
                    InputLabelProps={{
                      style: {
                        textAlign: "right", // Align the label to the right
                      },
                    }}
                    inputProps={{
                      style: {
                        textAlign: "right", // Align the input text to the right
                        direction: "rtl", // Set text direction for the input
                      },
                    }}
                    style={{
                      direction: "rtl", // Set text direction for the entire TextField
                      textAlign: "right", // Align the entire TextField to the right
                    }}
                  />
                </div>
                <div className="font-mainfont"> : اسم المستخدم</div>
              </div>
              {error ? (
                <div className="flex justify-center">
                  <div className="text-right text-s text-red-600 pt-1 pb-2">
                    هذه المعلومات موجودة بالفعل عند مستخدم اخر
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="flex font-mainfont py-4">
                <Button type="submit" variant="contained" color="primary">
                  <div className="font-mainfont">تعديل</div>
                </Button>
              </div>
            </form>
          </Box>
        </Modal>
      </div>
    </Context.Provider>
  );
};
