import { Breadcrumbs, Button, Link } from "@mui/material";
import { useEffect, useState } from "react";
import { GetsingleClassData } from "../api/getSingleClassData";
import { useNavigate, useParams } from "react-router-dom";
import { decryptionFun } from "../../../lib/secretUrl";
import { Waveform } from "@uiball/loaders";
import { MetaDataCard } from "../../../components/elements/metaDataCard";
import { EditSingleClass } from "./editSingleClass";
import { ROLES, useAuthorization } from "../../../lib/authorization";
import { SingleClassMetaDataType } from "../types";

export const SingleClass = () => {
  const { checkAccess } = useAuthorization();
  const { encryptedClassId } = useParams();
  const secretKey = process.env.REACT_APP_CLASS_ENCRYPTION_KEY as string;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [singleClassMetaDataState, setSingleClassMetaDataState] =
    useState<SingleClassMetaDataType | null>();

  const fetchMetaData = async () => {
    setSingleClassMetaDataState(null);
    if (encryptedClassId) {
      const id = decryptionFun(encryptedClassId, secretKey);
      // console.log("ididid", id);

      try {
        const response = await GetsingleClassData(id);
        setSingleClassMetaDataState(response);
        // console.log("response", response);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  useEffect(() => {
    fetchMetaData();
  }, [encryptedClassId, secretKey]);
  const navigate = useNavigate();
  // console.log("singleClassMetaDataState", singleClassMetaDataState);

  return (
    <div>
      {isLoading ? (
        <div className="flex justify-center items-center p-16">
          <Waveform size={40} speed={2} color="black" />
        </div>
      ) : (
        <div>
          <div className="pl-5 flex justify-between">
            <Breadcrumbs aria-label="breadcrumb">
              <div
                className="font-mainfont sm:text-sm xsm:text-xs md:text-md hover:underline cursor-pointer"
                onClick={() => navigate(`/dashboard`)}>
                الصفوف
              </div>
              <div className="font-mainfont sm:text-sm xsm:text-xs md:text-md hover:underline cursor-pointer">
                {singleClassMetaDataState?.stage_name}
              </div>
            </Breadcrumbs>
          </div>
          {checkAccess({ allowedRoles: [ROLES.ADMIN, ROLES.SUPERADMIN] }) && (
            <>
              <EditSingleClass fetchMetaData={fetchMetaData} />
              <div className="flex flex-wrap justify-end px-5">
                <MetaDataCard
                  index={0}
                  title="عدد المواد"
                  data={singleClassMetaDataState?.subjects.length}
                />
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

// done types
