import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { Fragment, Key, useEffect, useState } from "react";
import { encryptionFun } from "../../../lib/secretUrl";
import { useNavigate } from "react-router-dom";
function Row(props: { row: any }) {
  const { row } = props;
  const [open, setOpen] = useState(false);
  const secretKey = process.env.REACT_APP_STUDENT_ENCRYPTION_KEY as string;

  const navigate = useNavigate();
  const handleNavigate = () => {
    const id = encryptionFun(row.id, secretKey);
    navigate(`/students/${id}`);
  };

  const handleSetOpen = async (id: number) => {
    setOpen(!open);
  };
  // const fetchSubjectData = async (subjectId: any) => {
  //   const response = await GetsingleSubjectData(subjectId);
  //   // console.log(response.subject_name);

  //   return response.subject_name;
  // };
  // // console.log(singleStudentCourses);
  // const fetchSubject = (item: any) => {
  //   // console.log(item.course?.subjectId);
  //   if (item?.course?.subjectId) {
  //     try {
  //       const response = fetchSubjectData(item?.course?.subjectId);
  //       console.log(response);
  //       return `${response}`;
  //     } catch (error) {
  //       console.error("Error fetching subject:", error);
  //       return ""; // Return an empty string or any fallback value in case of an error
  //     }
  //   } else {
  //     return "";
  //   }
  // };
  // fetchSubject(item);
  return (
    <Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell align="center">
          <Button
            onClick={() => handleNavigate()}
            variant="contained"
            color="primary">
            <div className="font-mainfont">عرض الملف الشخصي</div>
          </Button>
        </TableCell>
        {/* <TableCell align="center"></TableCell>
        <TableCell align="center"></TableCell>
        <TableCell align="center"></TableCell> */}
        <TableCell align="center">{row.parent_phone}</TableCell>
        <TableCell align="center">{row.address}</TableCell>
        <TableCell align="center">{row.phone}</TableCell>
        <TableCell align="center">
          <div className="flex justify-center items-center">
            {row.student_name}
            {/* <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => handleSetOpen(row.id)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton> */}
          </div>
        </TableCell>
      </TableRow>
      {/* <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit className="py-10">
            {row.student_group.length > 0 ? (
              <div className="flex flex-col justify-end items-end">
                <div className="text-xl font-mainfont pr-10 pb-4">الكروبات</div>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">الاجرائات</TableCell>
                      <TableCell align="center">القسط المتبقي</TableCell>
                      <TableCell align="center">القسط المدفوع</TableCell>
                      <TableCell align="center">القسط الكامل</TableCell>
                      <TableCell align="center">عدد الطلاب</TableCell>
                      <TableCell align="center">مدة الكورس</TableCell>
                      <TableCell align="center">الكروب</TableCell>
                      <TableCell align="center">المادة</TableCell>
                      <TableCell align="center">الصف</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.student_group?.map((item: any, index: any) => (
                      <TableRow key={index}>
                        <TableCell align="center">
                          <div className="flex">
                            <div className="p-2">
                              <Button variant="outlined" color="error">
                                <div className="font-mainfont">ازالة دفعة</div>
                              </Button>
                            </div>
                            <div className="p-2">
                              <CreateStudentPayment />
                            </div>
                          </div>
                        </TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center">
                          {" "}
                          بداية الكورس - نهاية الكورس
                          <br /> <br />
                          {item.course.date_end} - {item.course.date_start}
                        </TableCell>
                        <TableCell align="center">{item.course.name}</TableCell>
                        <TableCell align="center">
                          
                        </TableCell>
                        <TableCell align="center">الصف</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            ) : (
              <div className="flex justify-center p-5">
                <div>لا يوجد كورسات لهذا الطالب</div>
              </div>
            )}
          </Collapse>
        </TableCell>
      </TableRow> */}
    </Fragment>
  );
}

export const StudentTable = ({ fetchData, studentData }: any) => {
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="pt-5">
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              {/* <TableCell /> */}
              <TableCell align="center">
                <div className="font-mainfont text-base"></div>
              </TableCell>
              {/* <TableCell align="center">
                <div className="font-mainfont text-base">
                  اجمالي المبلغ المتبقي
                </div>
              </TableCell>
              <TableCell align="center">
                <div className="font-mainfont text-base">
                  اجمالي المبلغ المدفوع
                </div>
              </TableCell>
              <TableCell align="center">
                <div className="font-mainfont text-base">
                  اجمالي المبلغ المستحق
                </div>
              </TableCell> */}
              <TableCell align="center">
                <div className="font-mainfont text-base">رقم هاتف الاهل</div>
              </TableCell>
              <TableCell align="center">
                <div className="font-mainfont text-base">عنوان السكن</div>
              </TableCell>
              <TableCell align="center">
                <div className="font-mainfont text-base">رقم الهاتف</div>
              </TableCell>
              <TableCell align="center">
                <div className="font-mainfont text-base">الاسم</div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {studentData.length > 0
              ? studentData?.map((row: any, index: Key | null | undefined) => (
                  <Row key={index} row={row} />
                ))
              : ""}
          </TableBody>
        </Table>
      </TableContainer>
      {studentData.length > 0 ? (
        ""
      ) : (
        <div className="flex justify-center p-5">
          <div>لا يوجد طلاب</div>
        </div>
      )}
    </div>
  );
};
