import { DotSpinner } from "@uiball/loaders";
import { User } from "../types";
import { AdminCard } from "./adminCard";
export const AdminList = ({ isLoading, adminData, fetchClassData }: any) => {
  // console.log(isLoading);

  return (
    <>
      <div>
        {isLoading ? (
          <div className="flex justify-center items-center p-16">
            <DotSpinner size={100} speed={2} color="black" />
          </div>
        ) : (
          <div>
            {adminData === null ? (
              <div className="flex justify-center pt-4">
                <div>لا يوجد اي ادمن</div>
              </div>
            ) : (
              <div className="flex flex-wrap justify-end pt-2">
                {adminData?.map((adminItem: User, index: number) => (
                  <div
                    className="w-full sm:w-1/2 md:w-1/3 lg:w-2/6 p-2"
                    key={index}>
                    <AdminCard
                      id={adminItem.id}
                      name={adminItem.fullName}
                      username={adminItem.userName}
                      fetchClassData={fetchClassData}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};
