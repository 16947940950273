import { encryptionFun } from "../../../lib/secretUrl";
import { Box, Button, IconButton, Modal } from "@mui/material";
import React, { useState, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { CreateStudentPayment } from "./creteStudentPayment";
import CloseIcon from "@mui/icons-material/Close";
import { CreateStudentPaymentReq } from "../api/createStudentPayment";
import { DeleteStudentFromGroup } from "../api/deleteStudentFromGroup";
import { notification } from "antd";
import type { NotificationPlacement } from "antd/es/notification/interface";
const styleClasses =
  "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white shadow-lg p-4 md:w-6/12 sm:w-11/12 xsm:w-11/12";
const Context = React.createContext({ name: "Default" });

export const EditStudentGroupButton = ({
  studentGroup,
  handleSingleStudne,
  didPayFee,
}: any) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const secretKey = process.env.REACT_APP_STUDENT_ENCRYPTION_KEY as string;
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement: NotificationPlacement) => {
    api.success({
      message: `تم اضافة المبلغ بنجاح`,
      // description: <Context.Consumer>{({ name }) => ``}</Context.Consumer>,
      placement,
    });
  };
  const {
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm();
  const handleOpen = () => {
    setOpen(true);
    clearErrors();
  };
  const handleStudent = () => {
    const decId = encryptionFun(studentGroup.studentId, secretKey);
    navigate(`/students/${decId}`);
  };
  const handleClose = () => {
    setOpen(false);
    setValue("adminName", "");
  };
  // console.log(studentGroup);

  const onSubmit = async () => {
    try {
      // console.log("studentGroup.studentGroup.id", studentGroup.studentGroupId);
      const response = await DeleteStudentFromGroup(
        studentGroup.studentGroupId
      );
      handleClose();
      handleSingleStudne();

      // console.log(studentGroup.studentGroupId);
      // fetchData();
    } catch (error) {
      handleSingleStudne();
      // console.error("Error submitting form:", error);
    }
  };

  const createPay = async ({ validDate, setItsBegger }: any) => {
    // console.log(
    //   Number(validDate.amount_paid),
    //   Number(studentGroup.totalFee) - Number(studentGroup.allStudentPayment)
    // );
    try {
      if (
        Number(validDate.amount_paid) > Number(studentGroup.totalFee) ||
        Number(validDate.amount_paid) >
          Number(studentGroup.totalFee) - Number(studentGroup.allStudentPayment)
      ) {
        setItsBegger(true);
        return false;
      } else {
        // console.log("wird");
        const response = await CreateStudentPaymentReq(validDate);
        handleSingleStudne();
        return true;
        // console.log(response);
      }

      // console.log(response);
      // fetchData();
    } catch (error) {
      console.error("Error creating payment:", error);
    }
  };
  // console.log("profile", handleSingleStudne);
  const contextValue = useMemo(() => ({ name: "Ant Design" }), []);

  return (
    <>
      <Context.Provider value={contextValue}>
        {contextHolder}
        <div className="flex items-center">
          <div className="p-1">
            <Button
              onClick={() => handleOpen()}
              variant="outlined"
              color="error">
              <div className="font-mainfont">حذف</div>
            </Button>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description">
              <Box className={styleClasses}>
                <div className="flex justify-between items-center font-mainfont pb-6">
                  <div>
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-controls={open ? "long-menu" : undefined}
                      aria-expanded={open ? "true" : undefined}
                      aria-haspopup="true"
                      onClick={handleClose}>
                      <CloseIcon />
                    </IconButton>
                  </div>
                  <div className="font-mainfont">ازالة الطالب</div>
                </div>
                <div className="flex justify-end pb-6">
                  <div>هل انت متاكد من ازالة الطالب من هذا الصف </div>
                </div>
                <div className="flex font-mainfont pt-6">
                  <Button
                    onClick={onSubmit}
                    variant="contained"
                    color="primary">
                    <div className="font-mainfont">نعم</div>
                  </Button>
                </div>
              </Box>
            </Modal>
          </div>
          <div className="p-1">
            <CreateStudentPayment
              studentGroup={studentGroup}
              createPay={createPay}
              didPayFee={didPayFee}
            />
          </div>
          <div className="p-1">
            <Button
              onClick={() => handleStudent()}
              variant="contained"
              color="primary">
              <div className="font-mainfont">عرض الملف الشخصي</div>
            </Button>
          </div>
        </div>{" "}
      </Context.Provider>
    </>
  );
};
