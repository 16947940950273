import { Box, Button, IconButton, Modal, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import React, { useMemo } from "react";
import { notification } from "antd";
import type { NotificationPlacement } from "antd/es/notification/interface";
import { useParams } from "react-router-dom";
import { decryptionFun } from "../../../lib/secretUrl";
import { GetTeacherDataReq } from "../../teachers/api/getTeacherData";
import CloseIcon from "@mui/icons-material/Close";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";

import { GetSingleGroup } from "../api/getSingleGroup";
import { GetSingleTeacherDataReq } from "../../teachers/api/getSingleTeacherData";
import { EditGroupTeacher } from "../api/editGroupTeacher";

export const styleClasses =
  "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white shadow-lg p-4 md:w-6/12 sm:w-11/12 xsm:w-11/12";
const Context = React.createContext({ name: "Default" });

export const ChangeTeacher = ({ fetchData }: any) => {
  const [open, setOpen] = useState(false);

  const secreGrouptKey = process.env.REACT_APP_GROUP_ENCRYPTION_KEY as string;
  const [api, contextHolder] = notification.useNotification();
  const { encryptedGrouptId } = useParams();
  const openNotification = (placement: NotificationPlacement) => {
    api.success({
      message: `تم اضافة المبلغ بنجاح`,
      // description: <Context.Consumer>{({ name }) => ``}</Context.Consumer>,
      placement,
    });
  };
  const [teacher, setTeacher] = useState<any>(0);
  const [teacherList, setTeacherList] = useState<
    { id: number; teacher_name: string }[]
  >([]);

  const handleChange = (event: any) => {
    setTeacher(event.id as string);
  };

  const fetchTeacherList = async () => {
    try {
      const response = await GetTeacherDataReq();
      // Extract id and teacher_name from the response and create an array of objects
      // console.log("responseresponseresponse", response[0].user.userName);
      const simplifiedTeacherList = response.map((user: any) => ({
        id: user.id,
        teacher_name: user.user.fullName,
      }));
      // console.log("simplifiedTeacherList", simplifiedTeacherList);

      // Set the simplified teacher list in the state
      setTeacherList(simplifiedTeacherList);
      // console.log(simplifiedTeacherList);
    } catch (error) {
      console.log(error);
    }
    if (encryptedGrouptId) {
      const GroupId = decryptionFun(encryptedGrouptId, secreGrouptKey);
      // console.log(GroupId);

      const response = await GetSingleGroup(GroupId);
      const teacherResponse = await GetSingleTeacherDataReq(response.teacherId);
      // console.log("teacherresponse", teacherResponse);
      setTeacher(teacherResponse.id);
    }
  };
  useEffect(() => {
    fetchTeacherList();
  }, []);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm();
  // const fetchTeacherList = async () => {
  //   try {
  //     const response: any = await GetTeacherDataReq();
  //     //   ("response", response);

  //     const simplifiedTeacherList = response.map(
  //       (teacher: { id: any; user: { fullName: any } }) => ({
  //         id: teacher.id,
  //         teacher_name: teacher.user.fullName,
  //       })
  //     );

  //     // setTeacherList(simplifiedTeacherList);
  //   } catch (error) {}
  // };
  const handleClick = () => {
    setOpen(true);
    setValue("payment_amount", ""); // Reset input value when modal is opened
    // clearErrors();
    // fetchEditData();
    // setOpenSnackbars(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async (data: any) => {
    try {
      // Validate the submitted data using the schema
      // const validatedData = inputSchema.parse(data);

      const currentTime = new Date().toISOString();
      // PaymentSchema.parse(data);
      if (encryptedGrouptId) {
        const GroupId = decryptionFun(encryptedGrouptId, secreGrouptKey);
        // console.log(GroupId);

        const SingleGroupResponse = await GetSingleGroup(GroupId);
        const data = {
          teacherId: teacher,
          date_end: SingleGroupResponse.date_end,
          date_start: SingleGroupResponse.date_start,
        };
        const response = await EditGroupTeacher({ GroupId, data });
        // console.log(response);
        fetchData();
        // if (response.status === 201) {
        //   openNotification("topLeft");
        // }

        // const response = await GetSingleGroup(GroupId);
        // const teacherResponse = await GetSingleTeacherDataReq(
        //   response.teacherId
        // );
        // console.log("teacherresponse", response, teacherResponse);
        // setTeacher(teacherResponse.user.fullName);
        // setTeacherData([response]);
        // console.log("teacherId", teacherId);

        // const validDate = {
        //   amount_paid: data.payment_amount.replace(/,/g, ""),
        //   courseId: GroupId,
        //   date: currentTime,
        //   teacherId: teacherId,
        // };
        // console.log(validDate);
        // You can use the validated data here
        // console.log(validatedData);

        // Await the handlePayment function
        // const response = await createPay(validDate);

        // Show the success Snackbar
        // console.log("response.status", response);
        // console.log("response.data", response.data);

        // Close the modal
        handleClose();
        // Refresh the page after a successful payment
        // window.location.reload();
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  // console.log(teacher, teacherList);

  const contextValue = useMemo(() => ({ name: "Ant Design" }), []);

  return (
    <Context.Provider value={contextValue}>
      {contextHolder}
      <Button onClick={() => handleClick()} variant="contained" color="primary">
        <div className="font-mainfont">تغيير المدرس</div>
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box className={styleClasses}>
          <div className="flex justify-between items-center font-mainfont pb-6">
            <div>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
            <div className="font-mainfont">اضافة دفعة</div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="py-2 flex items-center justify-between">
              <div className="w-9/12 xsm:w-8/12">
                <FormControl fullWidth>
                  {/* <Select
                    value={teacher}
                    onChange={handleChange}
                    required
                    displayEmpty
                    name="teacher_name" // Add the name attribute
                    style={{
                      marginTop: "0px", // Adjust top margin to prevent label overlap
                      textAlign: "right", // Align the value to the right
                    }}
                    inputProps={{
                      style: {
                        textAlign: "right", // Align the value text to the right
                      },
                      "aria-label": "Without label", // Add aria-label attribute
                    }}>
                    {teacherList.map((teacher) => (
                      <MenuItem key={teacher.id} value={teacher.id}>
                        {teacher.teacher_name}
                      </MenuItem>
                    ))}
                  </Select> */}
                  <Autocomplete
                    id="teacher-autocomplete"
                    // value={teacher}
                    // defaultValue={teacher}
                    onChange={(event, newValue) => {
                      handleChange(newValue);
                      // console.log(newValue);
                    }}
                    options={teacherList}
                    getOptionLabel={(teacher: any) => teacher.teacher_name}
                    style={{
                      marginTop: "0px",
                      textAlign: "right",
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        dir="ltr"
                        // label={teacher ? teacher.teacher_name : ""} // Display teacher_name as the label
                        variant="outlined"
                      />
                    )}
                  />
                </FormControl>
              </div>
              <div className="font-mainfont">: اسم المدرس</div>
            </div>
            <div className="flex font-mainfont pt-6">
              <Button type="submit" variant="contained" color="primary">
                <div className="font-mainfont">تغيير</div>
              </Button>
            </div>
          </form>
        </Box>
      </Modal>
    </Context.Provider>
  );
};
