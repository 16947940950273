import { Box, Button, IconButton, Modal, TextField } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import { GetsingleSubjectData } from "../api/getSingleSubject";
import { z } from "zod";
import { ROLES, useAuthorization } from "../../../lib/authorization";
import { EditSubjectReq } from "../api/editSubject";
import { notification } from "antd";
import type { NotificationPlacement } from "antd/es/notification/interface";
import { DeleteSubjectReq } from "../api/deleteSubject";

const styleClasses =
  "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white shadow-lg p-4 md:w-6/12 sm:w-11/12 xsm:w-11/12";
export const inputSchema = z.object({
  subject_name: z.string(),
});
const Context = React.createContext({ name: "Default" });

export const EditSubject = ({
  id,
  fetchData,
}: {
  id: number;
  fetchData: any;
}) => {
  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  // const [discount, setDiscount] = useState<boolean>(false);
  const [singlesubjectEditData, setSinglesubjectEditData] = useState<any>();
  const { checkAccess } = useAuthorization();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm();
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement: NotificationPlacement) => {
    api.success({
      message: `تم تعديل الصف بنجاح`,
      // description: <Context.Consumer>{({ name }) => ``}</Context.Consumer>,
      placement,
    });
  };
  const fetchEditData = async () => {
    try {
      // console.log("id", id);

      const response = await GetsingleSubjectData(id);
      // console.log("response", response);

      setSinglesubjectEditData(response);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchEditData();
  }, []);
  // GetsingleSubjectData;
  const handleDeleteClick = () => {
    setDeleteOpen(true);
    // setValue("stage_name", singleClassEditData?.stage_name); // Reset input value when modal is opened
    clearErrors();
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
    // setValue("stage_name", "");
  };
  const onDeleteSubmit = async () => {
    const response = await DeleteSubjectReq(singlesubjectEditData.id);
    if (response.status === 200) {
      handleDeleteClose();
      // openDeleteNotification("topLeft");
      fetchData();
    }
    // console.log(response);
  };

  const handleEditClick = () => {
    setEditOpen(true);
    setValue("subject_name", singlesubjectEditData?.subject_name); // Reset input value when modal is opened
    clearErrors();
  };

  const handleEditClose = () => {
    setEditOpen(false);
    setValue("subject_name", "");
  };

  const onSubmitEdit = async (data: any) => {
    try {
      // Validate the submitted data using the schema
      const validatedData = inputSchema.parse(data);

      // You can use the validated data here
      // console.log(validatedData);
      const response = await EditSubjectReq(id, validatedData);
      // console.log("response");

      if (response) {
        // console.log("response", response);
        openNotification("topLeft");
      }

      fetchEditData();
      handleEditClose();
      fetchData();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const contextValue = useMemo(() => ({ name: "Ant Design" }), []);
  // console.log("singlesubjectEditData", singlesubjectEditData);

  return (
    <>
      <Context.Provider value={contextValue}>
        {contextHolder}
        {isLoading ? (
          <div className="p-4 pr-6">
            <div className="flex justify-end">
              <Button
                variant="contained"
                onClick={handleEditClick}
                color="primary">
                <div className="font-mainfont">تعديل</div>
              </Button>
            </div>
          </div>
        ) : (
          <>
            {checkAccess({
              allowedRoles: [ROLES.SUPERADMIN],
            }) ? (
              singlesubjectEditData?.course.length !== 0 ? (
                <div className="p-4 pr-6">
                  <div className="flex justify-end">
                    <Button
                      variant="contained"
                      onClick={handleEditClick}
                      color="primary">
                      <div className="font-mainfont">تعديل</div>
                    </Button>
                  </div>
                </div>
              ) : (
                <div className="p-4 pr-6">
                  <div className="flex justify-between">
                    <Button
                      onClick={handleDeleteClick}
                      variant="outlined"
                      color="error">
                      <div className="font-mainfont">حذف</div>
                    </Button>
                    <Button
                      onClick={handleEditClick}
                      variant="contained"
                      color="primary">
                      <div className="font-mainfont">تعديل</div>
                    </Button>
                  </div>
                </div>
              )
            ) : (
              <div className="p-4 pr-6">
                <div className="flex justify-end">
                  <Button
                    onClick={handleEditClick}
                    variant="contained"
                    color="primary">
                    <div className="font-mainfont">تعديل</div>
                  </Button>
                </div>
              </div>
            )}
          </>
        )}

        <Modal
          open={deleteOpen}
          onClose={handleDeleteClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box className={styleClasses}>
            <div className="flex justify-between items-center font-mainfont pb-6">
              <div>
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={deleteOpen ? "long-menu" : undefined}
                  aria-expanded={deleteOpen ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleDeleteClose}>
                  <CloseIcon />
                </IconButton>
              </div>
              <div className="font-mainfont">حذف المادة</div>
            </div>
            <div className="flex justify-end pb-6">
              <div>هل انت متاكد من حذف</div>
            </div>

            <div className="flex font-mainfont pt-6">
              <Button
                onClick={onDeleteSubmit}
                variant="contained"
                color="primary">
                <div className="font-mainfont">نعم</div>
              </Button>
            </div>
          </Box>
        </Modal>
        <Modal
          open={editOpen}
          onClose={handleEditClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box className={styleClasses}>
            <div className="flex justify-between items-center font-mainfont pb-2">
              <div>
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={editOpen ? "long-menu" : undefined}
                  aria-expanded={editOpen ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleEditClose}>
                  <CloseIcon />
                </IconButton>
              </div>
              <div className="font-mainfont">تعديل المادة</div>
            </div>
            <form onSubmit={handleSubmit(onSubmitEdit)}>
              <div className="py-2 flex items-center justify-between">
                <div className="w-9/12 xsm:w-8/12">
                  <TextField
                    fullWidth
                    id="subject_name"
                    placeholder="ادخل اسم المادة"
                    {...register("subject_name", { required: true })}
                    error={!!errors.subject_name}
                    helperText={
                      errors.subject_name ? (
                        <div className="text-right">
                          الرجاء ادخال اسم المادة
                        </div>
                      ) : (
                        ""
                      )
                    }
                    InputLabelProps={{
                      style: {
                        textAlign: "right", // Align the label to the right
                      },
                    }}
                    inputProps={{
                      style: {
                        textAlign: "right", // Align the input text to the right
                        direction: "rtl", // Set text direction for the input
                      },
                    }}
                    style={{
                      direction: "rtl", // Set text direction for the entire TextField
                      textAlign: "right", // Align the entire TextField to the right
                    }}
                  />
                </div>
                <div className="font-mainfont">: اسم المادة</div>
              </div>
              <div className="flex font-mainfont py-4">
                <Button type="submit" variant="contained" color="primary">
                  <div className="font-mainfont">اضافة</div>
                </Button>
              </div>
            </form>
          </Box>
        </Modal>
      </Context.Provider>
    </>
  );
};

// done types
