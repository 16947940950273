import { any } from "zod";
import { axios } from "../../../lib/axios";

export const EditSingleAdmin = async (id: any, data: any) => {
  const response = await axios.patch(`/user/${id}`, data);
  // console.log(response);

  return response;
};

// done types
