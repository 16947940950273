import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Box, Button, IconButton, Modal, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { EditSingleStudnetData } from "../api/editStudent";
import { notification } from "antd";
import type { NotificationPlacement } from "antd/es/notification/interface";

const inputSchema = z.object({
  student_name: z.string(),
  phone: z.string().regex(/^[0-9]*$/, "Invalid number"),
  address: z.string(),
  parent_phone: z.string().regex(/^[0-9]*$/, "Invalid number"),
});

const styleClasses =
  "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white shadow-lg p-4 md:w-6/12 sm:w-11/12 xsm:w-11/12";

const Context = React.createContext({ name: "Default" });

export const EditStudent = ({
  StudentProfileData,
  fetchSingleStudentMetaData,
}: {
  StudentProfileData: any;
  fetchSingleStudentMetaData: any;
}) => {
  const [open, setOpen] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [error, setError] = useState(false);
  const openNotification = (placement: NotificationPlacement) => {
    api.success({
      message: `تم تعديل معلومات الطالب بنجاح`,
      // description: <Context.Consumer>{({ name }) => ``}</Context.Consumer>,
      placement,
    });
  };
  const openErrorNotification = (placement: NotificationPlacement) => {
    api.error({
      message: `هذه المعلومات موجودة بالفعل عند مستخدم اخر`,
      // description: <Context.Consumer>{({ name }) => ``}</Context.Consumer>,
      placement,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm();

  const handleOpen = () => {
    setValue("student_name", StudentProfileData?.studentName);
    setValue("phone", StudentProfileData?.studentPhone);
    setValue("address", StudentProfileData?.studentAddress);
    setValue("parent_phone", StudentProfileData?.studentParentPhone);
    clearErrors();
    setOpen(true);
    setError(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async (data: any) => {
    try {
      const validatedData = inputSchema.parse(data);
      const response = await EditSingleStudnetData(
        StudentProfileData.studentId,
        validatedData
      );
      // console.log(response);
      if (response.status === 200) {
        openNotification("topLeft");
        fetchSingleStudentMetaData();
        handleClose();
      }
    } catch (error) {
      // openErrorNotification("topLeft");
      setError(true);
    }
  };
  const contextValue = useMemo(() => ({ name: "Ant Design" }), []);

  return (
    <>
      <Context.Provider value={contextValue}>
        {contextHolder}
        <div className="flex font-mainfont py-6">
          <Button
            onClick={handleOpen}
            type="submit"
            variant="contained"
            color="primary">
            <div className="font-mainfont">تعديل</div>
          </Button>
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box className={styleClasses}>
            <div className="flex justify-between items-center font-mainfont pb-2">
              <div>
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={open ? "long-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </div>
              <div className="font-mainfont">تعديل معلومات الطالب</div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="py-2 flex items-center justify-between">
                <div className="w-9/12 xsm:w-8/12">
                  <TextField
                    fullWidth
                    id="student_name"
                    placeholder="ادخل اسم الطالب"
                    {...register("student_name", { required: true })}
                    error={!!errors.student_name}
                    helperText={
                      errors.student_name ? (
                        <div className="text-right">
                          الرجاء ادخال اسم الطالب
                        </div>
                      ) : (
                        ""
                      )
                    }
                    InputLabelProps={{
                      style: {
                        textAlign: "right", // Align the label to the right
                      },
                    }}
                    inputProps={{
                      style: {
                        textAlign: "right", // Align the input text to the right
                        direction: "rtl", // Set text direction for the input
                      },
                    }}
                    style={{
                      direction: "rtl", // Set text direction for the entire TextField
                      textAlign: "right", // Align the entire TextField to the right
                    }}
                  />
                </div>
                <div className="font-mainfont">: اسم الطالب</div>
              </div>
              <div className="py-2 flex items-center justify-between">
                <div className="w-9/12 xsm:w-8/12">
                  <TextField
                    fullWidth
                    id="phone"
                    placeholder="ادخل رقم الهاتف"
                    {...register("phone", {
                      required: true,
                      pattern: /^[0-9]*$/, // Use pattern to enforce numeric input
                    })}
                    error={!!errors.phone}
                    helperText={
                      errors.phone ? (
                        <div className="text-right">
                          {errors.phone.type === "pattern"
                            ? "الرجاء ادخال رقم هاتف صحيح"
                            : "الرجاء ادخال رقم الهاتف"}
                        </div>
                      ) : (
                        ""
                      )
                    }
                    InputLabelProps={{
                      style: {
                        textAlign: "right", // Align the label to the right
                      },
                    }}
                    inputProps={{
                      style: {
                        textAlign: "right", // Align the input text to the right
                        direction: "rtl", // Set text direction for the input
                      },
                    }}
                    style={{
                      direction: "rtl", // Set text direction for the entire TextField
                      textAlign: "right", // Align the entire TextField to the right
                    }}
                  />
                </div>
                <div className="font-mainfont">: رقم الهاتف</div>
              </div>
              <div className="py-2 flex items-center justify-between">
                <div className="w-9/12 xsm:w-8/12">
                  <TextField
                    fullWidth
                    id="address"
                    placeholder="ادخل عنوان السكن"
                    {...register("address", { required: true })}
                    error={!!errors.address}
                    helperText={
                      errors.address ? (
                        <div className="text-right">
                          الرجاء ادخال عنوان السكن
                        </div>
                      ) : (
                        ""
                      )
                    }
                    InputLabelProps={{
                      style: {
                        textAlign: "right", // Align the label to the right
                      },
                    }}
                    inputProps={{
                      style: {
                        textAlign: "right", // Align the input text to the right
                        direction: "rtl", // Set text direction for the input
                      },
                    }}
                    style={{
                      direction: "rtl", // Set text direction for the entire TextField
                      textAlign: "right", // Align the entire TextField to the right
                    }}
                  />
                </div>
                <div className="font-mainfont">: عنوان السكن</div>
              </div>
              <div className="py-2 flex items-center justify-between">
                <div className="w-9/12 xsm:w-8/12">
                  <TextField
                    fullWidth
                    id="parent_phone"
                    placeholder="ادخل رقم الهاتف"
                    {...register("parent_phone", {
                      required: true,
                      pattern: /^[0-9]*$/, // Use pattern to enforce numeric input
                    })}
                    error={!!errors.parent_phone}
                    helperText={
                      errors.parent_phone ? (
                        <div className="text-right">
                          {errors.parent_phone.type === "pattern"
                            ? "الرجاء ادخال رقم هاتف صحيح"
                            : "الرجاء ادخال رقم الهاتف"}
                        </div>
                      ) : (
                        ""
                      )
                    }
                    InputLabelProps={{
                      style: {
                        textAlign: "right", // Align the label to the right
                      },
                    }}
                    inputProps={{
                      style: {
                        textAlign: "right", // Align the input text to the right
                        direction: "rtl", // Set text direction for the input
                      },
                    }}
                    style={{
                      direction: "rtl", // Set text direction for the entire TextField
                      textAlign: "right", // Align the entire TextField to the right
                    }}
                  />
                </div>
                <div className="font-mainfont">: رقم هاتف الوالدين</div>
              </div>
              {error ? (
                <div className="flex justify-center">
                  <div className="text-right text-s text-red-600 pt-1 pb-2">
                    هذه المعلومات موجودة بالفعل عند مستخدم اخر
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="flex font-mainfont py-4">
                <Button type="submit" variant="contained" color="primary">
                  <div className="font-mainfont">اضافة</div>
                </Button>
              </div>
            </form>
          </Box>
        </Modal>
      </Context.Provider>
    </>
  );
};
