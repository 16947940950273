import { axios } from "../../../lib/axios";

export const TeacherArchiveDelete = async (id: any, enteredTeacherId: any) => {
  const requestBody = {
    entity: "teacher",
    id: enteredTeacherId,
  };
  const response = await axios.delete(`/delete-archived/${id}`, {
    data: requestBody,
  });
  return response.data;
};
