import { Breadcrumbs } from "@mui/material";

import { useNavigate, useParams } from "react-router-dom";
import { decryptionFun } from "../../../lib/secretUrl";
import { useEffect, useState } from "react";
import { Waveform } from "@uiball/loaders";
import { GetsingleClassDataReq } from "../api/getSingleClass";
import { GetsingleSubjectData } from "../api/getSingleSubject";
import { EditSingleSubject } from "./editSingleSubject";
import { SingleSubjectCard } from "../types";
import { SingleClassMetaDataType } from "../../singleClass/types";
import { ROLES, useAuthorization } from "../../../lib/authorization";
import { useAuth } from "../../../lib/auth";
export const SingleSubject = () => {
  const { encryptedClassId, encryptedSubjectId } = useParams();
  const secretSubjectKey = process.env
    .REACT_APP_SUBJECT_ENCRYPTION_KEY as string;
  const secretClassKey = process.env.REACT_APP_CLASS_ENCRYPTION_KEY as string;
  const { checkAccess } = useAuthorization();
  const [id, setId] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [subjectData, setSubjectData] = useState<SingleSubjectCard>();
  const [classData, setClassData] = useState<SingleClassMetaDataType>();
  const { user } = useAuth();
  // const [isLoading, setIsLoading] = useState(true);
  const fetchSubjectData = async () => {
    setIsLoading(true);
    if (encryptedSubjectId) {
      // console.log(encryptedSubjectId, secretSubjectKey);
      // setIsLoading(true);

      const subjectId = decryptionFun(encryptedSubjectId, secretSubjectKey);
      // console.log(subjectId);
      setId(subjectId);
      try {
        const response = await GetsingleSubjectData(subjectId);
        // console.log(response);

        setSubjectData(response);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching class data:", error);
      }
    }
  };
  const fetchSingleClasstDataFun = async () => {
    if (encryptedClassId) {
      const ClassId = decryptionFun(encryptedClassId, secretClassKey);
      try {
        const response = await GetsingleClassDataReq({
          id: ClassId,
          user: user,
        });
        // console.log("response", response);

        setClassData(response);
      } catch (error) {
        console.error("Error fetching class data:", error);
      }
    }
  };
  useEffect(() => {
    fetchSubjectData();
    fetchSingleClasstDataFun();
    // console.log(ClassId);
  }, []);

  // console.log(subjectMetaDataStats);

  // console.log("subjectData", classData);
  // console.log("subjectData", subjectData);
  const navigate = useNavigate();

  return (
    <div>
      {isLoading ? (
        <div className="flex justify-center items-center p-16">
          <Waveform size={40} speed={2} color="black" />
        </div>
      ) : (
        <>
          <div className="pl-5 flex justify-between">
            <Breadcrumbs aria-label="breadcrumb">
              <div
                className="font-mainfont sm:text-sm xsm:text-xs md:text-md hover:underline cursor-pointer"
                onClick={() => navigate(`/dashboard`)}>
                الصفوف
              </div>
              <div
                className="font-mainfont sm:text-sm xsm:text-xs md:text-md hover:underline cursor-pointer"
                onClick={() => navigate(`/dashboard/${encryptedClassId}`)}>
                {classData?.stage_name}
              </div>
              <div className="font-mainfont sm:text-sm xsm:text-xs md:text-md hover:underline cursor-pointer">
                {subjectData?.subject_name}
              </div>
            </Breadcrumbs>
          </div>
          <div className="flex flex-col px-5">
            {checkAccess({
              allowedRoles: [ROLES.ADMIN, ROLES.SUPERADMIN],
            }) ? (
              <div className="flex justify-between items-center">
                <EditSingleSubject
                  id={id}
                  fetchSubjectData={fetchSubjectData}
                />
                <div className="font-mainfont text-3xl">
                  {subjectData?.subject_name}
                </div>
              </div>
            ) : (
              <div className="flex justify-end p-5 items-center">
                <div className="font-mainfont text-3xl">
                  {subjectData?.subject_name}
                </div>
              </div>
            )}
            <div className="border-b-2 border-mainLine"></div>
          </div>
          {checkAccess({
            allowedRoles: [ROLES.ADMIN, ROLES.SUPERADMIN],
          }) ? (
            <div className="flex flex-wrap justify-end p-3">
              {/* {subjectMetaDataStats.map((card: any, index: number) => (
                <MetaDataCard
                  key={index}
                  index={index}
                  title={card.title}
                  data={card.data}
                />
              ))} */}
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
};

// done types
