import React, { useMemo, useState } from "react";
import { z } from "zod";
import { Box, Button, IconButton, Modal, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { notification } from "antd";
import type { NotificationPlacement } from "antd/es/notification/interface";
import { DeleteStudentReq } from "../api/deleteStudent";
// import { DeleteTeacherReq } from "../api/deleteTeacher";

export const inputSchema = z.object({
  teacherName: z.string(),
  phone: z.string(),
  address: z.string(),
  userName: z.string(),
  // password: z.string(),
});

const styleClasses =
  "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white shadow-lg p-4 md:w-6/12 sm:w-11/12 xsm:w-11/12";
const Context = React.createContext({ name: "Default" });

export const DeleteStudent = ({
  StudentProfileData,
  fetchSingleStudentMetaData,
}: {
  StudentProfileData: any;
  fetchSingleStudentMetaData: any;
}) => {
  const [open, setOpen] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement: NotificationPlacement) => {
    api.success({
      message: `تم الحذف بنجاح`,
      // description: <Context.Consumer>{({ name }) => ``}</Context.Consumer>,
      placement,
    });
  };
  const openErrorNotification = (placement: NotificationPlacement) => {
    api.error({
      message: `حدث خطأ اثناء الحذف`,
      // description: <Context.Consumer>{({ name }) => ``}</Context.Consumer>,
      placement,
    });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async () => {
    try {
      console.log("StudentProfileData", StudentProfileData);
      //   const validatedData = inputSchema.parse(data);
      // console.log(validatedData);
      const response = await DeleteStudentReq({
        id: StudentProfileData.studentId,
      });
      // console.log(response);
      if (response.status === 200) {
        openNotification("topLeft");
        fetchSingleStudentMetaData();
        handleClose();
        window.location.href = "/students/";
      }
    } catch (error) {
      openErrorNotification("topLeft");
    }
  };

  const contextValue = useMemo(() => ({ name: "Ant Design" }), []);
  // console.log(TeacherProfileData);

  return (
    <>
      <Context.Provider value={contextValue}>
        <div className="flex font-mainfont py-6">
          <Button
            onClick={handleOpen}
            type="submit"
            variant="contained"
            color="error">
            <div className="font-mainfont">حذف</div>
          </Button>
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box className={styleClasses}>
            <div className="flex justify-between items-center font-mainfont pb-2">
              <div>
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={open ? "long-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </div>
              <div className="font-mainfont">
                هل تريد بالتاكيد حذف هذا الطالب ؟
              </div>
            </div>
            <div className="flex font-mainfont py-4">
              <Button
                type="submit"
                variant="contained"
                color="error"
                onClick={() => onSubmit()}>
                <div className="font-mainfont">تاكيد الحذف</div>
              </Button>
            </div>
          </Box>
        </Modal>{" "}
        {contextHolder}
      </Context.Provider>
    </>
  );
};
