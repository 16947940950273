import { useEffect, useState } from "react";
import { Card } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { encryptionFun } from "../../../lib/secretUrl";
import { EditGroup } from "./editGroup";
import dayjs from "dayjs";
import { ROLES, useAuthorization } from "../../../lib/authorization";
import { GetGroupsSingleTeacher } from "../api/getGroupsSingleTeacher";

export const GroupCard = ({
  id,
  title,
  price,
  date_start,
  date_end,
  teacherId,
  fetchData,
}: any) => {
  const { checkAccess } = useAuthorization();

  const { encryptedClassId, encryptedSubjectId } = useParams();
  const secretKey = process.env.REACT_APP_GROUP_ENCRYPTION_KEY as string;
  const navigate = useNavigate();
  const [teacher, setTeacher] = useState<any>();
  // console.log("fetchDat", fetchData);

  const fetchDataReq = async () => {
    const response = await GetGroupsSingleTeacher(teacherId);
    // console.log("response", response.user.userName);
    setTeacher(response.user.fullName);
  };
  useEffect(() => {
    fetchDataReq();
  }, []);
  const handleClass = (id: number) => {
    // console.log(id);

    const enctyptedGrouptId = encryptionFun(id, secretKey);
    // console.log(
    //   `/dashboard/${encryptedClassId}/${encryptedSubjectId}/${enctyptedGrouptId}`
    // );
    navigate(
      `/dashboard/${encryptedClassId}/${encryptedSubjectId}/${enctyptedGrouptId}`
    );
  };
  function formatNumberWithCommas(number: number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return (
    <Card className="bg-white rounded-md ">
      <div className="flex flex-col justify-between">
        <div className="cursor-pointer" onClick={() => handleClass(id)}>
          <div className="mb-1 text-end font-bold p-3 pr-6 text-xl">
            {title}
          </div>
          <div className="border-b-2 border-cardLine w-full"></div>
          <div className="flex flex-col items-end p-6">
            <div className="flex">
              {dayjs(date_start).format("DD-MM-YYYY")}
              <div className="font-semibold pl-1"> : تاريخ البدء</div>
            </div>
            <div className="flex">
              {dayjs(date_end).format("DD-MM-YYYY")}
              <div className="font-semibold pl-1"> : تاريخ الانتهاء</div>
            </div>
            <div className="flex">
              {teacher}
              <div className="font-semibold pl-1"> : الاستاذ</div>
            </div>
            <div className="flex">
              {/* {price} */}
              {formatNumberWithCommas(price)}
              <div className="font-semibold pl-1"> : السعر</div>
            </div>
          </div>

          <div className="border-b-2 border-cardLine w-full p-3"></div>
        </div>
        {checkAccess({
          allowedRoles: [ROLES.ADMIN, ROLES.SUPERADMIN],
        }) ? (
          <EditGroup GroupId={id} teacherId={teacherId} fetchData={fetchData} />
        ) : (
          ""
        )}
      </div>
    </Card>
  );
};
