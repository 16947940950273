import { ResponseInterceptor } from "@/features/teachers/api/getTeacherData";
import { axios } from "../../../lib/axios";
import { User } from "../types";

export const GetAdmin = async (): Promise<User[]> => {
  const response = await axios.get(`/user/role/admin/2`);
  return response.data;
};

// done types
