import { axios } from "../../../lib/axios";

export const deleteClass = async (id: any) => {
  // console.log("name.class_name", stage_name.stage_name);
  // console.log("dataReq", dataReq.stage_name);

  const response = await axios.delete(`/stages/${id}`);
  // console.log(response);

  return response;
};

// done types
