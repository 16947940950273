import { Box, Button, IconButton, Modal, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import { z } from "zod";
import { GetTeacherDataReq } from "../../teachers/api/getTeacherData";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import { GetCurrentTeacher } from "../api/getCurrentTeacher";
import { EditGroupReq } from "../api/editGroup";
import { NumericFormatCustom } from "../../../lib/numberFormat";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const styleClasses =
  "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white shadow-lg p-4 md:w-6/12 sm:w-11/12 xsm:w-11/12";
export const EditGroup = ({ GroupId, fetchData, teacherId }: any) => {
  const [editOpen, setEditOpen] = useState(false);
  const [editData, setEditData] = useState<any>();
  const [teacherLoading, setTeacherLoading] = useState(true);
  const [isLoading, setIsLoaging] = useState(true);
  const [teacherList, setTeacherList] = useState<
    { id: number; teacher_name: string }[]
  >([]);
  // console.log("fetchData", fetchData);
  const [startDateValue, setStartDateValue] = useState<Dayjs | null>();
  const [endDateValue, setEndDateValue] = useState<Dayjs | null>();
  const [startDateError, setStartDateError] = useState<string | null>(null);
  const [endDateError, setEndDateError] = useState<string | null>(null);
  const [currentTeacher, setCurrentTeacher] = useState<any>();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm();

  const inputEditSchema = z.object({
    groupName: z.string(),
  });

  const fetchTeacherList = async () => {
    try {
      const response = await GetTeacherDataReq();
      // console.log("response", response);

      const simplifiedTeacherList = response.map(
        (teacher: { id: any; user: { fullName: any } }) => ({
          id: teacher.id,
          teacher_name: teacher.user.fullName,
        })
      );

      setTeacherList(simplifiedTeacherList);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchEditData = async () => {
    try {
      const teacherResponse = await GetCurrentTeacher({ GroupId, teacherId });
      // console.log("teacherResponse", teacherResponse);

      setCurrentTeacher({
        name: teacherResponse.userFullName,
        id: teacherResponse.teacherId,
      });
      // console.log("teacherResponse", teacherResponse);

      setStartDateValue(dayjs(teacherResponse.courseDateStart));
      setEndDateValue(dayjs(teacherResponse.courseDateEnd));
      setEditData(teacherResponse);
      setIsLoaging(false);
      setTeacherLoading(false);

      // setSingleClassEditData(response);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchTeacherList();
    fetchEditData();
  }, []);

  const handleEditClick = () => {
    setEditOpen(true);
    setValue("groupName", editData.courseName);
    setValue("price", editData.courseFee);
    setStartDateValue(dayjs(editData.courseDateStart));
    setEndDateValue(dayjs(editData.courseDateEnd));
    // setValue("teacher_percentage", editData.teacher_percentage);
    // setTeacher(editData?.teacher);
    clearErrors();
  };

  const handleEditClose = () => {
    setEditOpen(false);
    setValue("groupName", "");
    setStartDateError(null);
    setEndDateError(null);
  };
  // console.log(editData);

  const onEditSubmit = async (data: any) => {
    try {
      const parsedStartDate = dayjs(startDateValue);
      const parsedEndDate = dayjs(endDateValue);

      let startDateError = null;
      let endDateError = null;

      if (
        !parsedStartDate.isValid() ||
        parsedStartDate.isBefore("2000-01-01")
      ) {
        startDateError = "Please fill in the start date.";
      }

      if (!parsedEndDate.isValid() || parsedEndDate.isBefore("2000-01-01")) {
        endDateError = "Please fill in the end date.";
      }

      // Set the error messages
      setStartDateError(startDateError);
      setEndDateError(endDateError);

      // Check if either of the error messages is not null
      if (startDateError || endDateError) {
        return; // Prevent form submission if there are errors
      }

      const acceptedStartDate = parsedStartDate.format(
        "YYYY-MM-DDTHH:mm:ss.SSSZ"
      );
      const acceptedEndDate = parsedEndDate.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
      // Create the form data with groupName and teacher_name
      const formData = {
        name: data.groupName,
        fee: data.price,
        date_start: acceptedStartDate,
        date_end: acceptedEndDate,
        teacherId: currentTeacher.id,
      };

      // console.log(formData);

      if (GroupId) {
        const response = await EditGroupReq(GroupId, formData);
        console.log("response", response);
      }

      // Close the modal
      fetchData();
      handleEditClose();
      fetchEditData();
    } catch (error) {
      console.error("Error submitting the form:", error);
    }
  };
  const handleTeacherChange = (event: SelectChangeEvent) => {
    // console.log(event.target.value);

    setCurrentTeacher({
      id: event.target.value as string,
    });
  };
  // console.log(editData);

  return (
    <>
      {isLoading ? (
        <div className="p-4 pr-6 flex justify-end">
          {/* <EditGroupDiscount /> */}
          <Button variant="contained" color="primary">
            <div className="font-mainfont">تعديل</div>
          </Button>
        </div>
      ) : (
        <>
          <div className="p-4 pr-6 flex justify-end">
            {/* <EditGroupDiscount /> */}
            <Button
              onClick={handleEditClick}
              variant="contained"
              color="primary">
              <div className="font-mainfont">تعديل</div>
            </Button>
          </div>
          <Modal
            open={editOpen}
            onClose={handleEditClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box className={styleClasses}>
              <div className="flex justify-between items-center font-mainfont pb-6">
                <div>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={editOpen ? "long-menu" : undefined}
                    aria-expanded={editOpen ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleEditClose}>
                    <CloseIcon />
                  </IconButton>
                </div>
                <div className="font-mainfont">تعديل</div>
              </div>
              <form onSubmit={handleSubmit(onEditSubmit)}>
                <div className="py-2 flex items-center justify-between">
                  <div className="w-9/12 xsm:w-8/12">
                    <TextField
                      fullWidth
                      id="groupName"
                      placeholder="ادخل اسم الكروب"
                      {...register("groupName", { required: true })}
                      error={!!errors.groupName}
                      InputLabelProps={{
                        style: {
                          textAlign: "right", // Align the label to the right
                        },
                      }}
                      inputProps={{
                        style: {
                          textAlign: "right", // Align the input text to the right
                          direction: "rtl", // Set text direction for the input
                        },
                      }}
                      style={{
                        direction: "rtl", // Set text direction for the entire TextField
                        textAlign: "right", // Align the entire TextField to the right
                      }}
                    />
                    {errors.groupName && (
                      <div className="text-right text-xs text-red-600 pt-1">
                        الرجاء ادخال اسم الكورس
                      </div>
                    )}
                  </div>
                  <div className="font-mainfont">: اسم الكروب</div>
                </div>
                <div className="py-2 flex items-center justify-between">
                  <div className="w-9/12 xsm:w-8/12">
                    {teacherLoading ? (
                      ""
                    ) : (
                      <FormControl fullWidth>
                        <Select
                          value={currentTeacher.id}
                          onChange={handleTeacherChange}
                          required
                          // defaultValue={currentTeacher.name}
                          name="teacher_name"
                          style={{
                            marginTop: "0px",
                            textAlign: "right",
                          }}
                          inputProps={{
                            style: {
                              textAlign: "right",
                            },
                            "aria-label": "Without label",
                          }}>
                          {teacherList.map((teacher) => (
                            <MenuItem key={teacher.id} value={teacher.id}>
                              {teacher.teacher_name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </div>
                  <div className="font-mainfont">: اسم المدرس</div>
                </div>
                <div className="py-2 flex items-center justify-between">
                  <div className="w-9/12 xsm:w-8/12">
                    <TextField
                      fullWidth
                      id="price"
                      defaultValue={editData.courseFee}
                      placeholder="ادخل سعر الكورس"
                      {...register("price", { required: true })}
                      error={!!errors.price}
                      InputLabelProps={{
                        style: {
                          textAlign: "right", // Align the label to the right
                        },
                      }}
                      InputProps={{
                        inputComponent: NumericFormatCustom as any,
                      }}
                      style={{
                        direction: "rtl", // Set text direction for the entire TextField
                        textAlign: "right", // Align the entire TextField to the right
                      }}
                    />{" "}
                    {errors.price && (
                      <div className="text-right text-xs text-red-600 pt-1">
                        الرجاء ادخال سعر الكورس
                      </div>
                    )}
                  </div>
                  <div className="font-mainfont">: سعر الكورس</div>
                </div>
                <div className="py-2 flex items-center justify-between">
                  <div className="w-9/12 xsm:w-8/12">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker", "DatePicker"]}>
                        <DatePicker
                          className="w-full"
                          value={startDateValue}
                          onChange={(newValue) => setStartDateValue(newValue)}
                          format="DD-MM-YYYY"
                          minDate={dayjs("2000-01-01")}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                    {startDateError && (
                      <div className="text-right text-xs text-red-600 pt-1">
                        الرجاء ادخال تاريخ البدء
                      </div>
                    )}
                  </div>
                  <div className="font-mainfont">: تاريخ البدء</div>
                </div>
                <div className="py-2 flex items-center justify-between">
                  <div className="w-9/12 xsm:w-8/12">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker", "DatePicker"]}>
                        <DatePicker
                          className="w-full"
                          value={endDateValue}
                          onChange={(newValue) => setEndDateValue(newValue)}
                          format="DD-MM-YYYY"
                          minDate={dayjs("2000-01-01")}
                        />
                      </DemoContainer>
                    </LocalizationProvider>{" "}
                    {endDateError && (
                      <div className="text-right text-xs text-red-600 pt-1">
                        الرجاء ادخال تاريخ الانتهاء
                      </div>
                    )}
                  </div>
                  <div className="font-mainfont">: تاريخ الانتهاء</div>
                </div>

                <div className="flex font-mainfont pt-6">
                  <Button type="submit" variant="contained" color="primary">
                    <div className="font-mainfont">اضافة</div>
                  </Button>
                </div>
              </form>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
};
