import { axios } from "../../../lib/axios";

export const DeleteSingleAdmin = async (id: any) => {
  const response = await axios.delete(`/user/${id}`);
  // console.log(response);

  return response;
};

// done types
