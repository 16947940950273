import { useForm } from "react-hook-form";
import React, { useEffect, useMemo, useState } from "react";
import { Box, Button, IconButton, Modal, TextField } from "@mui/material";
import { z } from "zod";
import CloseIcon from "@mui/icons-material/Close";

import { useParams } from "react-router-dom";
import { decryptionFun } from "../../../lib/secretUrl";
import { GetsingleClassData } from "../api/getSingleClassData";
import { ROLES, useAuthorization } from "../../../lib/authorization";
import { SingleClassMetaDataType } from "../types";
import { EditClassReq } from "../../class/api/editClass";

import { notification } from "antd";
import type { NotificationPlacement } from "antd/es/notification/interface";

const inputSchema = z.object({
  stage_name: z.string(),
});
const Context = React.createContext({ name: "Default" });

const styleClasses =
  "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white shadow-lg p-4 md:w-6/12 sm:w-11/12 xsm:w-11/12";

export const EditSingleClass = (fetchMetaData: any) => {
  const { checkAccess } = useAuthorization();
  const { encryptedClassId } = useParams();
  const secretKey = process.env.REACT_APP_CLASS_ENCRYPTION_KEY as string;
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement: NotificationPlacement) => {
    api.success({
      message: `تم تعديل الصف بنجاح`,
      // description: <Context.Consumer>{({ name }) => ``}</Context.Consumer>,
      placement,
    });
  };
  const [open, setOpen] = useState<boolean>(false);
  const [singleClassEditData, setSingleClassEditData] =
    useState<SingleClassMetaDataType>();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm();

  // console.log(id);
  const fetchEditData = async () => {
    if (encryptedClassId) {
      const id = decryptionFun(encryptedClassId, secretKey);
      if (id) {
        try {
          const response = await GetsingleClassData(id);

          setSingleClassEditData(response);
        } catch (error) {
          console.error("Error fetching class data:", error);
        }
      }
    }
  };
  useEffect(() => {
    fetchEditData();
  }, [encryptedClassId, secretKey]);
  const handleEditClick = () => {
    setOpen(true);
    setValue("stage_name", singleClassEditData?.["stage_name"]); // Reset input value when modal is opened
    clearErrors();
  };

  const handleClose = () => {
    setOpen(false);
    setValue("stage_name", "");
  };
  const SubmitEditData = async (validatedData: any) => {
    if (encryptedClassId) {
      const id = decryptionFun(encryptedClassId, secretKey);
      if (id) {
        try {
          // console.log(validatedData);
          // const data = {
          //   class_name: validatedData.class_name,
          // };
          const response = await EditClassReq(id, validatedData.stage_name);
          // console.log(response);
          if (response.status === 200) {
            openNotification("topLeft");
          }
          fetchEditData();
          //  setSingleClassEditData(response);
        } catch (error) {
          console.error("Error fetching class data:", error);
        }
      }
    }
  };
  const onSubmit = async (data: any) => {
    try {
      // Validate the submitted data using the schema
      const validatedData = inputSchema.parse(data);
      const response = SubmitEditData(validatedData);
      // You can use the validated data here
      // console.log(validatedData);
      // openNotification("topLeft");
      // Close the modal
      handleClose();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  // console.log(singleClassEditData?.["stage_name"]);
  const contextValue = useMemo(() => ({ name: "Ant Design" }), []);

  return (
    <Context.Provider value={contextValue}>
      {contextHolder}
      <div className="flex flex-col px-5">
        {checkAccess({ allowedRoles: [ROLES.ADMIN, ROLES.SUPERADMIN] }) && (
          <>
            <div className="flex justify-between items-center">
              <div className="p-4 pr-6 flex justify-between">
                <Button
                  onClick={handleEditClick}
                  variant="contained"
                  color="primary">
                  <div className="font-mainfont">تعديل</div>
                </Button>
              </div>
              <div className="font-mainfont text-3xl">
                {singleClassEditData?.["stage_name"]}
              </div>
            </div>
            <div className="border-b-2 border-mainLine"></div>
          </>
        )}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box className={styleClasses}>
            <div className="flex justify-between items-center font-mainfont pb-6">
              <div>
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={open ? "long-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </div>
              <div className="font-mainfont">تعديل معلومات الصف</div>
            </div>
            {/* <div className="flex justify-end pb-6">
            <div>اسم الصف الحالي : {singleClassEditData?.["title"]}</div>
          </div> */}
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="py-2 flex items-center justify-between">
                <div className="w-9/12 xsm:w-8/12">
                  <TextField
                    fullWidth
                    id="stage_name"
                    placeholder="ادخل اسم الصف"
                    {...register("stage_name", { required: true })}
                    error={!!errors.stage_name}
                    helperText={
                      errors.stage_name ? (
                        <div className="text-right">
                          الرجاء ادخال المبلغ بشكل صحيح
                        </div>
                      ) : (
                        ""
                      )
                    }
                    InputLabelProps={{
                      style: {
                        textAlign: "right", // Align the label to the right
                      },
                    }}
                    inputProps={{
                      style: {
                        textAlign: "right", // Align the input text to the right
                        direction: "rtl", // Set text direction for the input
                      },
                    }}
                    style={{
                      direction: "rtl", // Set text direction for the entire TextField
                      textAlign: "right", // Align the entire TextField to the right
                    }}
                  />
                </div>
                <div className="font-mainfont"> : اسم المادة</div>
              </div>
              <div className="flex font-mainfont py-6">
                <Button type="submit" variant="contained" color="primary">
                  <div className="font-mainfont">تعديل</div>
                </Button>
              </div>
            </form>
          </Box>
        </Modal>
      </div>
    </Context.Provider>
  );
};

// done types
