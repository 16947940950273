import { Box, Button, IconButton, Modal, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import FormControl from "@mui/material/FormControl";
import { GetTeacherDataReq } from "../../teachers/api/getTeacherData";
import { z } from "zod";
import { ROLES, useAuthorization } from "../../../lib/authorization";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import Autocomplete from "@mui/material/Autocomplete";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { NumericFormatCustom } from "../../../lib/numberFormat";
import dayjs, { Dayjs } from "dayjs";
const styleClasses =
  "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white shadow-lg p-4 md:w-6/12 sm:w-11/12 xsm:w-11/12";

export const CreateGroup = ({ onCreateGroup }: any) => {
  const { checkAccess } = useAuthorization();

  const [open, setOpen] = useState<boolean>(false);
  const [teacher, setTeacher] = useState<any>("");
  const [teacherList, setTeacherList] = useState<
    { id: number; teacher_name: string }[]
  >([]);
  // const [value, onChange] = useState<Value>(new Date());

  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [startDateError, setStartDateError] = useState<string | null>(null);
  const [endDateError, setEndDateError] = useState<string | null>(null);
  const inputSchema = z.object({
    groupName: z.string(),
    price: z.number(),
    teacher_percentage: z.number(),
  });

  const handleChange = (event: any) => {
    if (event) {
      setTeacher(event.id as string);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm();

  const fetchTeacherList = async () => {
    try {
      const response = await GetTeacherDataReq();
      // Extract id and teacher_name from the response and create an array of objects
      // console.log("responseresponseresponse", response[0].user.userName);
      const simplifiedTeacherList = response.map((user: any) => ({
        id: user.id,
        teacher_name: user.user.fullName,
      }));
      // console.log("simplifiedTeacherList", simplifiedTeacherList);

      // Set the simplified teacher list in the state
      setTeacherList(simplifiedTeacherList);
      // console.log(simplifiedTeacherList);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchTeacherList();
  }, []);
  const fetchEditData = async () => {
    try {
      // const response = await axios.get(`class/${id}`);
      // setSingleClassEditData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleEditClick = () => {
    setOpen(true);
    setValue("groupName", "");
    setValue("fee", "");
    setValue("teacher_percentage", 80);
    setTeacher("");
    setEndDate(null);
    setStartDateError(null);
    setEndDateError(null);
    setStartDate(null);
    clearErrors();
    fetchEditData();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async (data: any) => {
    const parsedStartDate = dayjs(startDate);
    const parsedEndDate = dayjs(endDate);

    let startDateError = null;
    let endDateError = null;

    if (!parsedStartDate.isValid() || parsedStartDate.isBefore("2000-01-01")) {
      startDateError = "Please fill in the start date.";
    }

    if (!parsedEndDate.isValid() || parsedEndDate.isBefore("2000-01-01")) {
      endDateError = "Please fill in the end date.";
    }

    // Set the error messages
    setStartDateError(startDateError);
    setEndDateError(endDateError);

    // Check if either of the error messages is not null
    if (startDateError || endDateError) {
      return; // Prevent form submission if there are errors
    }

    // Both start date and end date are valid, proceed with your logic
    const acceptedStartDate = parsedStartDate.format(
      "YYYY-MM-DDTHH:mm:ss.SSSZ"
    );
    const acceptedEndDate = parsedEndDate.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
    // console.log(data, teacher);

    onCreateGroup({
      data,
      startDate: acceptedStartDate,
      endDate: acceptedEndDate,
      teacher,
      handleClose,
    });
  };
  // console.log(teacherList);

  return (
    <>
      {checkAccess({
        allowedRoles: [ROLES.ADMIN, ROLES.SUPERADMIN],
      }) ? (
        <div className="flex justify-between items-center mb-2">
          <div>
            <Button onClick={handleEditClick} variant="contained" size="large">
              <div className="font-mainfont">انشاء كروب</div>
            </Button>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description">
              <Box className={styleClasses}>
                <div className="flex justify-between items-center font-mainfont pb-6">
                  <div>
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-controls={open ? "long-menu" : undefined}
                      aria-expanded={open ? "true" : undefined}
                      aria-haspopup="true"
                      onClick={handleClose}>
                      <CloseIcon />
                    </IconButton>
                  </div>
                  <div className="font-mainfont">انشاء كروب جديد</div>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="py-2 flex items-center justify-between">
                    <div className="w-9/12 xsm:w-8/12">
                      <TextField
                        fullWidth
                        id="groupName"
                        placeholder="ادخل اسم الكروب"
                        {...register("groupName", { required: true })}
                        error={!!errors.groupName}
                        InputLabelProps={{
                          style: {
                            textAlign: "right", // Align the label to the right
                          },
                        }}
                        inputProps={{
                          style: {
                            textAlign: "right", // Align the input text to the right
                            direction: "rtl", // Set text direction for the input
                          },
                        }}
                        style={{
                          direction: "rtl", // Set text direction for the entire TextField
                          textAlign: "right", // Align the entire TextField to the right
                        }}
                      />
                      {errors.groupName && (
                        <div className="text-right text-xs text-red-600 pt-1">
                          الرجاء ادخال اسم الكورس
                        </div>
                      )}
                    </div>
                    <div className="font-mainfont">: اسم الكروب</div>
                  </div>
                  <div className="py-2 flex items-center justify-between">
                    <div className="w-9/12 xsm:w-8/12">
                      <FormControl fullWidth>
                        <Autocomplete
                          id="teacher-autocomplete"
                          // value={teacher}
                          onChange={(event, newValue) => {
                            handleChange(newValue);
                            // console.log(newValue);
                          }}
                          options={teacherList}
                          getOptionLabel={(teacher: any) =>
                            teacher.teacher_name
                          }
                          style={{
                            marginTop: "0px",
                            textAlign: "right",
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              dir="ltr"
                              // label={teacher ? teacher.teacher_name : ""} // Display teacher_name as the label
                              variant="outlined"
                            />
                          )}
                        />

                        {/* <Select
                          value={teacher}
                          onChange={handleChange}
                          required
                          displayEmpty
                          name="teacher_name" // Add the name attribute
                          style={{
                            marginTop: "0px", // Adjust top margin to prevent label overlap
                            textAlign: "right", // Align the value to the right
                          }}
                          inputProps={{
                            style: {
                              textAlign: "right", // Align the value text to the right
                            },
                            "aria-label": "Without label", // Add aria-label attribute
                          }}>
                          {teacherList.map((teacher) => (
                            <MenuItem key={teacher.id} value={teacher.id}>
                              {teacher.teacher_name}
                            </MenuItem>
                          ))}
                        </Select> */}
                      </FormControl>
                      {/* <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={teacherList.map(
                          (teacher) => teacher.teacher_name
                        )}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} />}
                      /> */}
                    </div>
                    <div className="font-mainfont">: اسم المدرس</div>
                  </div>
                  <div className="py-2 flex items-center justify-between">
                    <div className="w-9/12 xsm:w-8/12">
                      <TextField
                        fullWidth
                        id="fee"
                        placeholder="ادخل سعر الكورس"
                        {...register("fee", { required: true })}
                        error={!!errors.fee}
                        InputLabelProps={{
                          style: {
                            textAlign: "right", // Align the label to the right
                          },
                        }}
                        InputProps={{
                          inputComponent: NumericFormatCustom as any,
                        }}
                        style={{
                          direction: "rtl", // Set text direction for the entire TextField
                          textAlign: "right", // Align the entire TextField to the right
                        }}
                      />
                      {errors.fee && (
                        <div className="text-right text-xs text-red-600 pt-1">
                          الرجاء ادخال سعر الكورس
                        </div>
                      )}
                    </div>
                    <div className="font-mainfont">: سعر الكورس</div>
                  </div>
                  {/* <div className="py-2 flex items-center justify-between">
                    <div className="w-9/12 xsm:w-8/12">
                      <TextField
                        fullWidth
                        // defaultValue={80}
                        id="teacher_percentage"
                        placeholder="ادخل نسبة المدرس"
                        {...register("teacher_percentage", { required: true })}
                        error={!!errors.teacher_percentage}
                        helperText={
                          errors.teacher_percentage ? (
                            <div className="text-right">
                              الرجاء ادخال نسبة المدرس
                            </div>
                          ) : (
                            ""
                          )
                        }
                        InputLabelProps={{
                          style: {
                            textAlign: "right", // Align the label to the right
                          },
                        }}
                        inputProps={{
                          style: {
                            textAlign: "right", // Align the input text to the right
                            direction: "rtl", // Set text direction for the input
                          },
                        }}
                        style={{
                          direction: "rtl", // Set text direction for the entire TextField
                          textAlign: "right", // Align the entire TextField to the right
                        }}
                      />
                    </div>
                    <div className="font-mainfont">: نسبة المدرس</div>
                  </div> */}

                  {/* <div className="py-2 flex items-center justify-between">
                    <div className="w-9/12 xsm:w-8/12">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DatePicker"]}>
                          <DateField
                            sx={{ width: "100%" }}
                            required
                            // value={endDate}
                            // defaultValue={endDate}
                            format="DD-MM-YYYY"
                            onChange={(newDate) =>
                              setStartDate(newDate as Date)
                            }
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                    <div className="font-mainfont">: تاريخ البدء</div>
                  </div> */}
                  <div className="py-2 flex items-center justify-between">
                    <div className="w-9/12 xsm:w-8/12">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={["DatePicker", "DatePicker"]}>
                          <DatePicker
                            className="w-full"
                            value={startDate}
                            onChange={(newDate) => setStartDate(newDate)}
                            format="DD-MM-YYYY"
                            minDate={dayjs("2000-01-01")}
                          />
                        </DemoContainer>
                      </LocalizationProvider>{" "}
                      {startDateError && (
                        <div className="text-right text-xs text-red-600 pt-1">
                          الرجاء ادخال تاريخ البدء
                        </div>
                      )}
                    </div>
                    <div className="font-mainfont">: تاريخ البدء</div>
                  </div>
                  {/* <div className="py-2 flex items-center justify-between">
                    <div className="w-9/12 xsm:w-8/12">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DatePicker"]}>
                          <DateField
                            sx={{ width: "100%" }}
                            required
                            // value={endDate}
                            format="DD-MM-YYYY"
                            // defaultValue={endDate}
                            onChange={(newDate) => setEndDate(newDate as Date)}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                    <div className="font-mainfont">: تاريخ الانتهاء</div>
                  </div> */}
                  <div className="py-2 flex items-center justify-between">
                    <div className="w-9/12 xsm:w-8/12">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={["DatePicker", "DatePicker"]}>
                          <DatePicker
                            className="w-full"
                            value={endDate}
                            onChange={(newValue) => setEndDate(newValue)}
                            format="DD-MM-YYYY"
                            minDate={dayjs("2000-01-01")}
                          />
                        </DemoContainer>
                      </LocalizationProvider>{" "}
                      {endDateError && (
                        <div className="text-right text-xs text-red-600 pt-1">
                          الرجاء ادخال تاريخ الانتهاء
                        </div>
                      )}
                    </div>
                    <div className="font-mainfont">: تاريخ الانتهاء</div>
                  </div>
                  <div className="flex font-mainfont pt-6">
                    <Button type="submit" variant="contained" color="primary">
                      <div className="font-mainfont">اضافة</div>
                    </Button>
                  </div>
                </form>
              </Box>
            </Modal>
          </div>
          <div>
            <div className="font-mainfont text-3xl">الكروبات</div>
          </div>
        </div>
      ) : (
        <div className="flex justify-end items-center mb-2">
          <div>
            <div className="font-mainfont p-5 text-3xl">الكروبات</div>
          </div>
        </div>
      )}
      <div className="border-b-2 border-mainLine pt-2"></div>
    </>
  );
};
