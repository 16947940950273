import { axios } from "../../../lib/axios";

export const SubjectArchiveDelete = async (id: any, enteredSubjectId: any) => {
  const requestBody = {
    entity: "subject",
    id: enteredSubjectId,
  };
  const response = await axios.delete(`/delete-archived/${id}`, {
    data: requestBody,
  });
  return response;
};
// done types
