// import { CreateSubject } from "../../subject/components/CreateSubject";
// import { SingleClass } from "../components/SingleClass";
// import { SubjectList } from "../../subject/components/SubjectList";

import { useEffect, useState } from "react";
import { GroupStudentTable } from "../components/groupStudentTable";
import { GroupTeacherTable } from "../components/groupTeacherTable";
import { SingleGroupData } from "../components/singleGroupData";
import { useParams } from "react-router-dom";

import { decryptionFun } from "../../../lib/secretUrl";
import { GetSingleGroup } from "../api/getSingleGroup";
import { GetCurrentTeacher } from "../api/getCurrentTeacher";

export const SingleGroupWrapper = () => {
  const secreGrouptKey = process.env.REACT_APP_GROUP_ENCRYPTION_KEY as string;
  const [isLoading, setIsLoading] = useState(true);
  const { encryptedGrouptId } = useParams();
  const fetchData = async () => {
    setIsLoading(true);
    // console.log("encryptedGrouptId", encryptedGrouptId);
    try {
      if (encryptedGrouptId) {
        const GroupId = decryptionFun(encryptedGrouptId, secreGrouptKey);
        // console.log("GroupId", GroupId);
        if (GroupId) {
          const response = await GetSingleGroup(GroupId);
          // console.log("response", response);
          const groupTotalDataResponse = await GetCurrentTeacher({
            GroupId: response.id,
            teacherId: response.teacherId,
          });
          // console.log(groupTotalDataResponse);
        }

        // console.log("responseresponseresponseresponse", response);
        // setTeacherData(response);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching teacher data:", error);
    }
  };

  useEffect(() => {
    // Scroll to the top of the page when the component is mounted
    window.scrollTo({ top: 0, behavior: "smooth" });
    fetchData();
  }, []);
  // console.log("teacherData", teacherData);

  return (
    <>
      {isLoading ? (
        ""
      ) : (
        <div className="pt-5">
          <SingleGroupData fethcTeacherData={fetchData} />
          <div className="pt-3 p-5">
            <GroupTeacherTable />
            <GroupStudentTable />
            {/* <SubjectList /> */}
          </div>
        </div>
      )}
    </>
  );
};
