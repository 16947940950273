import { Box, Button, IconButton, Modal, TextField } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { GetStudentDataReq } from "../../students/api/getStudentData";
import { ROLES, useAuthorization } from "../../../lib/authorization";
import Autocomplete from "@mui/material/Autocomplete";
import { notification } from "antd";
import type { NotificationPlacement } from "antd/es/notification/interface";
import { decryptionFun } from "../../../lib/secretUrl";
import { useParams } from "react-router-dom";
import { AddStudentReq } from "../api/addStudent";
import { NumericFormatCustom } from "../../../lib/numberFormat";
import { GetSingleGroup } from "../api/getSingleGroup";
import { GetCurrentStudent } from "../api/getStudent";
import { CreateStudentPaymentReq } from "../api/createStudentPayment";
import { formatNumberWithCommas } from "../../../lib/formatNumberWithCommas";
const styleClasses =
  "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white shadow-lg p-4 md:w-6/12 sm:w-11/12 xsm:w-11/12";
const Context = React.createContext({ name: "Default" });

export const AddStudent = ({ fetchData }: any) => {
  const { encryptedGrouptId } = useParams();
  const [open, setOpen] = useState<boolean>(false);
  const [students, setStudents] = useState("");
  const [isBook, setIsbook] = useState(false);
  const [discount, setDiscount] = useState("");
  const [api, contextHolder] = notification.useNotification();
  const [thisGroupData, setthisGroupData] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const openNotification = (placement: NotificationPlacement) => {
    api.error({
      message: `الطالب موجود بالعفل`,
      // description: <Context.Consumer>{({ name }) => ``}</Context.Consumer>,
      placement,
    });
  };
  const [studentList, setStudentList] = useState<
    { id: number; student_name: string; create_at: string }[]
  >([]);
  const { checkAccess } = useAuthorization();
  const secretGrouptKey = process.env.REACT_APP_GROUP_ENCRYPTION_KEY as string;
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm();

  const handleClick = () => {
    // const feeNumber = Number(teacherData.fee);
    setOpen(true);
    // setValue("discount_percentage", feeNumber);
    setStudents("");
    setDiscount("");
    setIsbook(false);
    // console.log(teacherData.name);
    // console.log(feeNumber.toLocaleString());

    clearErrors();
    // fetchEditData();
  };
  const handleChange = (event: any) => {
    if (event) {
      setStudents(event.id as string);
    }
    // console.log(event);
  };
  const handleDiscountChange = (event: SelectChangeEvent) => {
    setDiscount(event.target.value as string);
  };
  const handleGroup = async () => {
    try {
      if (encryptedGrouptId) {
        const GroupId = decryptionFun(encryptedGrouptId, secretGrouptKey);
        const GroupResponse = await GetSingleGroup(GroupId);
        // console.log("GroupResponse", GroupResponse);
        setthisGroupData(GroupResponse);
      }
    } catch (error) {}
  };
  // console.log("StudentData", StudentData);
  const fetchStudentList = async () => {
    try {
      const response = await GetStudentDataReq();
      // console.log("response", response);

      // Extract id and teacher_name from the response and create an array of objects
      const simplifiedStudentList = response.map((student: any) => ({
        id: student.id,
        student_name: student.student_name,
        create_at: student.createdAt,
      }));
      // console.log(response);
      // Set the simplified teacher list in the state
      setStudentList(simplifiedStudentList);
      // console.log(simplifiedTeacherList);
    } catch (error) {
      console.log(error);
    }
  };
  const handleBookChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsbook(event.target.checked);
  };
  useEffect(() => {
    fetchStudentList();
    handleGroup();
  }, []);
  // console.log("students", studentList);

  const onSubmit = async (data: any) => {
    try {
      if (encryptedGrouptId) {
        const GroupId = decryptionFun(encryptedGrouptId, secretGrouptKey);
        let finalDiscount;
        // console.log(data.payment_amount.replace(/,/g, ""));
        // console.log(thisGroupData.fee);

        if (Number(discount) == 10) {
          finalDiscount = thisGroupData.fee;
        } else if (Number(discount) == 20) {
          finalDiscount =
            thisGroupData.fee - Number(data.payment_amount.replace(/,/g, ""));
        } else {
          finalDiscount = 0;
        }
        // console.log(finalDiscount);

        const combinedData = {
          studentID: students,
          courseID: GroupId,
          teacherDiscount: Number(finalDiscount),

          // discount_percentage: Number(teacherData.fee),
        };
        // console.log(combinedData);

        // console.log(combinedData);
        // console.log(finalDiscount);

        const response = await AddStudentReq(combinedData);
        // console.log("responseresponseresponseresponse", response);
        // console.log(response);

        if (response.status == 201) {
          if (isBook == true) {
            const currentTime = new Date().toISOString();
            const singleStudentResponse = await GetCurrentStudent({
              GroupId: GroupId,
              StudentId: students,
            });
            // console.log(singleStudentResponse);
            const validDate = {
              amount_paid: Number(25000),
              courseId: GroupId,
              date: currentTime,
              studentId: students,
              studentGroupId: singleStudentResponse.studentGroupId,
              isBook: true,
            };
            // const response = await GetGroupStudent(GroupId);
            // console.log("response", validDate);

            const createResponse = await CreateStudentPaymentReq(validDate);
            if (createResponse.status === 409) {
              // Check for the correct status code, 409 for conflict
              openNotification("topLeft");
            } else {
            }
          }
        }

        fetchData();
        handleClose();
      }
    } catch (error) {
      openNotification("topLeft");
      handleClose();
      // console.error("Error submitting form:", error);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  // console.log(studentList);
  const contextValue = useMemo(() => ({ name: "Ant Design" }), []);
  // console.log(thisGroupData);
  const validateAmount = (value: any) => {
    // Remove commas and parse the value as a float
    const amount = parseFloat(value.replace(/,/g, ""));

    if (isNaN(amount) || amount < 250) {
      return "المبلغ يجب أن يكون أكبر من 250";
    }
    return true;
  };
  // console.log(studentList);

  return (
    <>
      <Context.Provider value={contextValue}>
        {contextHolder}
        {checkAccess({
          allowedRoles: [ROLES.ADMIN, ROLES.SUPERADMIN],
        }) ? (
          <div className="flex justify-between items-center">
            <div className="flex font-mainfont py-6">
              <Button
                onClick={handleClick}
                type="submit"
                variant="contained"
                color="primary">
                <div className="font-mainfont">اضافة طالب</div>
              </Button>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box className={styleClasses}>
                  <div className="flex justify-between items-center font-mainfont pb-6">
                    <div>
                      <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={open ? "long-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={handleClose}>
                        <CloseIcon />
                      </IconButton>
                    </div>
                    <div className="font-mainfont">اضافة طالب</div>
                  </div>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="py-2 flex items-center justify-between">
                      <div className="w-9/12 xsm:w-8/12">
                        <FormControl fullWidth>
                          {/* <Select
                            value={students}
                            onChange={handleChange}
                            required
                            displayEmpty
                            name="teacher_name" // Add the name attribute
                            style={{
                              marginTop: "0px", // Adjust top margin to prevent label overlap
                              textAlign: "right", // Align the value to the right
                            }}
                            inputProps={{
                              style: {
                                textAlign: "right", // Align the value text to the right
                              },
                              "aria-label": "Without label", // Add aria-label attribute
                            }}>
                            {studentList.map((student) => (
                              <MenuItem
                                className="flex flex-col justify-end"
                                key={student.id}
                                value={student.id}>
                                <div>
                                  <div className="flex justify-end">
                                    <div>{student.student_name}</div>
                                    <div>&nbsp;: الاسم</div>
                                  </div>
                                  <div className="text-sm flex justify-end">
                                    <div>
                                      {new Date(
                                        student.create_at
                                      ).toLocaleDateString("en-GB", {
                                        year: "numeric",
                                        month: "numeric",
                                        day: "numeric",
                                      })}
                                    </div>
                                    <div>&nbsp;: تاريخ الانشاء</div>
                                  </div>
                                </div>
                              </MenuItem>
                            ))}
                          </Select> */}
                          <Autocomplete
                            id="teacher-autocomplete"
                            // className="flex flex-col justify-end"
                            // value={students}
                            // value={teacher}
                            onChange={(event, newValue) => {
                              handleChange(newValue);
                              // console.log(newValue);
                            }}
                            options={studentList}
                            getOptionLabel={(student: any) =>
                              student.student_name
                            }
                            style={{
                              marginTop: "0px",
                              textAlign: "left",
                            }}
                            renderOption={(props, student) => (
                              <li
                                // className="flex flex-col justify-end"
                                {...props}>
                                <div className="flex flex-col justify-end">
                                  <div className="flex justify-end">
                                    <div>{student.student_name}</div>
                                    <div>&nbsp;: الاسم</div>
                                  </div>
                                  <div className="text-sm flex justify-end">
                                    <div>
                                      {new Date(
                                        student.create_at
                                      ).toLocaleDateString("en-GB", {
                                        year: "numeric",
                                        month: "numeric",
                                        day: "numeric",
                                      })}
                                    </div>
                                    <div>&nbsp;: تاريخ الانشاء</div>
                                  </div>
                                </div>
                              </li>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                dir="rtl" // Set direction to right-to-left
                                required
                                // label={student ? student.student_name : ""} // Display teacher_name as the label
                                variant="outlined"
                              />
                            )}
                          />
                        </FormControl>
                      </div>
                      <div className="font-mainfont">: اسم الطالب</div>
                    </div>
                    <div className="py-2 flex items-center justify-between">
                      <div className="w-9/12 xsm:w-8/12">
                        <FormControl fullWidth>
                          <Select
                            value={discount}
                            onChange={handleDiscountChange}
                            required
                            displayEmpty
                            name="teacher_name" // Add the name attribute
                            style={{
                              marginTop: "0px", // Adjust top margin to prevent label overlap
                              textAlign: "right", // Align the value to the right
                            }}
                            inputProps={{
                              style: {
                                textAlign: "right", // Align the value text to the right
                              },
                              "aria-label": "Without label", // Add aria-label attribute
                            }}>
                            <MenuItem
                              className="flex flex-col justify-end"
                              value={10}>
                              خصم كامل - خصم 100% من سعر الكورس
                            </MenuItem>
                            <MenuItem
                              className="flex flex-col justify-end"
                              value={20}>
                              خصم جزئي - خصم مبلغ معين من الكورس
                            </MenuItem>
                            <MenuItem
                              className="flex flex-col justify-end"
                              value={30}>
                              بدون خصم
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      <div className="font-mainfont">
                        {" "}
                        : الرجاء ادخال نوع الخصم
                      </div>
                    </div>
                    {discount == "20" && (
                      <>
                        <div className="py-2 flex items-center justify-between">
                          <div className="w-9/12 xsm:w-8/12 flex justify-end">
                            <FormControl fullWidth>
                              <TextField
                                fullWidth
                                id="payment_amount"
                                // type="number"
                                placeholder="ادخل المبلغ"
                                {...register("payment_amount", {
                                  required: true,
                                  validate: validateAmount, // Add the custom validation function
                                })}
                                error={!!errors.payment_amount}
                                InputProps={{
                                  inputComponent: NumericFormatCustom as any,
                                }}
                                style={{
                                  direction: "rtl", // Set text direction for the entire TextField
                                  textAlign: "right", // Align the entire TextField to the right
                                }}
                              />
                              {errors.payment_amount && (
                                <div className="text-right text-xs text-red-600 pt-1">
                                  المبلغ يجب أن يكون رقمًا أكبر من 250
                                </div>
                              )}
                            </FormControl>
                          </div>
                          <div className="font-mainfont">
                            : سعر الكورس للطالب
                          </div>
                        </div>
                        <div className="py-2 flex items-center justify-between">
                          <div className="w-9/12 xsm:w-8/12 flex pr-4 justify-end">
                            {/* {thisGroupData.fee} */}
                            {formatNumberWithCommas(
                              thisGroupData.fee ? thisGroupData.fee : 0
                            )}
                          </div>
                          <div className="font-mainfont">
                            : سعر الكورس الاصلي
                          </div>
                        </div>
                      </>
                    )}
                    <div className="py-2 flex items-center justify-end">
                      <div className="">
                        <Checkbox
                          checked={isBook}
                          onChange={handleBookChange}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </div>
                      <div className="font-mainfont">
                        : هل دفع الطالب مبلغ الحجز
                      </div>
                    </div>
                    {/* <Checkbox
                      checked={isBook}
                      onChange={handleBookChange}
                      inputProps={{ "aria-label": "controlled" }}
                    /> */}
                    {/* <div className="py-2 flex items-center justify-between">
                      <div className="w-9/12 xsm:w-8/12 flex justify-end">
                        <FormControl fullWidth>
                          <TextField
                            id="discount_percentage"
                            placeholder="سعر الكورس للطالب"
                            {...register("discount_percentage", {
                              required: true,
                            })}
                            error={!!errors.discount_percentage}
                            InputLabelProps={{
                              style: {
                                textAlign: "right",
                              },
                            }}
                            InputProps={{
                              inputComponent: NumericFormatCustom as any,
                            }}
                            style={{
                              direction: "rtl",
                              textAlign: "right",
                            }}
                          />
                          {errors.discount_percentage && (
                            <div className="text-right text-xs text-red-600 pt-1">
                              {errors.discount_percentage.type === "pattern"
                                ? "رقم الهاتف يجب ان يبدا ب 07"
                                : "الرجاء ادخال رقم الهاتف"}
                            </div>
                          )}
                        </FormControl>
                      </div>
                      <div className="font-mainfont"> : سعر الكورس للطالب</div>
                    </div> */}
                    <div className="flex font-mainfont pt-6">
                      <Button type="submit" variant="contained" color="primary">
                        <div className="font-mainfont">اضافة</div>
                      </Button>
                    </div>
                  </form>
                </Box>
              </Modal>
            </div>{" "}
            <div className="font-mainfont text-3xl">
              {/* {singleClassData?.title} */}الطلاب
            </div>
          </div>
        ) : (
          <div className="flex justify-end p-5 items-center">
            <div className="font-mainfont text-3xl">
              {/* {singleClassData?.title} */}الطلاب
            </div>
          </div>
        )}{" "}
      </Context.Provider>
    </>
  );
};

// done types
