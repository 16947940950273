import { useForm } from "react-hook-form";
import { z } from "zod";
import { Box, Button, IconButton, Modal } from "@mui/material";
import { useState, createContext, useMemo } from "react";
import CloseIcon from "@mui/icons-material/Close";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { changeTeacherPasswordReq } from "../api/changeTeacherPassword";
import { notification } from "antd";
import type { NotificationPlacement } from "antd/es/notification/interface";

export const inputSchema = z.object({
  newTeacherPassword: z.any(),
});
const styleClasses =
  "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white shadow-lg p-4 md:w-6/12 sm:w-11/12 xsm:w-11/12";
const Context = createContext({ name: "Default" });

const ChangeTeacherPassword = ({
  TeacherProfileData,
}: {
  TeacherProfileData: any;
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement: NotificationPlacement) => {
    api.success({
      message: `تم تغيير كلمة المرور بنجاح`,
      // description: <Context.Consumer>{({ name }) => ``}</Context.Consumer>,
      placement,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm();

  const handleOpen = () => {
    setOpen(true);
    setValue("newTeacherPassword", "");
    clearErrors();
  };
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClose = () => {
    setOpen(false);
  };
  const onSubmit = async (data: any) => {
    try {
      //   Validate the submitted data using the schema
      const validatedData = inputSchema.parse(data);
      const lastData = {
        newPassword: validatedData.newTeacherPassword,
        userId: TeacherProfileData.userId,
      };
      // console.log(lastData);
      const response = await changeTeacherPasswordReq(lastData);
      // console.log(response);
      openNotification("topLeft");
      handleClose();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const contextValue = useMemo(() => ({ name: "Ant Design" }), []);
  // console.log(TeacherProfileData);

  return (
    <Context.Provider value={contextValue}>
      {contextHolder}
      <div className="flex font-mainfont py-6 px-4">
        <Button
          onClick={() => handleOpen()}
          type="submit"
          variant="contained"
          color="primary">
          <div className="font-mainfont">تغير كلمة المرور</div>
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box className={styleClasses}>
            <div className="flex justify-between items-center font-mainfont pb-6">
              <div>
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={open ? "long-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </div>
              <div className="font-mainfont">نغيير كلمة المرور للاستاذ</div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="py-2 flex items-center justify-between">
                <div className="w-9/12 xsm:w-8/12">
                  <OutlinedInput
                    fullWidth
                    placeholder="ادخل الرمز السري"
                    id="newTeacherPassword"
                    type={showPassword ? "text" : "password"}
                    {...register("newTeacherPassword", { required: true })}
                    error={!!errors.newTeacherPassword}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          // onMouseDown={handleMouseDownPassword}
                          edge="end">
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    inputProps={{
                      style: {
                        textAlign: "right", // Align the input text to the right
                        direction: "rtl", // Set text direction for the input
                      },
                    }}
                    style={{
                      direction: "rtl", // Set text direction for the entire TextField
                      textAlign: "right", // Align the entire TextField to the right
                    }}
                  />{" "}
                  {errors.newTeacherPassword && (
                    <div className="text-right text-xs text-red-600 pt-1">
                      الرجاء ادخال رمز سري
                    </div>
                  )}
                </div>
                <div className="font-mainfont"> : كلمة المرور الجديدة</div>
              </div>
              <div className="flex font-mainfont py-6">
                <Button type="submit" variant="contained" color="primary">
                  <div className="font-mainfont">تغيير</div>
                </Button>
              </div>
            </form>
          </Box>
        </Modal>
      </div>{" "}
    </Context.Provider>
  );
};

export default ChangeTeacherPassword;
