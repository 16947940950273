import { axios } from "../../../lib/axios";

export const GetTeacherDataReq = async () => {
  const response = await axios.get<any>("/teacher/courses");
  // console.log(response.data);
  return response.data;
};

export type ResponseInterceptor<T> = {
  array: T[];
  count: number;
};
