import {
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { EditStudentGroupButton } from "./editStudentGroupButton";
import { AddStudent } from "./addStudent";
import { ROLES, useAuthorization } from "../../../lib/authorization";
import { decryptionFun } from "../../../lib/secretUrl";
import { GetGroupStudent } from "../api/getGroupStudent";
import { Waveform } from "@uiball/loaders";
import { GetCurrentStudent } from "../api/getStudent";
import { formatNumberWithCommas } from "../../../lib/formatNumberWithCommas";
import { GetStudentPayment } from "../api/getStudentPayment";
import { DeleteStudentPayment } from "./deleteStudentPayment";
import { EditStudentPayment } from "./editStudentPayment";

function Row(props: { row: any; fetchData: any }) {
  const { row, fetchData } = props;
  const [isLoadingCell, setIsLoadingCell] = useState(true);
  const [singleStudentData, setSingleStudentData] = useState<any>();
  const [open, setOpen] = useState(false);
  const [StudentPayment, setStudentPayment] = useState<any>();
  const [didPayFee, setDidPayFee] = useState(false);
  const { checkAccess } = useAuthorization();
  const fetchStudentPayment = async () => {
    // console.log(singleStudentData.studentGroupCourseId);

    const response = await GetStudentPayment({
      GroupStudentId: singleStudentData.studentGroupId,
    });
    response.array.map((response: any) => {
      if (response.isBook === true) {
        // console.log("response", response);
        setDidPayFee(true);
      }
    });
    // console.log(response);
    setStudentPayment(response.array);
  };
  const handleSingleStudent = async () => {
    // setIsLoading(true);
    // console.log(row);

    const response = await GetCurrentStudent({
      GroupId: row.courseID,
      StudentId: row.studentID,
    });
    // console.log("response", response);
    const response2 = await GetStudentPayment({
      GroupStudentId: response.studentGroupId,
    });
    response2.array.map((response: any) => {
      if (response.isBook === true) {
        // console.log("response", response);

        setDidPayFee(true);
      }
    });
    // console.log(response);
    setStudentPayment(response.array);
    setSingleStudentData(response);

    setIsLoadingCell(false);
  };
  const fetchEditData = async () => {
    fetchData();
    // fetchStudentPayment();
  };

  useEffect(() => {
    handleSingleStudent();
    // fetchStudentPayment();
  }, []);

  // console.log("singleStudentData", singleStudentData);
  const handleSetOpen = async (id: number) => {
    fetchStudentPayment();
    setOpen(!open);
  };
  // console.log(StudentPayment);

  return (
    <>
      {isLoadingCell ? (
        ""
      ) : (
        <>
          <Fragment>
            <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
              {checkAccess({
                allowedRoles: [ROLES.ADMIN, ROLES.SUPERADMIN],
              }) ? (
                <TableCell align="center">
                  <EditStudentGroupButton
                    studentGroup={singleStudentData}
                    handleSingleStudne={fetchEditData}
                    didPayFee={didPayFee}
                    // coursePrice={singleStudentData?.totalFee}
                  />
                </TableCell>
              ) : (
                ""
              )}
              {/* <TableCell align="center">
                <div className="font-mainfont text-base">
                  {row.student.total_paid_price}
                </div>
              </TableCell> */}
              <TableCell align="center">
                <div className="font-mainfont text-base">
                  {didPayFee ? "نعم" : "لا"}
                </div>
              </TableCell>
              <TableCell align="center">
                <div className="font-mainfont text-base">
                  {singleStudentData?.courseFee -
                    singleStudentData?.totalFee !==
                  0 ? (
                    // <CheckIcon />
                    <div>نعم</div>
                  ) : (
                    <div>لا</div>
                  )}
                </div>
              </TableCell>
              <TableCell align="center">
                <div className="font-mainfont text-base">
                  {formatNumberWithCommas(
                    Number(singleStudentData?.totalFee) -
                      Number(
                        singleStudentData?.allStudentPayment
                          ? singleStudentData?.allStudentPayment
                          : 0
                      )
                  )}
                </div>
              </TableCell>
              <TableCell align="center">
                <div className="font-mainfont text-base">
                  {formatNumberWithCommas(
                    singleStudentData?.allStudentPayment
                      ? singleStudentData?.allStudentPayment
                      : 0
                  )}
                </div>
              </TableCell>
              <TableCell align="center">
                <div className="font-mainfont text-base">
                  {formatNumberWithCommas(Number(singleStudentData?.totalFee))}
                </div>
              </TableCell>
              <TableCell align="center">
                <div className="font-mainfont text-base">
                  {singleStudentData.studentParentPhone}
                </div>
              </TableCell>
              <TableCell align="center">
                <div className="font-mainfont text-base">
                  {singleStudentData.studentAddress}
                </div>
              </TableCell>
              <TableCell align="center">
                <div className="font-mainfont text-base">
                  {singleStudentData.studentPhone}
                </div>
              </TableCell>

              <TableCell align="center" width={50}>
                <div className="font-mainfont flex items-center w-full justify-between text-base p-4">
                  {/* {row.student.student_name} */}
                  <div className="flex justify-center">
                    {singleStudentData.studentName}
                  </div>
                  <div className="flex justify-center">
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => handleSetOpen(row.id)}>
                      {open ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </div>
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                style={{ paddingBottom: 0, paddingTop: 0 }}
                colSpan={10}>
                <Collapse
                  in={open}
                  timeout="auto"
                  unmountOnExit
                  className="py-10">
                  {StudentPayment?.length > 0 ? (
                    <>
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 150 }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell align="center"></TableCell>
                              {/* <TableCell align="center">التاريخ</TableCell> */}
                              <TableCell align="center">تاريخ الوصل</TableCell>
                              <TableCell align="center">المبلغ</TableCell>
                              <TableCell align="center">اسم الوصل</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {StudentPayment.map((payment: any, index: any) => {
                              return payment.isBook === false ? (
                                <TableRow
                                  key={index}
                                  sx={{ "& > *": { borderBottom: "unset" } }}>
                                  <TableCell align="center" className="flex ">
                                    <div className="flex justify-center">
                                      <DeleteStudentPayment
                                        payment={payment}
                                        fetchEditData={fetchEditData}
                                      />
                                      <EditStudentPayment
                                        payment={payment}
                                        fetchEditData={fetchEditData}
                                      />
                                    </div>
                                  </TableCell>
                                  {/* <TableCell align="center">
                                    <div>{payment.date}</div>
                                    {payment.isBook}
                                  </TableCell> */}
                                  <TableCell align="center">
                                    {payment.invoiceDate !== null ? (
                                      <div>
                                        {
                                          new Date(payment.invoiceDate)
                                            .toISOString()
                                            .split("T")[0]
                                        }
                                      </div>
                                    ) : (
                                      <div>لايوجد معلومات</div>
                                    )}
                                  </TableCell>
                                  <TableCell align="center">
                                    <div>
                                      {formatNumberWithCommas(
                                        Number(payment.amount_paid)
                                      )}
                                    </div>
                                  </TableCell>
                                  <TableCell align="center">
                                    {payment.invoiceName !== null ? (
                                      <div>{payment.invoiceName}</div>
                                    ) : (
                                      <div>لايوجد معلومات</div>
                                    )}
                                  </TableCell>
                                </TableRow>
                              ) : (
                                ""
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  ) : (
                    <div className="flex justify-center p-4">
                      <div>لايوجد اي دفعات </div>
                    </div>
                  )}
                </Collapse>
              </TableCell>
            </TableRow>
          </Fragment>
        </>
      )}
    </>
  );
}

export const GroupStudentTable = () => {
  const [StudentData, setStudentData] = useState([]);
  const { checkAccess } = useAuthorization();
  const secreGrouptKey = process.env.REACT_APP_GROUP_ENCRYPTION_KEY as string;
  const { encryptedGrouptId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  // const [order, setOrder] = useState<any>("asc");
  // const [orderBy, setOrderBy] = useState<any>("calories");

  const fetchData = async () => {
    setIsLoading(true);
    try {
      if (encryptedGrouptId) {
        const GroupId = decryptionFun(encryptedGrouptId, secreGrouptKey);
        const response = await GetGroupStudent(GroupId);

        // console.log(response.array);

        setStudentData(response.array);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching teacher data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  // console.log(StudentData);

  return (
    <div className="pt-5">
      <>
        <div className="flex flex-col px-5">
          <AddStudent fetchData={fetchData} />
          <div className="border-b-2 border-mainLine"></div>
        </div>
        <div className="p-3">
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  {/* <TableCell /> */}
                  {checkAccess({
                    allowedRoles: [ROLES.ADMIN, ROLES.SUPERADMIN],
                  }) ? (
                    <TableCell align="center">
                      <div className="font-mainfont text-base"></div>
                    </TableCell>
                  ) : (
                    ""
                  )}
                  <TableCell align="center">
                    <div className="font-mainfont text-base">
                      هل دفع مبلغ الحجز
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className="font-mainfont text-base">
                      هل يملك الطالب خصم
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className="font-mainfont text-base">
                      اجمالي المبلغ المتبقي
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className="font-mainfont text-base">
                      اجمالي المبلغ المدفوع
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className="font-mainfont text-base">
                      سعر الكورس للطالب
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className="font-mainfont text-base">
                      رقم الهاتف الوالدين
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className="font-mainfont text-base">عنوان السكن</div>
                  </TableCell>
                  <TableCell align="center">
                    <div className="font-mainfont text-base">رقم الهاتف</div>
                  </TableCell>
                  <TableCell align="center" size="medium">
                    <div className="font-mainfont text-base">الاسم</div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading
                  ? ""
                  : StudentData.length > 0
                  ? StudentData.map((row, index) => (
                      <Row key={index} row={row} fetchData={fetchData} />
                    ))
                  : ""}
              </TableBody>
            </Table>
            {isLoading ? (
              <div className="flex justify-center items-center p-16">
                <Waveform size={100} speed={2} color="black" />
              </div>
            ) : StudentData.length === 0 ? (
              <div className="flex justify-center p-5">
                <div>لايوجد طلاب</div>
              </div>
            ) : (
              ""
            )}
          </TableContainer>
        </div>
      </>
    </div>
  );
};
