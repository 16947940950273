import { axios } from "../../../lib/axios";
// import { ClassTypes } from "../types";

export const DeleteGroup = async (id: any) => {
  // console.log(id);

  const response = await axios.delete(`/courses/${id}`); // Use stage_name key
  // console.log(response);https://api.school.new.qafdev.com/student-group/48

  return response;
};
