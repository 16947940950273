import {
  Button,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { Waveform } from "@uiball/loaders";
import { Fragment, Key, useEffect, useState } from "react";
import { encryptionFun } from "../../../lib/secretUrl";
import { useNavigate, useParams } from "react-router-dom";
import { ROLES, useAuthorization } from "../../../lib/authorization";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { decryptionFun } from "../../../lib/secretUrl";
import { CreateTeacherPayment } from "../../teachers/components/creteTeacherPayment";
import { formatNumberWithCommas } from "../../../lib/formatNumberWithCommas";
import { GetSingleGroup } from "../api/getSingleGroup";
import { GetCurrentTeacher } from "../api/getCurrentTeacher";
import { CreateTeacherPaymentReq } from "../api/createTeacherPayment";
import { ChangeTeacher } from "./changeTeacher";
import { GetTeacherPayment } from "../api/getTeacherPayments";
import { DeleteTeacherPayment } from "./deleteTeacherPayment";
import { EditTeacherPayment } from "./editTeacherPayment";
import { GetGroupStudent } from "../api/getGroupStudent";
import { GetCurrentStudent } from "../api/getStudent";
function Row(props: { row: any; fetchData: any }) {
  const { row, fetchData } = props;
  const { checkAccess } = useAuthorization();
  const [TeacherPayment, setTeacherPayment] = useState<any>();
  const [open, setOpen] = useState(false);
  const [totalTeacher, setTotalTeacher] = useState(0);
  const [totalCouldPayToTeacher, setTotalCouldPayToTeacher] = useState(0);
  const [paymentsIsLoading, setPaymentsIsLoading] = useState(true);
  useEffect(() => {
    // fetchData();
    // Scroll to the top of the page when the component is mounted
    window.scrollTo({ top: 0, behavior: "smooth" });
    fetchTeacherPayment();
    handleStudentTotalPrice();
  }, []);
  // console.log(row);
  const navigate = useNavigate();

  const secretKey = process.env.REACT_APP_TEACHER_ENCRYPTION_KEY as string;

  const handleTeacher = () => {
    // console.log(secretKey);
    // console.log("id", row.teacherId);
    const decId = encryptionFun(row.teacherId, secretKey);
    // console.log("decId", decId);
    navigate(`/teachers/${decId}`);
  };
  // console.log("row", row, row.teacherId, row.courseId);
  const fetchTeacherPayment = async () => {
    const response = await GetTeacherPayment({
      TeacherId: row.teacherId,
      GroupId: row.courseId,
    });
    // console.log(response);
    setTeacherPayment(response);
  };
  // console.log("teacherName", teacherName);
  const handleSetOpen = async (id: number) => {
    setOpen(!open);
  };

  const fetchEditData = async () => {
    fetchData();
    fetchTeacherPayment();
  };
  // let total = 0;
  const secreGrouptKey = process.env.REACT_APP_GROUP_ENCRYPTION_KEY as string;
  const { encryptedGrouptId } = useParams();
  const handleStudentTotalPrice = async () => {
    try {
      let total = 0; // Initialize a variable to accumulate the total
      let totalCouldPayToTeacher = 0;
      if (encryptedGrouptId) {
        const GroupId = decryptionFun(encryptedGrouptId, secreGrouptKey);
        const response = await GetGroupStudent(GroupId);

        const calculateTotal = async (results: any[]) => {
          // console.log(results);

          for (const result of results) {
            const currentResponse = await GetCurrentStudent({
              GroupId: result.courseID,
              StudentId: result.studentID,
            });
            // console.log(currentResponse);

            totalCouldPayToTeacher +=
              (Number(currentResponse.totalPayment) * 80) / 100;
            if (currentResponse.totalFee !== 0) {
              // if (currentResponse.totalFee == currentResponse.courseFee) {
              total += (Number(currentResponse.totalFee) * 80) / 100;
              // } else {
              //   const fee = (Number(currentResponse.courseFee) * 20) / 100;
              //   // console.log("fee", fee);
              //   // console.log(currentResponse.totalFee - fee);

              //   total += currentResponse.totalFee - fee;
              // }
            } else {
              total += 0;
              // totalCouldPayToTeacher += 0;
            }
          }
          // console.log(totalCouldPayToTeacher);

          // Update the state with the accumulated total
          setTotalTeacher(total);
          setTotalCouldPayToTeacher(totalCouldPayToTeacher);
        };
        // console.log("response.array", response);

        calculateTotal(response.array);
        setPaymentsIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching teacher data:", error);
    }
  };
  useEffect(() => {
    handleStudentTotalPrice();
  }, []);
  // console.log(row);

  const createPay = async (validDate: any, setItsBegger: any) => {
    try {
      if (
        Number(validDate.amount_paid) >
        Number(
          totalTeacher - (row.allTeacherPayment ? row.allTeacherPayment : 0)
        )
        // ||
        // Number(validDate.amount_paid) >
        //   Number(row.totalPayment) - Number(row.courseFee)
      ) {
        // console.log("dfas");

        // openNotification("topLeft");
        setItsBegger(true);
        return false;
      } else {
        await CreateTeacherPaymentReq(validDate);
        fetchData();
        fetchTeacherPayment();
        return true;
        // console.log(response);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      throw error;
    }
  };
  // console.log("row", row, totalTeacher, row.allPayment ? row.allPayment : 0);
  // console.log(TeacherPayment);

  return (
    <>
      <Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          {checkAccess({
            allowedRoles: [ROLES.ADMIN, ROLES.SUPERADMIN],
          }) ? (
            <TableCell align="center">
              <div className="flex justify-center">
                <div className="p-1">
                  <CreateTeacherPayment
                    teacherId={row.teacherId}
                    createPay={createPay}
                  />
                </div>
                <div className="p-1">
                  <Button
                    onClick={() => handleTeacher()}
                    variant="contained"
                    color="primary">
                    <div className="font-mainfont">عرض الملف الشخصي</div>
                  </Button>
                </div>
              </div>
            </TableCell>
          ) : (
            ""
          )}
          {paymentsIsLoading === false ? (
            <>
              <TableCell align="center">
                {row.allTeacherPayment <= totalCouldPayToTeacher ? (
                  <div
                    className="font-mainfont text-base"
                    style={{
                      color:
                        row.allTeacherPayment <= totalCouldPayToTeacher
                          ? "inherit"
                          : "red",
                    }}>
                    {formatNumberWithCommas(
                      totalCouldPayToTeacher - row.allTeacherPayment
                    )}
                  </div>
                ) : (
                  <Tooltip
                    title={
                      <span style={{ fontSize: "14px" }}>
                        المبلغ المدفوع للاستاذ اعلى من المبلغ المستلم من قبل
                        الطلاب
                      </span>
                    }>
                    <div
                      className="font-mainfont text-base"
                      style={{
                        color:
                          row.allTeacherPayment <= totalCouldPayToTeacher
                            ? "inherit"
                            : "red",
                      }}>
                      {formatNumberWithCommas(
                        totalCouldPayToTeacher - row.allTeacherPayment
                      )}
                    </div>
                  </Tooltip>
                )}
              </TableCell>
              <TableCell align="center">
                <div className="font-mainfont text-base">
                  {formatNumberWithCommas(
                    totalTeacher -
                      (row.allTeacherPayment ? row.allTeacherPayment : 0)
                  )}
                </div>
              </TableCell>
              <TableCell align="center">
                <div className="font-mainfont text-base">
                  {formatNumberWithCommas(
                    row.allTeacherPayment ? row.allTeacherPayment : 0
                  )}
                </div>
              </TableCell>{" "}
              <TableCell align="center">
                <div className="font-mainfont text-base">
                  {formatNumberWithCommas(totalTeacher ? totalTeacher : 0)}
                </div>
              </TableCell>
              <TableCell align="center">
                <div className="font-mainfont text-base">
                  {row.teacherAddress}
                </div>
              </TableCell>
              <TableCell align="center">
                <div className="font-mainfont text-base">
                  {row.teacherPhone}
                </div>
              </TableCell>
              <TableCell align="center" width={50}>
                <div className="font-mainfont flex items-center w-full justify-between text-base p-4">
                  <div className="flex justify-center">{row.userFullName}</div>
                  <div className="flex justify-center">
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => handleSetOpen(row.id)}>
                      {open ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </div>
                </div>
              </TableCell>
            </>
          ) : (
            ""
          )}
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
            <Collapse in={open} timeout="auto" unmountOnExit className="py-10">
              {TeacherPayment?.array?.length > 0 ? (
                <>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 150 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center"></TableCell>
                          {/* <TableCell align="center">التاريخ</TableCell> */}
                          <TableCell align="center">تاريخ الوصل</TableCell>
                          <TableCell align="center">المبلغ</TableCell>
                          <TableCell align="center">اسم الوصل</TableCell>
                          {/* <TableCell align="center">الصورة</TableCell> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {TeacherPayment?.array.map(
                          (payment: any, index: any) => (
                            <TableRow
                              key={index}
                              sx={{ "& > *": { borderBottom: "unset" } }}>
                              <TableCell align="center" className="flex">
                                <div className="flex justify-center">
                                  <DeleteTeacherPayment
                                    payment={payment}
                                    fetchEditData={fetchEditData}
                                  />
                                  <EditTeacherPayment
                                    payment={payment}
                                    fetchEditData={fetchEditData}
                                  />
                                </div>
                              </TableCell>
                              {/* <TableCell align="center">
                                <div>{payment.date}</div>
                              </TableCell> */}
                              <TableCell align="center">
                                {payment.invoiceDate != null ? (
                                  <div>
                                    {
                                      new Date(payment.invoiceDate)
                                        .toISOString()
                                        .split("T")[0]
                                    }
                                  </div>
                                ) : (
                                  <div>لايوجد معلومات</div>
                                )}
                              </TableCell>
                              <TableCell align="center">
                                <div>
                                  {formatNumberWithCommas(
                                    Number(payment.amount_paid)
                                  )}
                                </div>
                              </TableCell>
                              <TableCell align="center">
                                {payment.invoiceName != null ? (
                                  <div>{payment.invoiceName}</div>
                                ) : (
                                  <div>لايوجد معلومات</div>
                                )}
                              </TableCell>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              ) : (
                <div className="flex justify-center p-4">
                  <div>لايوجد اي دفعات </div>
                </div>
              )}
            </Collapse>
          </TableCell>
        </TableRow>
      </Fragment>
    </>
  );
}

export const GroupTeacherTable = () => {
  const { checkAccess } = useAuthorization();
  const secreGrouptKey = process.env.REACT_APP_GROUP_ENCRYPTION_KEY as string;
  const [isLoading, setIsLoading] = useState(true);
  const { encryptedGrouptId } = useParams();
  const [teacherList, setTeacherList] = useState<any>();
  const fetchData = async () => {
    // setIsLoading(true);
    // console.log("encryptedGrouptId", encryptedGrouptId);
    try {
      if (encryptedGrouptId) {
        const GroupId = decryptionFun(encryptedGrouptId, secreGrouptKey);
        // console.log("GroupId", GroupId);
        if (GroupId) {
          const response = await GetSingleGroup(GroupId);
          // console.log(response);
          const groupTotalDataResponse = await GetCurrentTeacher({
            GroupId: response.id,
            teacherId: response.teacherId,
          });
          // console.log(groupTotalDataResponse);
          setTeacherList([groupTotalDataResponse]);
          setIsLoading(false);
        }

        // console.log("responseresponseresponseresponse", response);
        // setTeacherData(response);
      }
    } catch (error) {
      console.error("Error fetching teacher data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  // const teacherList = [teacherData];
  // console.log("teacherData", teacherList);
  //   fetchData();
  // }, []);

  return (
    <div className="pt-5">
      {checkAccess({
        allowedRoles: [ROLES.TEACHER],
      }) ? (
        <>
          <div className="flex flex-col px-5">
            <div className="flex justify-end items-center py-6">
              <div className="font-mainfont text-3xl">المدرس</div>
            </div>
            <div className="border-b-2 border-mainLine"></div>
          </div>
        </>
      ) : (
        ""
      )}

      {checkAccess({
        allowedRoles: [ROLES.ADMIN, ROLES.SUPERADMIN],
      }) ? (
        <>
          <div className="flex flex-col px-5">
            <div className="flex justify-between items-center py-6">
              <div>
                <ChangeTeacher fetchData={fetchData} />
              </div>
              <div className="font-mainfont text-3xl">المدرس</div>
            </div>
            <div className="border-b-2 border-mainLine"></div>
          </div>
        </>
      ) : (
        ""
      )}
      <div className="p-3">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                {/* <TableCell /> */}
                {checkAccess({
                  allowedRoles: [ROLES.ADMIN, ROLES.SUPERADMIN],
                }) ? (
                  <>
                    <TableCell align="center">
                      <div className="font-mainfont text-base"></div>
                    </TableCell>
                  </>
                ) : (
                  ""
                )}
                <TableCell align="center">
                  <div className="font-mainfont text-base">
                    المبلغ الممكن دفعه للاستاذ
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className="font-mainfont text-base">
                    اجمالي المبلغ المتبقي للاستاذ
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className="font-mainfont text-base">
                    اجمالي المبلغ المدفوع للاستاذ
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className="font-mainfont text-base">
                    اجمالي المبلغ المستحق
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className="font-mainfont text-base">عنوان السكن</div>
                </TableCell>
                <TableCell align="center">
                  <div className="font-mainfont text-base">رقم الهاتف</div>
                </TableCell>
                <TableCell align="center">
                  <div className="font-mainfont text-base">الاسم</div>
                </TableCell>
              </TableRow>
            </TableHead>
            {isLoading ? (
              ""
            ) : (
              <TableBody className="flex w-full justify-center items-center">
                {teacherList.map((row: any, index: Key | null | undefined) => (
                  <Row key={index} row={row} fetchData={fetchData} />
                ))}
              </TableBody>
            )}
          </Table>
          {isLoading ? (
            <div className="flex w-full justify-center items-center p-16">
              <Waveform size={50} speed={2} color="black" />
            </div>
          ) : (
            ""
          )}
        </TableContainer>
      </div>
    </div>
  );
};
