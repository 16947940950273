import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Box, Button, IconButton, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { z } from "zod";
import { GetSingleGroup } from "../api/getSingleGroup";
import { useNavigate, useParams } from "react-router-dom";
import { decryptionFun } from "../../../lib/secretUrl";
import { ROLES, useAuthorization } from "../../../lib/authorization";
import { DeleteGroup } from "../api/deleteGroup";

const styleClasses =
  "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white shadow-lg p-4 md:w-6/12 sm:w-11/12 xsm:w-11/12";
export const DeleteSingleGroup = ({ fethcTeacherData }: any) => {
  const [deleteOpen, setDeleteOpen] = useState(false);
  const navigate = useNavigate();

  const [isLoading, setIsLoaging] = useState(true);
  const { checkAccess } = useAuthorization();
  const secreGrouptKey = process.env.REACT_APP_GROUP_ENCRYPTION_KEY as string;
  const [GroupData, setGroupData] = useState<any>();
  const { encryptedClassId, encryptedSubjectId, encryptedGrouptId } =
    useParams();
  const [canAccess, setCanAccess] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm();
  const inputEditSchema = z.object({
    name: z.string(),
    fee: z.string(),
    teacher_percentage: z.number(),
    teacherId: z.number(),
    // date_start: z.string(),
    // date_end: z.string(),
  });

  const fetchGroupData = async () => {
    if (encryptedGrouptId) {
      const id = decryptionFun(encryptedGrouptId, secreGrouptKey);
      // console.log("id", id);

      if (id) {
        const response = await GetSingleGroup(id);
        setGroupData(response);
        setCanAccess(
          checkAccess({
            allowedRoles: [ROLES.SUPERADMIN],
          })
        );

        setIsLoaging(false);
        // const startDateFormat = dayjs(startDate);
        // if (response.teacherId) {
        //   const teacherResponse = await GetCurrentTeacher(response.teacherId);
        //   if (teacherResponse.user.fullName) {
        //     setCurrentTeacher({
        //       name: teacherResponse.user.fullName,
        //       id: teacherResponse.id,
        //     }); // Set the current teacher name here
        //   }
        //   setTeacherLoading(false);
        // }
        //   ("responseteacherId", response);
        //   ("response", teacherResponse);
      }
    }
  };

  // const fetchTeacherList = async () => {
  //   try {
  //     const response: any = await GetTeacherDataReq();
  //     //   ("response", response);

  //     const simplifiedTeacherList = response.map(
  //       (teacher: { id: any; user: { fullName: any } }) => ({
  //         id: teacher.id,
  //         teacher_name: teacher.user.fullName,
  //       })
  //     );

  //     // setTeacherList(simplifiedTeacherList);
  //   } catch (error) {}
  // };

  useEffect(() => {
    fetchGroupData();
    // fetchTeacherList();
  }, []);

  const handleDeleteClick = () => {
    setDeleteOpen(true);
    // setValue("teacher_percentage", GroupData.teacher_percentage);
    // setEndDate(GroupData.date_end);
    //   (currentTeacher);
    clearErrors();
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const onDeleteSubmit = async () => {
    // console.log(`/dashboard/${encryptedClassId}/${encryptedSubjectId}`);
    const response = await DeleteGroup(GroupData.id);
    navigate(`/dashboard/${encryptedClassId}/${encryptedSubjectId}`);
    handleDeleteClose();
  };

  return (
    <>
      {isLoading ? (
        <div className="flex font-mainfont py-6">
          <Button className="m-2" variant="contained" color="error">
            <div className="font-mainfont">حذف</div>
          </Button>
        </div>
      ) : (
        <>
          <div className="flex font-mainfont py-6">
            <Button
              onClick={handleDeleteClick}
              // className="m-2"
              variant="contained"
              color="error">
              <div className="font-mainfont">حذف</div>
            </Button>
          </div>
          <Modal
            open={deleteOpen}
            onClose={handleDeleteClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box className={styleClasses}>
              <div className="flex justify-between items-center font-mainfont pb-6">
                <div>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={deleteOpen ? "long-menu" : undefined}
                    aria-expanded={deleteOpen ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleDeleteClose}>
                    <CloseIcon />
                  </IconButton>
                </div>
                <div className="font-mainfont">حذف الكروب</div>
              </div>
              <div className="flex justify-end pb-6">
                <div>هل انت متاكد من الكروب</div>
              </div>
              <div className="flex font-mainfont py-4">
                <Button
                  type="submit"
                  variant="contained"
                  color="error"
                  onClick={onDeleteSubmit}>
                  <div className="font-mainfont">تاكيد الحذف</div>
                </Button>
              </div>
              {/* <div className="flex font-mainfont pt-6">
                <Button
                  onClick={onDeleteSubmit}
                  variant="contained"
                  color="primary">
                  <div className="font-mainfont">نعم</div>
                </Button>
              </div> */}
            </Box>
          </Modal>
        </>
      )}
    </>
  );
};
