import { Box, Button, IconButton, Modal, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import FormControl from "@mui/material/FormControl";
import { useForm } from "react-hook-form";
import { EditTeacherPaymentReq } from "../api/editTeacherpaymentReq";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const styleClasses =
  "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white shadow-lg p-4 md:w-6/12 sm:w-11/12 xsm:w-11/12";

export const EditTeacherPayment = ({ payment, fetchEditData }: any) => {
  const [open, setOpen] = useState(false);
  const [invoiceDateValue, setInvoiceDateValue] = useState<any | null>();
  const [invoiceDateError, setInvoiceDateError] = useState<any | null>(false);
  const handleOpen = () => {
    setOpen(true);
    // setValue("payment_amount", 11); // Set the initial value of payment_amount to "11"
    clearErrors(); // Clear any previous errors
    // console.log(
    //   payment.invoiceDate != null ? dayjs(payment.invoiceDate) : null
    // );

    setInvoiceDateError(payment.invoiceDate === null ? true : false);
    setValue(
      "invoiceDate",
      payment.invoiceDate != null ? dayjs(payment.invoiceDate) : ""
    );
    // console.log(dayjs(payment.invoiceDate));

    setInvoiceDateValue(
      payment.invoiceDate != null ? dayjs(payment.invoiceDate) : ""
    );
    setValue("invoiceName", payment.invoiceName ? payment.invoiceName : "");
  };
  // console.log(payment.invoiceDate);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    clearErrors,
  } = useForm();
  const handleClose = () => {
    setOpen(false);
    // setValue("adminName", "");
  };

  const onSubmit = async (data: any) => {
    try {
      // Check if the form is valid
      // console.log(invoiceDateValue);
      // console.log(invoiceDateValue);
      const formattedReceiptDate = invoiceDateValue.format(
        "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
      );
      if (isValid && invoiceDateError !== true) {
        const dataEdit = {
          invoiceName: data.invoiceName,
          amount_paid: Number(data.payment_amount),
          date: payment.date,
          invoiceDate: formattedReceiptDate,
        };
        const response = await EditTeacherPaymentReq({
          data: dataEdit,
          id: payment.id,
        });

        fetchEditData();

        handleClose();
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  const handleDateChange = (newValue: any) => {
    if (newValue && newValue.isValid && newValue.isValid()) {
      // Set the receiptDate directly as the newValue (a Dayjs object)
      setInvoiceDateValue(newValue);
      setInvoiceDateError(false);
    } else {
      setInvoiceDateError(true);
    }
  };

  // console.log(invoiceDateError);

  return (
    <div className="p-1">
      <Button
        size="small"
        variant="contained"
        color="primary"
        onClick={() => handleOpen()}>
        <div className="font-mainfont">تعديل الدفعة</div>
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box className={styleClasses}>
          <div className="flex justify-between items-center font-mainfont pb-6">
            <div>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
            <div className="font-mainfont">تعديل الدفعة</div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="py-2 flex items-center justify-between">
              <div className="w-9/12 xsm:w-8/12">
                <TextField
                  fullWidth
                  id="invoiceName"
                  placeholder="ادخل اسم الوصل"
                  {...register("invoiceName", { required: true })}
                  error={!!errors.invoiceName}
                  InputLabelProps={{
                    style: {
                      textAlign: "right", // Align the label to the right
                    },
                  }}
                  inputProps={{
                    style: {
                      textAlign: "right", // Align the input text to the right
                      direction: "rtl", // Set text direction for the input
                    },
                  }}
                  style={{
                    direction: "rtl", // Set text direction for the entire TextField
                    textAlign: "right", // Align the entire TextField to the right
                  }}
                />
                {errors.invoiceName && (
                  <div className="text-right text-xs text-red-600 pt-1">
                    الرجاء ادخال اسم الوصل
                  </div>
                )}
              </div>
              <div className="font-mainfont">: اسم الوصل</div>
            </div>
            <div className="py-2 flex items-center justify-between">
              <div className="w-9/12 xsm:w-8/12 flex justify-end">
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    id="payment_amount"
                    defaultValue={Number(payment.amount_paid)}
                    placeholder="ادخل المبلغ"
                    {...register("payment_amount", {
                      required: true,
                      validate: (value) => {
                        const amount = parseFloat(value);
                        if (isNaN(amount)) {
                          return "المبلغ يجب أن يكون رقمًا أكبر من 250";
                        }
                        return true;
                      },
                    })}
                    error={!!errors.payment_amount}
                    helperText={
                      errors.payment_amount ? (
                        <div className="text-right">
                          المبلغ يجب أن يكون رقمًا أكبر من 250 واصغر من
                          2,500,000,000
                        </div>
                      ) : (
                        ""
                      )
                    }
                    InputLabelProps={{
                      style: {
                        textAlign: "right", // Align the label to the right
                      },
                    }}
                    // InputProps={{
                    //   inputComponent: NumericFormatCustom as any,
                    // }}
                    style={{
                      direction: "rtl", // Set text direction for the entire TextField
                      textAlign: "right", // Align the entire TextField to the right
                    }}
                  />
                </FormControl>
              </div>
              <div className="font-mainfont"> : المبلغ</div>
            </div>
            <div className="py-2 flex items-center justify-between">
              <div className="w-9/12 xsm:w-8/12">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker", "DatePicker"]}>
                    <DatePicker
                      className="w-full"
                      defaultValue={invoiceDateValue}
                      onChange={(newValue) => handleDateChange(newValue)}
                      format="DD-MM-YYYY"
                      minDate={dayjs("2000-01-01")}
                    />
                  </DemoContainer>
                </LocalizationProvider>
                {invoiceDateError === true && (
                  <div className="text-right text-xs text-red-600 pt-1">
                    الرجاء ادخال تاريخ الوصل
                  </div>
                )}
              </div>
              <div className="font-mainfont">: تاريخ الوصل</div>
            </div>
            <div className="flex font-mainfont pt-6">
              <Button type="submit" variant="contained" color="primary">
                <div className="font-mainfont">تعديل</div>
              </Button>
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
};
