import { axios } from "../../../lib/axios";
// import { TeacherType } from "../types";

export const GetStudentDataReq = async () => {
  const response = await axios.get(`/students/courses`);
  // console.log(response);

  return response.data;
};
// `/students-group?relations[]=student&relations[]=course&relations[]=student_payment`;
